import React from "react";

import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SelectBoxPropsType } from "./types";

const SelectBox = (props: SelectBoxPropsType) => {
    const { option, selectedOptions, setSelectedOptions, category, openDrawer, totalOption } = props;
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    const handleClick = (value: any, Categery?: any, title?: any) => {
        if (Categery) {
            setSelectedOptions((prev: any) => ({ ...prev, [Categery]: value }));
        } else if (title) {
            setSelectedOptions(value, title);
        } else {
            setSelectedOptions(value);
        }

        if (option.value === selectedOptions && (category === "Payments" || category === "File Upload")) {
            if (category === "Payments") {
                setSelectedOptions({ ...totalOption, Payments: "" });
            } else if (category === "File Upload") {
                setSelectedOptions({ ...totalOption, "File Upload": "" });
            }
        }
        if (openDrawer) {
            openDrawer();
        }
    };
    return (
        <Box
            className="transition"
            onClick={() => handleClick(option.value, category, option.title)}
            sx={{
                height: mediaQuery ? "50px" : "80px",
                backgroundColor: option.value === selectedOptions ? "#2b359559" : "#ffff",
                border: "1px solid #edededdd",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                padding: "0 25px"
            }}
        >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {option.icon}
                <Typography>{option.label}</Typography>
            </Box>
            <Box />
        </Box>
    );
};

export default SelectBox;
