import React from "react";
import { toast, ToastContainer } from "react-toastify";

import { Box } from "@mui/material";

import { useAppSelector } from "../../Redux/Hooks";

import "react-toastify/dist/ReactToastify.css";

const Alert = () => {
    const { alertMessage } = useAppSelector((state) => state);
    return (
        <Box>
            <ToastContainer />
            {alertMessage.open && toast.success(alertMessage?.message)}
        </Box>
    );
};
export default Alert;
