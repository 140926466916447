import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleProjects } from "../../Redux/Reducers";
import { validateFormOnSubmit } from "../../ReusableCodes/Resuseablefunctions";

import { ProjectDetailsConfig } from "./Uitilities";

const SelectProjectName = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { newProject } = useAppSelector((state) => state);
    console.log({ newProject });

    const handleBack = () => {
        dispatch(
            handleProjects({
                projectDetails: {
                    projectName: "",
                    tagName: ""
                }
            })
        );
        navigate(RoutesEnum.manageProject);
    };
    const formikProps = useFormik({
        initialValues: {
            ...ProjectDetailsConfig.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a })),
            ...newProject.projectDetails
        },
        onSubmit: async (values) => {
            dispatch(
                handleProjects({
                    projectDetails: {
                        projectName: values?.projectName,
                        tagName: values.tagName
                    }
                })
            );
            navigate(RoutesEnum.technologies);
        },
        validate: (values) => {
            return validateFormOnSubmit(values, [ProjectDetailsConfig]);
        }
    });

    return (
        <Box component="div" sx={{ minHeight: "100vh" }}>
            <HoC.CardComponent cardTitle="Project Details" handleBack={handleBack} handleNext={formikProps.handleSubmit}>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                    <Box p={4}>
                        <HoC.GenerateForm FormikProps={formikProps} FormData={ProjectDetailsConfig} />
                    </Box>
                </Box>
            </HoC.CardComponent>
        </Box>
    );
};

export default SelectProjectName;
