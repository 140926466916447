import React from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";

import HoC from "../../../../Hoc";

import { projectListProps } from "./types";

const ProjectList = (props: projectListProps) => {
    const { projectData, handleProjectModal, selectedProject, handleCancel, handleOk } = props;
    return (
        <Grid container>
            {projectData?.map((e: any) => (
                <Grid item xs={12}>
                    <Box
                        component={Paper}
                        p={1.5}
                        m={2}
                        onClick={() => handleProjectModal(e)}
                        sx={{
                            // eslint-disable-next-line no-underscore-dangle
                            backgroundColor: selectedProject && selectedProject?._id === e?._id ? "#303f9f" : "",
                            // eslint-disable-next-line no-underscore-dangle
                            color: selectedProject && selectedProject?._id === e?._id ? "#FFF" : ""
                        }}
                    >
                        <Typography variant="h6">Project Name: {e?.projectDetails?.projectName}</Typography>
                        <Typography>Tag Name: {e?.projectDetails?.tagName}</Typography>
                    </Box>
                </Grid>
            ))}
            <Grid item xs={12}>
                <HoC.ModalButtons okText="Associate" cancelText="Cancel" onCancel={handleCancel} onOk={handleOk} />
            </Grid>
        </Grid>
    );
};

export default ProjectList;
