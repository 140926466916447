import * as React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SideNavbarProps } from "./types";

const SideNavbar: React.FC<SideNavbarProps> = ({ state, toggleDrawer, logo, Menus, onChange }) => {
    const [selectedIndex, setSelectedIndex] = React.useState<any>();
    const handleIndex = (index: any) => {
        setSelectedIndex(index);
    };
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    const list = (anchor: string) => (
        <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            sx={{ padding: 3, backgroundColor: "#ffffff", height: "100%", paddingBottom: "0px" }}
        >
            <List style={{ padding: mediaQuery && "0px" }}>
                <Box
                    component="div"
                    sx={{
                        flexGrow: 1,
                        color: "#e40046",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        top: "-12px"
                    }}
                >
                    {logo}
                </Box>
            </List>
            <Divider />
            {Menus?.length &&
                Menus?.map((menu: any, i: number) => {
                    return (
                        <List style={{ padding: mediaQuery && "0px" }}>
                            {menu?.parentMenuText && (
                                <ListItem disablePadding>
                                    <ListItemText
                                        sx={{
                                            color: "#948f8f"
                                        }}
                                        primary={menu?.parentMenuText}
                                    />
                                </ListItem>
                            )}
                            <ListItem
                                disablePadding
                                selected={selectedIndex === i}
                                sx={{ "& .MuiTypography-root": { fontSize: mediaQuery && "14px" } }}
                            >
                                <ListItemButton
                                    component={Link}
                                    to={menu.path}
                                    onClick={() => {
                                        handleIndex(i);
                                        onChange(menu.childMenuText);
                                    }}
                                >
                                    {menu?.childMenuIcon}
                                    <ListItemText
                                        sx={{
                                            "&:active": {
                                                color: "#e40046"
                                            }
                                        }}
                                        primary={menu.childMenuText}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    );
                })}
        </Box>
    );

    return (
        <div>
            {(["left"] as const).map((anchor) => (
                <Box key={anchor}>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        sx={{
                            "& .MuiDrawer-modal": {
                                backgroundColor: "#fff"
                            },
                            "& .MuiBackdrop-root": {
                                backgroundColor: "rgba(0, 0, 0, 0)"
                            }
                        }}
                    >
                        {list(anchor)}
                    </Drawer>
                </Box>
            ))}
        </div>
    );
};

export default SideNavbar;
