import React from "react";

import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";

import { RadioButtonProps } from "./types";

const RadioButtons: React.FC<RadioButtonProps> = ({ id, list, name, error, value, defaultValue, onChange, errorText, label, toolTip, onBlur }) => {
    const halfListLength = Math.ceil(list.length / 2);
    const firstColumn = list.slice(0, halfListLength);
    const secondColumn = list.slice(halfListLength);

    return (
        <Box width="100%" display="flex" paddingY={0.5} justifyContent="start" gap={2} alignItems="start" position="relative">
            <Tooltip title={toolTip || ""} placement="top-start">
                <FormControl>
                    <Typography variant="body1" color="textPrimary" component="label" htmlFor={id}>
                        {label}
                    </Typography>
                    <RadioGroup
                        row
                        onBlur={onBlur}
                        id={id}
                        name={name}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={(event: any) => onChange(event.target.value)}
                    >
                        <Box display="flex" flexDirection="column" pr={10}>
                            {firstColumn.map(
                                (
                                    item: {
                                        label: string;
                                        value: string;
                                        disabled?: boolean;
                                    },
                                    index: number
                                ) => (
                                    <FormControlLabel
                                        key={item.label + item.value}
                                        disabled={item.disabled}
                                        sx={{
                                            mt: index === 0 ? 0 : -1,
                                            ml: 0,
                                            color: error ? "error" : "secondary",
                                            py: 1
                                        }}
                                        value={item.value}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: "#646464",
                                                    "&.Mui-checked": {
                                                        color: "#303f9f"
                                                    },
                                                    "&.Mui-disabled": {
                                                        color: "#E2E2E2"
                                                    }
                                                }}
                                            />
                                        }
                                        label={item.label}
                                    />
                                )
                            )}
                        </Box>
                        <Box display="flex" flexDirection="column" pr={5}>
                            {secondColumn.map(
                                (
                                    item: {
                                        label: string;
                                        value: string;
                                        disabled?: boolean;
                                    },
                                    index: number
                                ) => (
                                    <FormControlLabel
                                        key={item.label + item.value}
                                        disabled={item.disabled}
                                        sx={{
                                            mt: index === 0 ? 0 : -1,
                                            ml: 0,
                                            color: error ? "error" : "secondary",
                                            py: 1
                                        }}
                                        value={item.value}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: "#646464",
                                                    "&.Mui-checked": {
                                                        color: "#303f9f"
                                                    },
                                                    "&.Mui-disabled": {
                                                        color: "#E2E2E2"
                                                    }
                                                }}
                                            />
                                        }
                                        label={item.label}
                                    />
                                )
                            )}
                        </Box>
                    </RadioGroup>
                    {error && (
                        <Typography variant="body1" color="red">
                            {errorText}
                        </Typography>
                    )}
                </FormControl>
            </Tooltip>
        </Box>
    );
};

export default RadioButtons;
