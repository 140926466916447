import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import KeyIcon from "@mui/icons-material/Key";
import PasswordIcon from "@mui/icons-material/Password";
import { Box, Grid, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import HoC from "../../../Hoc";
import SaveButton from "../../../Hoc/Button/SaveButton";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Input from "../../../Hoc/Input";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerOpen, handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../../Redux/Reducers";

import "react-toastify/dist/ReactToastify.css";

// const KEYPAIRNAMEREGEX = '^(?!.*[\\/"][\\]:|<>+=;,?*@&\\s]|^_|_$|[.-]$)[^\\s]{1,64}$';

const AdminDetailsPage = ({ setIsAllSaved, isAllSaved }: any) => {
    const dispatch = useAppDispatch();
    const [osBasedOption, setOsBasedOption] = useState(false);
    const [afterSave, setAfterSave] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<any>({
        AuthorizationType: "sshKey",
        userNameSSH: "",
        keyPairName: "",
        userName: "",
        password: "",
        confirmPassword: ""
    });
    const { hostLayerType, hostUi, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;
    const isStorageSelected = selectedOptions?.AuthorizationType === "sshKey";
    const isPassword = selectedOptions?.AuthorizationType === "password";

    useEffect(() => {
        if (layer === en.isUiLayer) {
            setSelectedOptions((prevOptions: any) => ({
                ...prevOptions,
                AuthorizationType: hostUi?.virtualMachine?.authType?.AuthorizationType || "",
                userNameSSH: hostUi?.virtualMachine?.authType?.userNameSSH || "",
                keyPairName: hostUi?.virtualMachine?.authType?.keyPairName || "",
                userName: hostUi?.virtualMachine?.authType?.userName || "",
                password: hostUi?.virtualMachine?.authType?.password || "",
                confirmPassword: hostUi?.virtualMachine?.authType?.confirmPassword || ""
            }));
        } else if (layer === en.isApiLayer && hostApiLayer) {
            setSelectedOptions((prevOptions: any) => ({
                ...prevOptions,
                AuthorizationType: hostApiLayer?.virtualMachine?.authType?.AuthorizationType || "",
                userNameSSH: hostApiLayer?.virtualMachine?.authType?.userNameSSH || "",
                keyPairName: hostApiLayer?.virtualMachine?.authType?.keyPairName || "",
                userName: hostApiLayer?.virtualMachine?.authType?.userName || "",
                password: hostApiLayer?.virtualMachine?.authType?.password || "",
                confirmPassword: hostApiLayer?.virtualMachine?.authType?.confirmPassword || ""
            }));
        } else if (layer === en.isDbLayer && hostDbLayer) {
            setSelectedOptions((prevOptions: any) => ({
                ...prevOptions,
                AuthorizationType: hostDbLayer.AuthorizationType || "",
                userNameSSH: hostDbLayer?.virtualMachine?.authType?.userNameSSH || "",
                keyPairName: hostDbLayer?.virtualMachine?.authType?.keyPairName || "",
                userName: hostDbLayer?.virtualMachine?.authType?.userName || "",
                password: hostDbLayer?.virtualMachine?.authType?.password || "",
                confirmPassword: hostDbLayer?.virtualMachine?.authType?.confirmPassword || ""
            }));
        }

        if (hostUi?.virtualMachine?.image?.includes("Windows")) {
            setOsBasedOption(true);
        }
        if (hostApiLayer?.virtualMachine?.image?.includes("Windows")) {
            setOsBasedOption(true);
        }
        if (hostDbLayer?.virtualMachine?.image?.includes("Windows")) {
            setOsBasedOption(true);
        }
    }, [hostApiLayer, hostDbLayer, hostUi]);
    const handeDrawerOpen = () => {
        dispatch(handleDrawerOpen({ open: true, type: "ADMIN_DETAILS" }));
    };
    const handleSubmit = () => {
        try {
            // Validation logic here
            if (!selectedOptions.AuthorizationType) {
                throw new Error("Please select an authorization type.");
            }
            if (isStorageSelected && !selectedOptions.userNameSSH) {
                throw new Error("Please enter User Name.");
            }
            if (isStorageSelected && !selectedOptions.keyPairName) {
                throw new Error(
                    "Azure resource names cannot contain special characters \\/\"[]:|<>+=;,?*@&, whitespace, or begin with '_' or end with '.' or '-'"
                );
            }
            if (isPassword && !selectedOptions.userName) {
                throw new Error("Please enter User Name.");
            }
            if (isPassword && !selectedOptions.password) {
                throw new Error("Please enter password.");
            }
            if (isPassword && !selectedOptions.confirmPassword) {
                throw new Error("Please Confirm Your Password.");
            }
            if (selectedOptions.password !== selectedOptions.confirmPassword) {
                throw new Error("Confirm Password and Password not Matches.");
            }

            if (layer === en.isUiLayer) {
                dispatch(
                    handleHostUi({
                        virtualMachine: {
                            ...hostUi?.virtualMachine,
                            authType: {
                                AuthorizationType: selectedOptions?.AuthorizationType,
                                userNameSSH: selectedOptions?.userNameSSH,
                                keyPairName: selectedOptions?.keyPairName,
                                userName: selectedOptions?.userName,
                                password: selectedOptions?.password,
                                confirmPassword: selectedOptions?.confirmPassword
                            }
                        }
                    })
                );
            } else if (layer === en.isApiLayer) {
                dispatch(
                    handleHostApiLayer({
                        virtualMachine: {
                            ...hostApiLayer?.virtualMachine,
                            authType: {
                                AuthorizationType: selectedOptions?.AuthorizationType,
                                userNameSSH: selectedOptions?.userNameSSH,
                                keyPairName: selectedOptions?.keyPairName,
                                userName: selectedOptions?.userName,
                                password: selectedOptions?.password,
                                confirmPassword: selectedOptions?.confirmPassword
                            }
                        }
                    })
                );
            } else if (layer === en.isDbLayer) {
                dispatch(
                    handleHostDbLayer({
                        virtualMachine: {
                            ...hostDbLayer?.virtualMachine,
                            authType: {
                                AuthorizationType: selectedOptions?.AuthorizationType,
                                userNameSSH: selectedOptions?.userNameSSH,
                                keyPairName: selectedOptions?.keyPairName,
                                userName: selectedOptions?.userName,
                                password: selectedOptions?.password,
                                confirmPassword: selectedOptions?.confirmPassword
                            }
                        }
                    })
                );
            }
            setAfterSave(true);
        } catch (error: any) {
            console.error("Validation error:", error);
            // Show toast message with error message
            toast.error(error.message);
            handeDrawerOpen();
        }
    };
    useEffect(() => {
        isAllSaved.AdminDetailsPage = afterSave;
        setIsAllSaved({ ...isAllSaved });
    }, [afterSave]);
    const stateHandle = (inputName: string, inputValue: any) => {
        setSelectedOptions((prevOptions: any) => ({
            ...prevOptions,
            [inputName]: inputValue
        }));
    };

    const Options = osBasedOption
        ? [
              {
                  label: "Password",
                  value: "password",
                  icon: <PasswordIcon />
              }
          ]
        : [
              {
                  label: "Ssh Key",
                  value: "sshKey",
                  icon: <KeyIcon />
              },
              {
                  label: "Password",
                  value: "password",
                  icon: <PasswordIcon />
              }
          ];

    // Function to handle input change and show error if empty
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChange = (inputName: string, inputValue: any) => {
        if (isStorageSelected || isPassword) {
            if (inputValue !== selectedOptions[inputName]) {
                stateHandle(inputName, inputValue);
                setAfterSave(false);
            }
        }

        if (inputValue === "") {
            toast.error("Field cannot be empty.");
        }
    };

    return (
        <Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Grid item xs={12}>
                    <Typography variant="h6">Admin Details</Typography>
                </Grid>
                <Box py={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Select Authorization Type</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {Options.map((option: { label: string; value: string; icon: any }) => (
                                    <Grid
                                        item
                                        // className="transition"
                                        xs={6}
                                        key={option.value}
                                    >
                                        <HoC.SelectBox
                                            option={option}
                                            selectedOptions={selectedOptions.AuthorizationType}
                                            setSelectedOptions={setSelectedOptions}
                                            category="AuthorizationType"
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Grid container>
                <Grid item xs={12}>
                    {isStorageSelected ? (
                        <Box>
                            <Input
                                type="text"
                                name="userNameSSH"
                                value={selectedOptions.userNameSSH || ""}
                                onChange={(value) => handleInputChange("userNameSSH", value)}
                                placeHolder="User Name"
                                // regex={KEYPAIRNAMEREGEX}
                            />
                            <Input
                                type="text"
                                name="keyPairName"
                                // regex={KEYPAIRNAMEREGEX}
                                value={selectedOptions.keyPairName || ""}
                                onChange={(value) => handleInputChange("keyPairName", value)}
                                placeHolder="Key Pair Name"
                            />
                        </Box>
                    ) : null}
                    {isPassword ? (
                        <Box>
                            <Input
                                type="text"
                                name="userName"
                                value={selectedOptions.userName || ""}
                                onChange={(value) => handleInputChange("userName", value)}
                                placeHolder="Username"
                                // regex={KEYPAIRNAMEREGEX}
                            />
                            <Input
                                type="text"
                                name="password"
                                value={selectedOptions.password || ""}
                                onChange={(value) => handleInputChange("password", value)}
                                placeHolder="Password"
                            />
                            <Input
                                type="text"
                                name="confirmPassword"
                                value={selectedOptions.confirmPassword || ""}
                                onChange={(value) => handleInputChange("confirmPassword", value)}
                                placeHolder="Confirm Password"
                            />
                        </Box>
                    ) : null}
                </Grid>
                <Box sx={{ display: afterSave ? "none" : "inline" }}>
                    <SaveButton handleSubmit={handleSubmit} />
                </Box>
            </Grid>
        </Box>
    );
};

export default AdminDetailsPage;
