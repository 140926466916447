interface Routes {
    home: string;
    // Manage Project flow
    manageProject: string;
    projectDetails: string;
    technologies: string;
    appComponents: string;
    branding: string;
    // Generate Project
    generateProject: string;

    // Manage Page flow
    managePage: string;
    pageType: string;
    pageForm: string;
    listcapture: string;
    reportcapture: string;
    crudcapture: string;

    // Generate Page
    generatePage: string;

    // UI Layer flows

    uiLayer: string;

    // vm flow
    uiLayerVmPlan: string;
    uiLayerVmAdmin: string;
    uiLayerVmPort: string;
    uiLayerVmDiskStorage: string;
    uiLayerVmVirtualNetwork: string;
    uiLayerVmBiScript: string;
    //  app flow
    uiLayerWebAppDetails: string;
    uiLayerAppServicePlan: string;
    uiLayerAppServiceBiScript: string;

    // Api Layer flows
    apiLayer: string;

    // vm flow
    apiLayerVmPlan: string;
    apiLayerVmAdmin: string;
    apiLayerVmPort: string;
    apiLayerVmDiskStorage: string;
    apiLayerVmVirtualNetwork: string;
    apiLayerVmBiScript: string;
    // app flow
    apiLayerWebAppDetails: string;
    apiLayerAppServicePlan: string;
    apiLayerAppServiceBiScript: string;
    // function flow
    apiLayerFunctionAppDetails: string;
    apiLayerFunctionDetails: string;
    apiLayerFunctionVnet: string;
    apiLayerFunctionAppServicePlan: string;
    apiLayerFunctionBiScript: string;

    // services flow

    servicesStorageAccount: string;

    // Other flows
    selectProject: string;
    selectHost: string;
    downloadJson: string;
    editData: string;
    editList: string;
    editReport: string;
    editjson: string;
    login: string;
    IaasScreen: string;
    dbLayer: string;
    selectAppType: string;
    vmAdmin: string;
    vmDiskStorage: string;
    vmPort: string;
    vmVirtualNetwork: string;
    webAppDetails: string;
    functionAppDetails: string;
    functionVNet: string;
    vmBiscript: string;
    appServicesBiScript: string;
    functionsBiScript: string;
    dbSql: string;
    servicesLayer: string;
    functionOtherInfo: string;
    testingArea: string;
    appServicePlan: string;
    servicesAzureCache: string;
    servicesBiscript: string;
}
const RoutesEnum: Routes = {
    home: "/",
    // Manage Project flow
    manageProject: "/manageproject",
    projectDetails: "/manageproject/projectdetails",
    technologies: "/manageproject/technologies",
    appComponents: "/manageproject/appcomponents",
    branding: "/manageproject/branding",
    // Generate Project
    generateProject: "/generateproject",

    // Manage Page flow
    managePage: "/managepage",
    pageType: "/managepage/pagetype",
    pageForm: "/managepage/pageform",
    listcapture: "/managepage/listform",
    reportcapture: "/managepage/reportform",
    crudcapture: "/managepage/crudform",

    // Generate Page
    generatePage: "/generatepage",

    // UI Layer flows
    uiLayer: "/uilayer",

    // Vm flow
    uiLayerVmPlan: "/uilayer/vmplan",
    uiLayerVmAdmin: "/uilayer/vmadmin",
    uiLayerVmPort: "/uilayer/vmport",
    uiLayerVmDiskStorage: "/uilayer/vmdiskstorage",
    uiLayerVmVirtualNetwork: "/uilayer/vmvirtualnetwork",
    uiLayerVmBiScript: "/uilayer/vmbiscript",

    // App flow
    uiLayerWebAppDetails: "/uilayer/webappdetails",
    uiLayerAppServicePlan: "/uilayer/appserviceplan",
    uiLayerAppServiceBiScript: "/uilayer/appservicebiscript",

    // Api Layer flows
    apiLayer: "/apilayer",

    // Vm flow
    apiLayerVmPlan: "/apilayer/vmplan",
    apiLayerVmAdmin: "/apilayer/vmadmin",
    apiLayerVmPort: "/apilayer/vmport",
    apiLayerVmDiskStorage: "/apilayer/vmdiskstorage",
    apiLayerVmVirtualNetwork: "/apilayer/vmvirtualnetwork",
    apiLayerVmBiScript: "/apilayer/vmbiscript",

    // App flow
    apiLayerWebAppDetails: "/apilayer/webappdetails",
    apiLayerAppServicePlan: "/apilayer/appserviceplan",
    apiLayerAppServiceBiScript: "/apilayer/appservicebiscript",

    // function flow
    apiLayerFunctionAppDetails: "/apilayer/functionappdetails",
    apiLayerFunctionDetails: "/apilayer/functiondetails",
    apiLayerFunctionVnet: "/apilayer/functionvnet",
    apiLayerFunctionAppServicePlan: "/apilayer/appserviseplan",
    apiLayerFunctionBiScript: "/apilayer/functionbiscript",

    // services flow
    servicesStorageAccount: "/services/storageaccount",
    servicesAzureCache: "/services/azurecache",
    servicesBiscript: "/services/biscript",
    // Other flows
    selectProject: "/selectproject",
    selectHost: "/selecthost",
    downloadJson: "/downloadjson",
    editData: "/editdata",
    editList: "/editlist",
    editReport: "/editreport",
    editjson: "/editJson",
    login: "/login",
    IaasScreen: "/iaas",
    dbLayer: "/dblayer",
    selectAppType: "/selectapptype",
    vmAdmin: "/vmadmin",
    vmDiskStorage: "/vmdiskstorage",
    vmPort: "/vmport",
    vmVirtualNetwork: "/vmvirtualnetwork",
    webAppDetails: "/webappdetails",
    functionAppDetails: "/functionappdetails",
    functionVNet: "/functionvnet",
    vmBiscript: "/vmbiscript",
    appServicesBiScript: "/appservicesbiscript",
    functionsBiScript: "/functionsbiscript",
    dbSql: "/dbsql",
    servicesLayer: "serviceslayer",
    functionOtherInfo: "/functiondetails",
    testingArea: "/testing",
    appServicePlan: "/appserviceplan"
};

export default RoutesEnum;
