/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, Button, FormControlLabel, Grid, Paper, Switch, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GridRowEditStopReasons } from "@mui/x-data-grid";

import API from "../../API";
import { en, messages } from "../../dictionary/en";
import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import CheckBox from "../../Hoc/Checkbox";
import GenerateForm from "../../Hoc/GenerateForms";
import { columns, contactDetails } from "../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handlePageData, handlePages } from "../../Redux/Reducers";
import { downloadJsonFile } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../Theme/Theme";
import CloningSourceCode from "../codeCloning/CodeCloning";

import ProjectList from "./ProjectList";

interface DataCaptureFormProps {}

const PageForm: React.FC<DataCaptureFormProps> = () => {
    const { projectData, newPages, pageData } = useAppSelector((state) => state);
    console.log("newPages: ", newPages);
    const dispatch = useAppDispatch();
    const [isApi, setIsApi] = useState({
        isApi: false,
        apiUrl: "",
        inputParams: ""
    });
    const [state, setState] = useState<any[]>(newPages?.pageDetails ?? []);
    const [defaultCheckbox, setDefaultCheckbox] = useState<boolean>(false);
    const [projectJson, setProjectJson] = useState<any>();
    const [selectedProject, setSelectedProject] = useState<any>();
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    const navigate = useNavigate();
    const handleOpen = () => {
        dispatch(handleDrawerOpen({ open: true, type: "DATA_FORM" }));
    };

    const handleClose = () => {
        if ((isApi.apiUrl === "" || isApi.inputParams === "") && isApi.isApi) {
            setIsApi((prev) => ({ ...prev, isApi: false }));
        }
        dispatch(handleDrawerClose());
    };
    const handleDrawerSubmit = () => {
        const URLPATTERN = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
        if (URLPATTERN.test(isApi.apiUrl)) {
            dispatch(handleDrawerClose());
            return true;
        }
        toast.error("Invaild URL");
        return false;
    };

    const handleSwitch = (value: any) => {
        if (value.target.checked) handleOpen();
        setIsApi((prev) => ({ ...prev, isApi: value.target.checked }));
    };

    const handleRowEditStop = (params: any, event: any) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const processRowUpdate = (newRow: any) => {
        const updatedRow = { ...newRow, isNew: false };
        setState((prevState: any) => prevState.map((r: any) => (r.id === newRow.id ? updatedRow : r)));

        return updatedRow;
    };

    const handleDeleteRows = async () => {
        try {
            const updatedState = state.filter((item: any) => !selectionModel.includes(item.id));
            await dispatch(handlePages({ ...newPages, updatedState }));
            setState(updatedState);
        } catch (error) {
            console.error(messages?.anErrormessage, error);
        }
    };

    const formikProps = useFormik({
        initialValues: {
            ...contactDetails.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values) => {
            if (isApi.isApi) {
                const aipUrl = handleDrawerSubmit();
                if (!aipUrl) {
                    return;
                }
            }

            setState([...state, values]);
            dispatch(handlePages({ pageDetails: [...newPages.pageDetails, values] }));

            formikProps.resetForm();
            handleClose();
            setIsApi({
                isApi: false,
                apiUrl: "",
                inputParams: ""
            });
            toast.success("Field added successfully");
        }
    });

    const handleCheck = async (defaultCheck: boolean) => {
        if (!state.length) {
            toast.warning(messages.kindlyProvideDetails);
        } else {
            dispatch(handleDrawerOpen({ open: true, type: en.appendProjectType }));
            setDefaultCheckbox(defaultCheck);
        }
    };

    const handleProjectModal = async (project: any) => {
        setSelectedProject(project);
        const file: any = project;
        const Menus: any = file?.projectDetails?.Menus || [];
        if (file) {
            try {
                const newMenu = { childMenuText: newPages?.childMenuText, path: newPages?.pagePath };
                const projectDetails = {
                    ...file?.projectDetails,
                    Menus: [...Menus, newMenu]
                };
                const updatedProject = {
                    ...file,
                    projectDetails,
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    pages: [...file?.pages, newPages],
                    IsAddInProject: true
                };
                setProjectJson(updatedProject);
                await API.Projects.updateProjects(updatedProject);
            } catch (parseError) {
                console.error(messages.jsonparseerror, parseError);
            }
        }
    };

    const handleAppendProjectCancel = () => {
        if (!selectedProject) {
            setDefaultCheckbox(false);
        }
        dispatch(handleDrawerClose());
    };

    const handleApppendProjectOk = () => {
        if (selectedProject) {
            dispatch(handleDrawerClose());
            setDefaultCheckbox(true);
        } else {
            toast.error(messages?.PleaseChooseProject);
        }
    };

    const handleDownloadJson = async () => {
        if (newPages?._id) {
            const res: any = await API.Projects.updatePages(newPages);
            const updatedPage = pageData?.filter((e: any) => e._id !== newPages?._id);
            if (res?.success) {
                downloadJsonFile({ ...newPages }, `${newPages?.pageName}.json`);
                dispatch(handlePageData([...updatedPage, newPages]));
                navigate(RoutesEnum.managePage);
            }
        } else {
            const response: any = await API.Projects.createPages(newPages);
            if (response?.success) {
                downloadJsonFile({ ...newPages }, `${newPages?.pageName}.json`);
                dispatch(handlePageData([...pageData, response?.data]));
                navigate(RoutesEnum.managePage);
            }
        }
    };
    const handleIsApi = (name: any, value: any) => {
        setIsApi((prev: any) => ({
            ...prev,
            [name]: value
        }));
        formikProps.setFieldValue(name, value);
        if (!formikProps?.values?.isApi) {
            formikProps.setFieldValue("isApi", isApi.isApi);
        }
    };
    return (
        <>
            <Box component="form">
                <Box
                    component="div"
                    width="100%"
                    height="calc(100vh - 100px)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="100px"
                >
                    <Paper elevation={3} sx={{ height: "90%", width: "90%", p: 2, pl: 2 }}>
                        <Box sx={{ height: mediaQuery ? "470px" : "100%", textAlign: "end" }}>
                            <Button onClick={handleOpen} sx={{ marginBottom: "8px" }}>
                                Add Field
                            </Button>
                            <Grid container spacing={1} sx={{ overflow: mediaQuery && "auto" }}>
                                {/* Table Grid */}
                                <Grid item xs={12} sx={{ height: "52vh" }}>
                                    <HoC.DataTable
                                        rows={state.map((row, index) => ({
                                            ...row,
                                            id: index + 1,
                                            fieldType: row?.fieldType?.label || row?.fieldType,
                                            mandatory: row?.mandatory?.label || row?.mandatory,
                                            fieldOrder: row?.fieldOrder?.label || row?.fieldOrder,
                                            options: row?.fieldType?.options || row?.fieldType?.options
                                        }))}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 5 }
                                            }
                                        }}
                                        pageSizeOptions={[5, 10]}
                                        checkboxSelection
                                        onRowSelectionModelChange={(newRowSelectionModel: any) => {
                                            console.log("newRowSelectionModel: ", newRowSelectionModel);
                                            setSelectionModel(newRowSelectionModel);
                                            setState((prevState) =>
                                                prevState.map((row, index) => ({
                                                    ...row,
                                                    id: index + 1,
                                                    fieldType: row?.fieldType,
                                                    mandatory: row?.mandatory,
                                                    fieldOrder: row?.fieldOrder
                                                }))
                                            );
                                        }}
                                        onRowEditStop={handleRowEditStop}
                                        processRowUpdate={processRowUpdate}
                                    />
                                </Grid>
                                <CheckBox
                                    id="appendWithProject"
                                    name="appendWithProject"
                                    checked={defaultCheckbox}
                                    onChange={handleCheck}
                                    disabled={false}
                                    label="Select to Append with Project"
                                    toolTip="Select to Append with Project"
                                />
                            </Grid>
                            <Grid item xs={12} py={1} display="flex" justifyContent="space-between" gap={2}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        navigate(RoutesEnum.pageType);
                                    }}
                                >
                                    {en.backbtn}
                                </Button>

                                {defaultCheckbox && projectJson ? (
                                    <CloningSourceCode jsonData={projectJson} />
                                ) : (
                                    <Button style={buttonStyle} disabled={!(state?.length > 0)} onClick={handleDownloadJson}>
                                        {en.genjson}
                                    </Button>
                                )}
                            </Grid>
                            <HoC.CustomDrawer
                                type="DATA_FORM"
                                handleDrawerOnClose={handleClose}
                                title={`${newPages?.initialState?.pageName || newPages?.pageName} Config`}
                            >
                                {/* Field Grid */}
                                <Box component="form" onSubmit={formikProps.handleSubmit}>
                                    <Grid item xs={12}>
                                        <Box width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="flex-start">
                                            <Typography title="Personal Details" variant="h6">
                                                Fields
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GenerateForm
                                            FormData={contactDetails?.map((e: any) => {
                                                if (newPages.pageDetails.length && e.Name === "fieldOrder") {
                                                    return { ...e, List: e.List.slice(newPages.pageDetails.length, e.List.length) };
                                                }
                                                return e;
                                            })}
                                            FormikProps={formikProps}
                                        />
                                        {formikProps?.values?.fieldType?.value === "Radio" || formikProps?.values?.fieldType?.value === "Select" ? (
                                            <Grid item xs={12}>
                                                <HoC.Input
                                                    value={formikProps?.values?.fieldType?.options}
                                                    label="Options"
                                                    name="options"
                                                    type="text"
                                                    required
                                                    toolTip="Enter Opions separated by comma"
                                                    onChange={(value: any) => formikProps.setFieldValue("fieldType.options", value)}
                                                />
                                            </Grid>
                                        ) : null}
                                        {/* Is API field  */}

                                        {isApi.isApi ? (
                                            <Box>
                                                <Grid item xs={12}>
                                                    <Box
                                                        paddingY={1}
                                                        width="100%"
                                                        display="flex"
                                                        justifyContent="space-around"
                                                        gap={2}
                                                        alignItems="center"
                                                        position="relative"
                                                    >
                                                        <HoC.Input
                                                            name="apiUrl"
                                                            type="text"
                                                            onChange={(value: any) => handleIsApi("apiUrl", value)}
                                                            label="API URL"
                                                            // error={error}
                                                            value={formikProps?.values?.apiUrl}
                                                            required
                                                            toolTip="Enter API URL"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box
                                                        paddingY={1}
                                                        width="100%"
                                                        display="flex"
                                                        justifyContent="space-around"
                                                        gap={2}
                                                        alignItems="center"
                                                        position="relative"
                                                    >
                                                        <HoC.Input
                                                            toolTip="Enter API URL"
                                                            name="inputParams"
                                                            type="text"
                                                            onChange={(value: any) => handleIsApi("inputParams", value)}
                                                            required
                                                            label="Input Params "
                                                            // error={error}
                                                            value={formikProps?.values?.inputParams}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        ) : null}

                                        <Grid display="flex" justifyContent="space-between" py={1} gap={2} mt={1}>
                                            <Box>
                                                <FormControlLabel
                                                    label="Is this field an API?"
                                                    control={<Switch checked={isApi.isApi} onChange={handleSwitch} />}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", gap: "8px" }}>
                                                {selectionModel.length > 0 ? (
                                                    <Button
                                                        variant="contained"
                                                        color="inherit"
                                                        disabled={!(selectionModel.length > 0)}
                                                        onClick={handleDeleteRows}
                                                    >
                                                        {en.deletebtn}
                                                    </Button>
                                                ) : (
                                                    <Button variant="contained" color="inherit" onClick={() => formikProps.resetForm()}>
                                                        {en.clearbtn}
                                                    </Button>
                                                )}
                                                <Button style={buttonStyle} type="submit">
                                                    {en.savebtn}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </HoC.CustomDrawer>
                        </Box>
                    </Paper>
                </Box>
                {/* IS API Drawer */}
            </Box>
            {/* Append Project Drawer */}
            <HoC.CustomDrawer type="APPEND_PROJECT" handleDrawerOnClose={handleClose} title="Project List">
                <ProjectList
                    projectData={projectData}
                    selectedProject={selectedProject}
                    handleProjectModal={handleProjectModal}
                    handleCancel={handleAppendProjectCancel}
                    handleOk={handleApppendProjectOk}
                />
            </HoC.CustomDrawer>
        </>
    );
};

export default PageForm;
