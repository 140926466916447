import React from "react";

import { Button, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import { disabled, NextButtonStyle } from "../../../Theme/Theme";

import { NextButtonProps } from "./types";

const NextButton: React.FC<NextButtonProps> = ({ handleClick, submit, disableNext, buttonText, buttonType }) => {
    return (
        <Button
            variant={disableNext ? "outlined" : "contained"}
            color="primary"
            onClick={handleClick}
            style={disableNext ? disabled : NextButtonStyle}
            size="small"
            disabled={disableNext}
            sx={{ textTransform: "uppercase" }}
            type={buttonType}
        >
            <Typography>{submit ? en.submitbtn : buttonText || en.nxtbtn}</Typography>
        </Button>
    );
};

export default NextButton;
