enum APiEndPoints {
    // user
    userLogin = "/login",
    //  Projects
    Project = "/projects",
    Page = "/pages",
    Host = "/host",

    cloneFolder = "/clone-folder",
    downloadFolder = "/download-zipfile",
    downloadForm = "/download-form",
    downloadFile = "/download-file",
    apidowload = "/apidowload",
    scriptdowload = "/scriptdowload",
    sqlscriptdowload = "/sqlscriptdowload",
    swaggerdowload = "/swaggerdowload",
    dictionarydowload = "/dictionarydowload",
    jestdowload = "/jestdowload",
    sqlscriptdowloadlistpage = "/sqlscriptdowloadlistpage"
}
export default APiEndPoints;
