/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ComputerIcon from "@mui/icons-material/Computer";
import FunctionsIcon from "@mui/icons-material/Functions";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import DbIcon from "@mui/icons-material/Storage";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { en } from "../../dictionary/en";
import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import { createHostConfig } from "../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../Redux/Reducers";

const SelectAppType = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hostLayerType, hostUi, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;
    const [select, setselect] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<string>(select || "");
    useEffect(() => {
        if (layer === en.isUiLayer) {
            setSelectedOptions(hostUi?.hostType || "");
        } else if (layer === en.isApiLayer) {
            setSelectedOptions(hostApiLayer?.hostType || "");
        } else if (layer === en.isDbLayer) {
            setSelectedOptions(hostDbLayer?.hostType || "");
        }
    }, [layer, hostUi?.hostType, hostApiLayer?.hostType, hostDbLayer?.hostType]);

    let options: any = [];
    if (layer === en.isDbLayer) {
        options = [
            {
                value: createHostConfig?.appValue?.value1,
                label: createHostConfig?.appType?.type1,
                icon: <ComputerIcon />
            },
            {
                value: createHostConfig?.appValue?.value4,
                label: createHostConfig?.appType?.type4,
                icon: <DbIcon />
            }
        ];
    } else if (layer === en.isUiLayer) {
        options = [
            {
                value: createHostConfig?.appValue?.value1,
                label: createHostConfig?.appType?.type1,
                icon: <ComputerIcon />
            },
            {
                value: createHostConfig?.appValue?.value2,
                label: createHostConfig?.appType?.type2,
                icon: <SettingsApplicationsIcon />
            }
        ];
    } else {
        options = [
            {
                value: createHostConfig?.appValue?.value1,
                label: createHostConfig?.appType?.type1,
                icon: <ComputerIcon />
            },
            {
                value: createHostConfig?.appValue?.value2,
                label: createHostConfig?.appType?.type2,
                icon: <SettingsApplicationsIcon />
            },
            {
                value: createHostConfig?.appValue?.value3,
                label: createHostConfig?.appType?.type3,
                icon: <FunctionsIcon />
            }
        ];
    }

    const handleOptionsSubmit = () => {
        switch (layer) {
            case en.isUiLayer:
                dispatch(
                    handleHostUi({
                        virtualMachine: {
                            ...hostUi.virtualMachine,
                            hostType: selectedOptions
                        }
                    })
                );
                break;
            case en.isApiLayer:
                dispatch(
                    handleHostApiLayer({
                        virtualMachine: {
                            ...hostApiLayer.virtualMachine,
                            hostType: selectedOptions
                        }
                    })
                );
                break;
            case en.isDbLayer:
                dispatch(
                    handleHostDbLayer({
                        virtualMachine: {
                            ...hostDbLayer.virtualMachine,
                            hostType: selectedOptions
                        }
                    })
                );
                break;
            default:
                break;
        }

        switch (selectedOptions) {
            case createHostConfig?.appValue?.value1:
                navigate(RoutesEnum.uiLayerVmPlan);
                break;
            case createHostConfig?.appValue?.value2:
                navigate(RoutesEnum.webAppDetails);
                break;
            case createHostConfig?.appValue?.value3:
                navigate(RoutesEnum.functionAppDetails);
                break;
            case createHostConfig?.appValue?.value4:
                navigate(RoutesEnum.dbSql);
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <HoC.CardComponent cardTitle="Host Type" handleNext={handleOptionsSubmit}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box px={4} py={2}>
                    <Grid container spacing={2}>
                        {options.map((option: { label: string; value: string; icon: any }) => (
                            <Grid item xs={12} sm={6}>
                                <HoC.SelectBox option={option} setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </HoC.CardComponent>
    );
};

export default SelectAppType;
