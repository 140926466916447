import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { optionLabels } from "../../inputconfig";

import { IconButtonProps } from "./types";

const IconButtonComp: React.FC<IconButtonProps> = ({ navbar, item, handleIcon }) => {
    return (
        <Box
            sx={{
                backgroundColor: navbar.value === item.option ? "#FFA500" : "default",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                minWidth: "80px",
                borderRadius: "10px",
                color: navbar.value === item.option ? "#default" : "#303f9f"
                // mx: 5
            }}
        >
            <IconButton
                onClick={handleIcon}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                {item.icon}
                <Typography variant="body2">{optionLabels[item.option]}</Typography>
            </IconButton>
        </Box>
    );
};
export default IconButtonComp;
