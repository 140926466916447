import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import HoC from "../../../Hoc";
import GenerateForm from "../../../Hoc/GenerateForms";
import Input from "../../../Hoc/Input";
import {
    Auth0Credentials,
    createProjectConfig,
    EmailCredentials,
    facebookCredentials,
    FacebookMediaCredentials,
    FtpCredentials,
    GoogleCredentials,
    PaypalCredentials,
    RazorPayCredentials,
    StorageCredentials,
    StripeCredentials,
    VimeoCredentials,
    YoutubeMediaCredentials
} from "../../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handleProjects } from "../../../Redux/Reducers";
import { componentsType, MenusType } from "../../../Redux/types";
import { buttonStyle } from "../../../Theme/Theme";
// import { AuthenticationType } from "../../../Redux/types";
import { AuthenticationOptions, ChatOptions, FileUploadOptions, MediaOptions, PaymentsOptions } from "../Uitilities";

import {
    auth0FormikProps,
    emailFormikProps,
    facebokFormikProps,
    ftpFormikProps,
    googleFormikProps,
    paypalFormikProps,
    razorPayFormikProps,
    storageFormikProps,
    stripeFormikProps,
    validateAuthentication,
    validateMedia,
    validatePayment,
    vimeoMediaFormikProps,
    youtubeMediaFormikProps
} from "./utilities";

const SelectComponent = () => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { newProject } = useAppSelector((state) => state);
    console.log(newProject);
    // don't remove this functionality need for future sprint
    // const [authentication, setAuthentication] = useState<AuthenticationType[]>(newProject.component.Authentication);

    const [selectedOptions, setSelectedOptions] = useState<componentsType>({
        Authentication: newProject?.component.Authentication,
        Payments: newProject?.component?.Payments,
        Media: newProject?.component?.Media,
        "File Upload": newProject?.component["File Upload"],
        Chat: newProject?.component?.Chat
    });
    const [selectedComponent, setSelectedComponent] = useState("");
    const [drawerTitle, setDrawerTitle] = useState("");
    const [menu, setMenu] = useState<MenusType>({
        addToMenu: false,
        childMenuText: "",
        path: ""
    });
    const mediaQuery: any = useMediaQuery("(max-width:1400px)");
    // don't remove this functionality need for future sprint
    // const handleAuthenticationOptionsChange = (value: any) => {
    //     console.log(value);
    //     if (authentication.find((e: AuthenticationType) => e.authenticationType === value)) {
    //         setAuthentication(authentication.filter((e) => e.authenticationType !== value));
    //     } else {
    //         setAuthentication([...authentication, { authenticationType: value }]);
    //     }
    // };

    const handleInputChange = (category: string, value: any) => {
        setMenu((prev) => ({ ...prev, [category]: value }));
    };

    const initialValues =
        selectedComponent === createProjectConfig.projectData.components.component1.value1
            ? emailFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component1.value2
            ? facebokFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component1.value3
            ? googleFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component1.value4
            ? auth0FormikProps
            : selectedComponent === createProjectConfig.projectData.components.component2.value1
            ? razorPayFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component2.value2
            ? paypalFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component2.value3
            ? stripeFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component3.value1
            ? youtubeMediaFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component3.value2
            ? vimeoMediaFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component3.value3
            ? facebokFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component4.value1
            ? ftpFormikProps
            : selectedComponent === createProjectConfig.projectData.components.component4.value2
            ? storageFormikProps
            : {};
    const formikProps: any = useFormik({
        initialValues,

        onSubmit: async (values: any) => {
            if (
                selectedComponent === createProjectConfig.projectData.components.component1.value1 ||
                selectedComponent === createProjectConfig.projectData.components.component1.value2 ||
                selectedComponent === createProjectConfig.projectData.components.component1.value3 ||
                selectedComponent === createProjectConfig.projectData.components.component1.value4
            ) {
                setSelectedOptions((prev) => ({ ...prev, Authentication: [{ ...prev.Authentication[0], ...values }] }));
            } else if (
                selectedComponent === createProjectConfig.projectData.components.component2.value1 ||
                selectedComponent === createProjectConfig.projectData.components.component2.value2 ||
                selectedComponent === createProjectConfig.projectData.components.component2.value3
            ) {
                setSelectedOptions((prev) => ({ ...prev, Payments: [{ ...prev.Payments[0], ...values }] }));
            } else if (
                selectedComponent === createProjectConfig.projectData.components.component3.value1 ||
                selectedComponent === createProjectConfig.projectData.components.component3.value2 ||
                selectedComponent === createProjectConfig.projectData.components.component3.value3
            ) {
                setSelectedOptions((prev) => ({ ...prev, Media: [{ ...prev.Media[0], ...values }] }));
            } else if (
                selectedComponent === createProjectConfig.projectData.components.component4.value1 ||
                selectedComponent === createProjectConfig.projectData.components.component4.value2
            ) {
                setSelectedOptions((prev) => ({ ...prev, "File Upload": [{ ...prev["File Upload"][0], ...values }] }));
            } else {
                setSelectedOptions((prev) => ({ ...prev, Chat: values }));
            }

            dispatch(handleDrawerClose());
        }
    });
    console.log(selectedOptions);

    const handleAuthentications = (value: any, title: any) => {
        console.log(selectedOptions.Authentication[0].authenticationType !== value);
        if (selectedOptions.Authentication[0].authenticationType !== value) {
            setSelectedOptions((prev) => ({ ...prev, Authentication: [{ authenticationType: value }] }));
            setDrawerTitle(title);
            formikProps.resetForm();
        }
        setSelectedComponent(value);
    };

    const handleMedia = (value: any, title: any) => {
        setSelectedOptions((prev) => ({ ...prev, Media: [{ mediaType: value }] }));
        setSelectedComponent(value);
        setDrawerTitle(title);
    };

    const handlePayment = (value: any, title: any) => {
        setSelectedOptions((prev) => ({ ...prev, Payments: [{ paymentType: value }] }));
        setSelectedComponent(value);
        setDrawerTitle(title);
    };

    const handleFileUpload = (value: any, title: any) => {
        setSelectedOptions((prev) => ({ ...prev, "File Upload": [{ fileUploadType: value }] }));
        setSelectedComponent(value);
        setDrawerTitle(title);
    };
    const handleChat = (value: any) => {
        setSelectedOptions((prev) => ({ ...prev, Chat: value }));
        setSelectedComponent(value);
    };

    const handleDrawer = () => {
        dispatch(handleDrawerOpen({ open: true, type: "COMPONENT_CREDENTIALS" }));
    };

    const handleBack = () => {
        navigate(RoutesEnum.technologies);
    };

    // eslint-disable-next-line consistent-return
    const handleNext = () => {
        console.log("!validateAuthentication(selectedOptions): ", validateAuthentication(selectedOptions));
        if (!validateAuthentication(selectedOptions)) {
            setSelectedComponent(selectedOptions.Authentication[0].authenticationType || "emailLogin");
            toast.error("Please select login type.");
            return handleDrawer();
        }

        if (!validateMedia(selectedOptions)) {
            setSelectedComponent(selectedOptions.Media[0].mediaType || "vimeo");
            toast.error("Please fill the credentials.");
            return handleDrawer();
        }

        if (!validatePayment(selectedOptions)) {
            toast.error("Please fill the credentials.");
            return handleDrawer();
        }
        dispatch(handleProjects({ component: selectedOptions, projectDetails: { ...newProject.projectDetails, Menus: [{ ...menu }] } }));
        navigate(RoutesEnum.branding);
    };
    return (
        <HoC.CardComponent cardTitle="Application Component" handleNext={handleNext} handleBack={handleBack}>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                sx={{
                    overflow: "auto",
                    display: "flex"
                }}
            >
                <Box px={4} py={2} height="100%">
                    <Grid container spacing={2} height="100%">
                        <Grid item xs={6} sx={{ gap: 2.5 }}>
                            <Box sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 2.5 }}>
                                <Box
                                    sx={{
                                        boxShadow: "0px 2px 8px 5px rgba(0,0,0,0.1)",

                                        borderRadius: "10px",

                                        flex: "1",

                                        p: 2,

                                        paddingTop: mediaQuery && "0px",

                                        paddingBottom: mediaQuery && "0px"
                                    }}
                                >
                                    <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
                                        Authentication *
                                    </Typography>

                                    <Grid container spacing={2} p={1}>
                                        {AuthenticationOptions.map((option: { label: string; value: string; icon: any }) => (
                                            <Grid item sm={6} sx={{ paddingTop: "8px!important" }}>
                                                <Box>
                                                    <HoC.SelectBox
                                                        option={option}
                                                        openDrawer={handleDrawer}
                                                        setSelectedOptions={handleAuthentications}
                                                        selectedOptions={selectedOptions.Authentication[0].authenticationType}
                                                    />

                                                    {/* // don't remove this functionality need for future sprint */}

                                                    {/* <HoC.SelectBox

                                                        option={option}

                                                        setSelectedOptions={handleAuthenticationOptionsChange}

                                                        selectedOptions={

                                                            authentication[

                                                                authentication.findIndex(

                                                                    (e: AuthenticationType) => e.authenticationType === option.value

                                                                )

                                                            ]?.authenticationType

                                                        }

                                                    /> */}
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Box
                                    sx={{
                                        boxShadow: "0px 2px 8px 5px rgba(0,0,0,0.1)",

                                        borderRadius: "10px",

                                        flex: "1",

                                        p: 2,

                                        paddingTop: mediaQuery && "0px",

                                        paddingBottom: mediaQuery && "0px"
                                    }}
                                >
                                    <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
                                        Payments
                                    </Typography>

                                    <Grid container spacing={2} p={1}>
                                        {PaymentsOptions.map((option: { label: string; value: string; icon: any }) => (
                                            <Grid item sm={6} sx={{ paddingTop: "8px!important" }}>
                                                <Box>
                                                    <HoC.SelectBox
                                                        option={option}
                                                        openDrawer={handleDrawer}
                                                        setSelectedOptions={handlePayment}
                                                        selectedOptions={selectedOptions.Payments[0].paymentType}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6} height="100%">
                            <Box sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 2.5 }}>
                                <Box
                                    sx={{
                                        boxShadow: "0px 2px 8px 5px rgba(0,0,0,0.1)",

                                        borderRadius: "10px",

                                        flex: "1",

                                        p: 2,

                                        paddingTop: mediaQuery && "0px",

                                        paddingBottom: mediaQuery && "0px"
                                    }}
                                >
                                    <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
                                        Media
                                    </Typography>

                                    <Grid container spacing={2} p={1}>
                                        {MediaOptions.map((option: { label: string; value: string; icon: any }) => (
                                            <Grid item sm={6} sx={{ paddingTop: "8px!important" }}>
                                                <Box>
                                                    <HoC.SelectBox
                                                        option={option}
                                                        openDrawer={handleDrawer}
                                                        setSelectedOptions={handleMedia}
                                                        selectedOptions={selectedOptions.Media[0].mediaType}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                <Box
                                    sx={{
                                        boxShadow: "0px 2px 8px 5px rgba(0,0,0,0.1)",

                                        borderRadius: "10px",

                                        flex: "1",

                                        p: 2,

                                        paddingTop: mediaQuery && "0px",

                                        paddingBottom: mediaQuery && "0px"
                                    }}
                                >
                                    <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
                                        File Upload
                                    </Typography>

                                    <Grid container spacing={2} p={1} sx={{ flexDirection: "column!important" }}>
                                        {FileUploadOptions.map((option: { label: string; value: string; icon: any }) => (
                                            <Grid item sm={6} sx={{ paddingTop: "8px!important" }}>
                                                <Box>
                                                    <HoC.SelectBox
                                                        option={option}
                                                        openDrawer={handleDrawer}
                                                        setSelectedOptions={handleFileUpload}
                                                        selectedOptions={selectedOptions["File Upload"][0].fileUploadType}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box px={4} py={2} height="100%">
                    <Grid container spacing={2} height="100%">
                        <Grid item xs={6} sx={{ gap: 2.5 }}>
                            <Box sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 2.5 }}>
                                <Box
                                    sx={{
                                        boxShadow: "0px 2px 8px 5px rgba(0,0,0,0.1)",

                                        borderRadius: "10px",

                                        flex: "1",

                                        p: 2,

                                        paddingTop: mediaQuery && "0px",

                                        paddingBottom: mediaQuery && "0px"
                                    }}
                                >
                                    <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
                                        Chat
                                    </Typography>

                                    <Grid container spacing={2} p={1}>
                                        {ChatOptions.map((option: { label: string; value: string; icon: any }) => (
                                            <Grid item sm={6} sx={{ paddingTop: "8px!important" }}>
                                                <Box>
                                                    <HoC.SelectBox
                                                        option={option}
                                                        openDrawer={false}
                                                        setSelectedOptions={handleChat}
                                                        selectedOptions={selectedOptions.Chat}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <HoC.CustomDrawer
                title={drawerTitle}
                type="COMPONENT_CREDENTIALS"
                handleDrawerOnClose={() => dispatch(handleDrawerClose({ open: false, type: "COMPONENT_CREDENTIALS" }))}
            >
                <Box
                    sx={{
                        maxWidth: "660px",

                        width: "100%",

                        display: "flex",

                        justifyContent: "center",

                        flexDirection: "column"
                    }}
                    p={1}
                    component="form"
                    onSubmit={formikProps.handleSubmit}
                >
                    {/* auth */}

                    {selectedComponent === createProjectConfig.projectData.components.component1.value1 && (
                        <GenerateForm
                            FormData={EmailCredentials?.filter((e: any) => {
                                if (formikProps?.values?.loginType === "emailAndPassword") {
                                    return e;
                                }
                                return e.Categery !== "EmailAndPassword";
                            })}
                            FormikProps={formikProps}
                        />
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component1.value2 && (
                        <GenerateForm FormData={facebookCredentials} FormikProps={formikProps} />
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component1.value3 && (
                        <GenerateForm FormData={GoogleCredentials} FormikProps={formikProps} />
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component1.value4 && (
                        <GenerateForm FormData={Auth0Credentials} FormikProps={formikProps} />
                    )}

                    {/* payment */}

                    {selectedComponent === createProjectConfig.projectData.components.component2.value1 && (
                        <GenerateForm FormData={RazorPayCredentials} FormikProps={formikProps} />
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component2.value2 && (
                        <GenerateForm FormData={PaypalCredentials} FormikProps={formikProps} />
                    )}
                    {selectedComponent === createProjectConfig.projectData.components.component2.value3 && (
                        <GenerateForm FormData={StripeCredentials} FormikProps={formikProps} />
                    )}
                    {/* media */}

                    {selectedComponent === createProjectConfig.projectData.components.component3.value1 && (
                        <GenerateForm FormData={YoutubeMediaCredentials} FormikProps={formikProps} />
                    )}
                    {selectedComponent === createProjectConfig.projectData.components.component3.value2 && (
                        <>
                            <GenerateForm FormData={VimeoCredentials} FormikProps={formikProps} />

                            <Grid>
                                <Box width="100%">
                                    <FormGroup>
                                        <FormControlLabel
                                            defaultChecked={menu.addToMenu}
                                            value={menu.addToMenu}
                                            control={<Checkbox onChange={(e) => handleInputChange("addToMenu", e.target.checked)} />}
                                            label="Add To Menu"
                                        />
                                    </FormGroup>

                                    {menu.addToMenu && (
                                        <>
                                            <Input
                                                type="text"
                                                name="childMenuText"
                                                value={menu.childMenuText || ""}
                                                onChange={(value) => handleInputChange("childMenuText", value)}
                                                placeHolder="Menu Name"

                                                // regex={KEYPAIRNAMEREGEX}
                                            />

                                            <Input
                                                type="text"
                                                name="path"
                                                // regex={KEYPAIRNAMEREGEX}

                                                value={menu.path || ""}
                                                onChange={(value) => handleInputChange("path", value)}
                                                placeHolder="Menu Path"
                                            />
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </>
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component3.value3 && (
                        <GenerateForm FormData={FacebookMediaCredentials} FormikProps={formikProps} />
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component4.value1 && (
                        <GenerateForm FormData={FtpCredentials} FormikProps={formikProps} />
                    )}

                    {selectedComponent === createProjectConfig.projectData.components.component4.value2 && (
                        <GenerateForm FormData={StorageCredentials} FormikProps={formikProps} />
                    )}

                    <Box display="flex" justifyContent="space-between" py={1} gap={2}>
                        <Button variant="contained" color="inherit" onClick={() => formikProps.resetForm()}>
                            {en.clearbtn}
                        </Button>

                        <Button style={buttonStyle} type="submit">
                            {en.savebtn}
                        </Button>
                    </Box>
                </Box>
            </HoC.CustomDrawer>
        </HoC.CardComponent>
    );
};

export default SelectComponent;
