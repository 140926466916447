import React from "react";

import logo from "../../assets/Logo.png";

const Home = () => {
    return (
        <div className="home">
            <img width="500" src={logo} alt="logo" />
        </div>
    );
};
export default Home;
