/* eslint-disable no-underscore-dangle */
import APiEndPoints from "../enums/ApiEndPoints";

import axios from "./Configuration/apiConfiguration";

const getProjects = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.Project, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const updateProjects = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .put(APiEndPoints.Project, { data })
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const createProjects = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .post(APiEndPoints.Project, { data })
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const createPages = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .post(APiEndPoints.Page, { data })
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const updatePages = (data: any) =>
    new Promise((resolve, reject) => {
        console.log("data: ", data);
        axios
            .put(`${APiEndPoints.Page}/${data?._id}`, { data })
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const deleteProjects = (id: any) =>
    new Promise((resolve, reject) => {
        axios
            .delete(`${APiEndPoints.Project}/${id}`, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const deletePages = (id: any) =>
    new Promise((resolve, reject) => {
        axios
            .delete(`${APiEndPoints.Page}/${id}`, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const getPages = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.Page, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const cloneFolder = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .post(APiEndPoints.cloneFolder, { data })
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const downloadFolder = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.downloadFolder, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const downloadForm = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.downloadForm, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const downloadFile = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.downloadFile, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const apidowload = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.apidowload, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const scriptdowload = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.scriptdowload, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const sqlscriptdowload = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.sqlscriptdowload, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const swaggerdowload = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.swaggerdowload, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const dictionarydowload = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.dictionarydowload, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const jestdowload = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.jestdowload, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
const sqlscriptdowloadlistpage = () =>
    new Promise((resolve, reject) => {
        axios
            .get(APiEndPoints.sqlscriptdowloadlistpage, {})
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });

export default {
    getProjects,
    updateProjects,
    createPages,
    updatePages,
    deletePages,
    getPages,
    deleteProjects,
    cloneFolder,
    downloadFolder,
    downloadForm,
    downloadFile,
    apidowload,
    scriptdowload,
    sqlscriptdowload,
    swaggerdowload,
    dictionarydowload,
    jestdowload,
    sqlscriptdowloadlistpage,
    createProjects
};
