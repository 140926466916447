import React from "react";

import { Button } from "@mui/material";
import { Box } from "@mui/system";

import { en } from "../../../dictionary/en";
import { useAppSelector } from "../../../Redux/Hooks";
import { DownloadText } from "../../../ReusableCodes/Resuseablefunctions";

const BicryptTextFunctions = () => {
    const { hostApiLayer } = useAppSelector((state) => state);

    const functionName = hostApiLayer?.functionAppName;
    const functionConsumption = hostApiLayer?.hostingOption === en.functionHostPlanAsConsumption;
    const appServicePlanName = hostApiLayer?.appServicePlanName;
    const isAppInsight = hostApiLayer?.applicationInsight?.value;
    const runTimeStack = hostApiLayer?.runTimeStack?.value;
    const version = hostApiLayer?.version?.value;
    const storageAccountName = hostApiLayer?.storageAccountName;
    const appInsightName = `appinsights-${functionName}`;
    let content: any = "";
    if (functionConsumption) {
        content = `establish a functionapp named ${functionName} with properties AzureWebJobsStorage,WEBSITE_CONTENTAZUREFILECONNECTIONSTRING, 
        WEBSITE_CONTENTSHARE,FUNCTIONS_EXTENSION_VERSION, WEBSITE_NODE_DEFAULT_VERSION as ${version},APPINSIGHTS_INSTRUMENTATIONKEY,  FUNCTIONS_WORKER_RUNTIME as ${runTimeStack}, 
        which should use the above appserviceplan and storageaccount.`;
    } else if (isAppInsight === true) {
        content = `
        1.Provide me a bicep script for creating a appservice plan named ${appServicePlanName}, add a storage account of size standard_lrs named ${storageAccountName}.
        2.establish a functionapp named ${functionName} with properties AzureWebJobsStorage,WEBSITE_CONTENTAZUREFILECONNECTIONSTRING, 
        WEBSITE_CONTENTSHARE,FUNCTIONS_EXTENSION_VERSION, WEBSITE_NODE_DEFAULT_VERSION as ${version},APPINSIGHTS_INSTRUMENTATIONKEY,  FUNCTIONS_WORKER_RUNTIME as ${runTimeStack}, 
        which should use the above appserviceplan and storageaccount.
        3.Add a appinsights named ${appInsightName}.
        `;
    } else {
        content = `
        1.Provide me a bicep script for creating a appservice plan named ${appServicePlanName}, add a storage account of size standard_lrs named ${storageAccountName}.
        2.establish a functionapp named ${functionName} with properties AzureWebJobsStorage,WEBSITE_CONTENTAZUREFILECONNECTIONSTRING, 
        WEBSITE_CONTENTSHARE,FUNCTIONS_EXTENSION_VERSION, WEBSITE_NODE_DEFAULT_VERSION as ${version},APPINSIGHTS_INSTRUMENTATIONKEY,  FUNCTIONS_WORKER_RUNTIME as ${runTimeStack}, 
        which should use the above appserviceplan and storageaccount.
        `;
    }
    const handleDownloadClick = () => {
        DownloadText(content, functionName);
    };

    return (
        <Box width="100vw" sx={{ textAlign: "-webkit-center" }}>
            <Box
                component="div"
                width="80vw"
                sx={{
                    position: "relative",
                    top: "210px",
                    padding: "40px",
                    boxShadow: "10px 8px 20px 5px rgba(0,0,0,0.1)",
                    textAlign: "left",
                    lineHeight: "25px"
                }}
            >
                <p>{content}</p>
                <br />
                <Button onClick={handleDownloadClick}>ChatGPT Integration</Button>
            </Box>
        </Box>
    );
};

export default BicryptTextFunctions;
