import React, { ReactNode } from "react";

import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";

interface DialogBoxProps {
    children: ReactNode;
    open: boolean;
    onClose: () => void;
    // eslint-disable-next-line react/require-default-props
    title?: string;
}

const DialogBox: React.FC<DialogBoxProps> = ({ children, open, onClose, title }) => {
    if (!open) return <Box />;
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {title && (
                <DialogTitle id="alert-dialog-title" color="secondary">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default DialogBox;
