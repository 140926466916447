import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import NextButton from "../../../Hoc/Button/NextButton";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostApiLayer, handleHostUi } from "../../../Redux/Reducers";
import { PrefixAdder, validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { AppService } from "./Utilities";

const PlanAppServicePage = () => {
    const { hostLayerType, hostUi, hostApiLayer } = useAppSelector((state) => state);
    const hostType: any = hostApiLayer?.hostType;
    const layer = hostLayerType?.layer;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const updatePricingPlanList = AppService?.map((field: any) => {
        if (field.Name === "pricingTier") {
            return {
                ...field,
                List: hostUi?.operatingSystem ? field.List.slice(0, 4) : field.List.slice(4, 10)
            };
        }
        return field;
    });
    const formikProps = useFormik({
        initialValues: {
            ...updatePricingPlanList.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values: any) => {
            if (layer === en.isUiLayer) {
                if (layer === en.isUiLayer && hostType === en.TypeAsFunction) {
                    dispatch(handleHostUi({ ...hostUi, ...values }));
                    navigate(RoutesEnum.functionsBiScript);
                } else {
                    dispatch(
                        handleHostUi({
                            webAppServices: {
                                ...hostUi.webAppServices,
                                appServicePlanName: values?.appServicePlanName,
                                appServiceRegion: values?.appServiceRegion?.value,
                                pricingTier: values?.pricingTier?.value
                            }
                        })
                    );
                    navigate(RoutesEnum.appServicesBiScript);
                }
            } else if (layer === en.isApiLayer && hostType === en.TypeAsFunction) {
                dispatch(handleHostApiLayer({ ...hostApiLayer, ...values }));
                navigate(RoutesEnum.functionsBiScript);
            } else {
                dispatch(handleHostApiLayer({ ...hostApiLayer, ...values }));
                navigate(RoutesEnum.appServicesBiScript);
            }
        },
        validate: (values) => validateFormOnSubmit(values, [AppService])
    });

    const AppServiceName = formikProps?.values?.appServicePlanName;
    formikProps.values.appServicePlanName = PrefixAdder(AppServiceName, en.appServicePlanPrefix);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box px={4} py={2}>
                <GenerateForm FormData={updatePricingPlanList} FormikProps={formikProps} />
            </Box>
            <Box sx={{ textAlign: "end" }}>
                <NextButton handleClick={formikProps.handleSubmit} />
            </Box>
        </Box>
    );
};
export default PlanAppServicePage;
