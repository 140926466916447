/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, Button, Grid, Paper, useMediaQuery } from "@mui/material";

import API from "../../../API";
import { en, messages } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import HoC from "../../../Hoc";
import CheckBox from "../../../Hoc/Checkbox";
import { Listcolumns, ListPage, typeConfig } from "../../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handlePageData, handlePages } from "../../../Redux/Reducers";
import { downloadJsonFile, generateRegex } from "../../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../../Theme/Theme";
import CloningSourceCode from "../../codeCloning/CodeCloning";
import ProjectList from "../DataPage/ProjectList";

import ListPageForm from "./ListPageForm";

interface ListFormProps {}

const ListForm: React.FC<ListFormProps> = () => {
    const { projectData, newPages, pageData } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const [state, setState] = useState<any[]>(newPages?.pageDetails ?? []);
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [defaultCheckbox, setDefaultCheckbox] = useState<boolean>(newPages?.defaultCheckbox || false);
    const [projectJson, setProjectJson] = useState<any>();
    const [selectedProject, setSelectedProject] = useState<any>();
    const [drawer, setDrawer] = useState(false);

    const [coloumnType, setColoumnType] = useState<any>(null);
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    const navigate = useNavigate();
    const updateColumnValueRegex = (coloumn: any) => {
        const columnValueIndex = ListPage.findIndex((item: any) => item.Name === en.clmValueName);
        if (columnValueIndex !== -1) {
            ListPage[columnValueIndex].Regex = generateRegex(coloumn);
        }
    };
    const handleDrawer = () => {
        if (drawer) {
            setDrawer(false);
        } else {
            setDrawer(true);
        }
        dispatch(handleDrawerOpen({ open: true, type: "ListPageForm", title: "List Form" }));
    };

    const handleCheck = async (defaultCheck: boolean) => {
        if (!newPages?.pageDetails?.length) {
            toast.warning(messages.kindlyProvideDetails);
        } else {
            dispatch(handleDrawerOpen({ open: true, type: en.appendProjectType }));
            setDefaultCheckbox(defaultCheck);
        }
    };
    const handleAppendProjectCancel = () => {
        if (!selectedProject) {
            setDefaultCheckbox(false);
        }
        dispatch(handleDrawerClose());
    };
    const handleApppendProjectOk = () => {
        if (selectedProject) {
            dispatch(handleDrawerClose());
            setDefaultCheckbox(true);
        } else {
            toast.error(messages?.PleaseChooseProject);
        }
    };
    console.log("handleCheck: ", handleCheck);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDeleteRows = async () => {
        try {
            const updatedState = newPages?.pageDetails?.filter((item: any) => !selectionModel.includes(item.id));
            await dispatch(handlePages({ pageDetails: [...newPages.pageDetails, updatedState[0]] }));
        } catch (error) {
            console.error(messages.anErrormessage, error);
        }
    };

    const formikProps = useFormik({
        initialValues: {
            ...ListPage.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values: any) => {
            setState([...state, values]);
            dispatch(handlePages({ defaultCheckbox, pageDetails: [...newPages.pageDetails, values] }));
        }
    });
    useEffect(() => {
        let coloumnTypeValue;
        if (formikProps?.values?.columnType?.value === typeConfig?.string || formikProps?.values?.columnType?.value === typeConfig?.date) {
            coloumnTypeValue = typeConfig?.string;
        } else if (formikProps?.values?.columnType?.value === typeConfig?.number) {
            coloumnTypeValue = typeConfig?.number;
        } else if (formikProps?.values?.columnType?.value === typeConfig?.boolean) {
            coloumnTypeValue = typeConfig?.boolean;
        } else {
            coloumnTypeValue = typeConfig?.string;
        }
        setColoumnType(coloumnTypeValue);
        updateColumnValueRegex(coloumnType);
    }, [coloumnType, formikProps?.values?.columnType]);

    // const handleCheckForAppendProject = async (Checkbox: boolean) => {
    //     if (!newPages?.pageDetails?.length) {
    //         toast.warning(messages.kindlyProvideDetails);
    //     } else {
    //         dispatch(handleDrawerOpen({ open: true, type: en.appendProjectType }));
    //         setAppendProjectCheckbox(Checkbox);
    //     }
    // };

    const handleProjectModal = async (project: any) => {
        setSelectedProject(project);
        const file = project;
        const Menus: any = file?.projectDetails?.Menus || [];
        if (file) {
            const newMenu = { childMenuText: newPages?.childMenuText, path: newPages?.pagePath };
            const projectDetails = {
                ...file?.projectDetails,
                Menus: [...Menus, newMenu]
            };
            const updatedProject = {
                ...file,
                projectDetails,
                pages: [...file?.pages, newPages],
                IsAddInProject: true
            };
            try {
                setProjectJson(updatedProject);
                await API.Projects.updateProjects(updatedProject);
            } catch (parseError) {
                console.error(messages.jsonparseerror, parseError);
            }
        }
    };
    const handleOpen = () => {
        dispatch(handleDrawerOpen({ open: true, type: "LIST_PAGE" }));
    };
    const handleDownloadJson = async () => {
        if (newPages?._id) {
            const res: any = await API.Projects.updatePages(newPages);
            const updatedPage = pageData?.filter((e: any) => e._id !== newPages?._id);
            if (res?.success) {
                downloadJsonFile({ ...newPages }, `${newPages?.pageName}.json`);
                dispatch(handlePageData([...updatedPage, newPages]));
                navigate(RoutesEnum.managePage);
            }
        } else {
            const response: any = await API.Projects.createPages(newPages);
            if (response?.success) {
                downloadJsonFile({ ...newPages }, `${newPages?.pageName}.json`);
                dispatch(handlePageData([...pageData, response?.data]));
                navigate(RoutesEnum?.managePage);
            }
        }
    };
    return (
        <>
            <form onSubmit={formikProps?.handleSubmit}>
                <Box component="form" onSubmit={formikProps.handleSubmit}>
                    <Box
                        component="div"
                        width="100%"
                        height="calc(100vh - 100px)"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginTop="100px"
                    >
                        <Paper elevation={3} sx={{ height: "90%", width: "90%", p: 2, pl: 2 }}>
                            <Box sx={{ height: mediaQuery ? "470px" : "100%", textAlign: "end" }}>
                                <Button onClick={handleOpen} sx={{ marginBottom: "8px" }}>
                                    Add Field
                                </Button>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{ height: "52vh" }}>
                                        <Box height="100%">
                                            <HoC.DataTable
                                                rows={
                                                    newPages.pageDetails && newPages.pageDetails.length > 0
                                                        ? newPages?.pageDetails?.map((row: any, index: any) => ({
                                                              ...row,
                                                              id: index + 1,
                                                              columnName: row?.columnName || row?.columnName,
                                                              columnType: row?.columnType?.label || row?.columnType,
                                                              columnOrder: row?.columnOrder?.label || row?.columnOrder,
                                                              defaultCheckbox: row?.defaultCheckbox
                                                          }))
                                                        : []
                                                }
                                                columns={Listcolumns}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: { page: 0, pageSize: 5 }
                                                    }
                                                }}
                                                pageSizeOptions={[5, 10]}
                                                checkboxSelection
                                                onSelectionModelChange={(newRowSelectionModel: any) => {
                                                    setSelectionModel(newRowSelectionModel);
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <CheckBox
                                    id="appendWithProject"
                                    name="appendWithProject"
                                    checked={defaultCheckbox}
                                    onChange={handleCheck}
                                    disabled={false}
                                    label="Select to Append with Project"
                                    toolTip="Select to Append with Project"
                                />
                                <Grid item xs={12} py={1} gap={2} display="flex" justifyContent="space-between">
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            navigate(RoutesEnum.pageType);
                                        }}
                                    >
                                        {en.backbtn}
                                    </Button>
                                    {defaultCheckbox && projectJson ? (
                                        <CloningSourceCode jsonData={projectJson} />
                                    ) : (
                                        <Button style={buttonStyle} disabled={!(newPages?.pageDetails?.length > 0)} onClick={handleDownloadJson}>
                                            {en.genjson}
                                        </Button>
                                    )}
                                </Grid>
                                <HoC.CustomDrawer
                                    type="LIST_PAGE"
                                    handleDrawerOnClose={() => dispatch(handleDrawerClose())}
                                    title={`${newPages?.initialState?.pageName || newPages?.pageName} Config`}
                                >
                                    <Grid item xs={12}>
                                        <Box>
                                            <ListPageForm handleClose={handleDrawer} />
                                        </Box>
                                    </Grid>
                                </HoC.CustomDrawer>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </form>

            {/* Append Project Drawer */}
            <HoC.CustomDrawer type="APPEND_PROJECT" handleDrawerOnClose={() => dispatch(handleDrawerClose())} title="Project List">
                <ProjectList
                    projectData={projectData}
                    selectedProject={selectedProject}
                    handleProjectModal={handleProjectModal}
                    handleCancel={handleAppendProjectCancel}
                    handleOk={handleApppendProjectOk}
                />
            </HoC.CustomDrawer>
        </>
    );
};

export default ListForm;
