export const WebAppPlan: any = [
    {
        Label: "Name",
        Required: true,
        Name: "webAppName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Name",
        ErrorMessage: "Name is required*",
        Tooltip: "Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "App_Page",
        Group: "Web_App_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false,
        Prefix: "wa-"
    },
    {
        Label: "Publish",
        Required: true,
        Name: "publish",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Publish",
        ErrorMessage: "Publish is required*",
        Tooltip: "Select Publish  ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Code", value: "code" }
            // { label: "Docker Container", value: "dockerContainer" },
            // { label: "Static Web App", value: "staticWebApp" },
        ],
        Categery: "App_Page",
        Group: "Web_App_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Operating System  ",
        Required: true,
        Name: "operatingSystem",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Operating System",
        ErrorMessage: "Operating System is required*",
        Tooltip: "Select Operating System  ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Linux", value: "linux" },
            { label: "Windows", value: "windows" }
        ],
        Categery: "App_Page",
        Group: "Web_App__Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Run Time Stack ",
        Required: true,
        Name: "runTimeStack",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Run Time Stack",
        ErrorMessage: "Run Time Stack is required*",
        Tooltip: "Select Run time Stack  ",
        HelperText: "",
        Type: "select",
        List: [
            // { label: "Node.js", value: "nodeJs" }
            // { label: "Node 16 LTS", value: "node16Lts" },
            { label: "DOTNETCORE:8.0", value: "dotnetCore8" },
            { label: "DOTNETCORE:7.0", value: "dotnetCore7" },
            { label: "DOTNETCORE:6.0", value: "dotnetCore6" },
            { label: "NODE:18-lts", value: "node18Lts" },
            { label: "NODE:16-lts", value: "node16Lts" },
            { label: "PYTHON:3.11", value: "python311" },
            { label: "PYTHON:3.10", value: "python310" },
            { label: "PYTHON:3.9", value: "python39" },
            { label: "PYTHON:3.8", value: "python38" },
            { label: "PHP:8.2", value: "php82" },
            { label: "PHP:8.1", value: "php81" },
            { label: "PHP:8.0", value: "php80" },
            { label: "JAVA:17-java17", value: "java17" },
            { label: "JAVA:11-java11", value: "java11" },
            { label: "JAVA:8-jre8", value: "java8" },
            { label: "JBOSSEAP:7-java17", value: "jbossEap7" },
            { label: "JBOSSEAP:7-java11", value: "jbossEap7" },
            { label: "JBOSSEAP:7-java8", value: "jbossEap7" },
            { label: "TOMCAT:10.0-java17", value: "tomcat10Java17" },
            { label: "TOMCAT:10.0-java11", value: "tomcat10Java11" },
            { label: "TOMCAT:10.0-jre8", value: "tomcat10Jre8" },
            { label: "TOMCAT:9.0-java17", value: "tomcat9Java17" },
            { label: "TOMCAT:9.0-java11", value: "tomcat9Java11" },
            { label: "TOMCAT:9.0-jre8", value: "tomcat9Jre8" },
            { label: "TOMCAT:8.5-java11", value: "tomcat8.5Java11" },
            { label: "TOMCAT:8.5-jre8", value: "tomcat8.5Jre8" },
            { label: "GO:1.19", value: "go1.19" },
            { label: "DOTNET:8", value: "dotnet8" },
            { label: "DOTNET:7", value: "dotnet7" },
            { label: "DOTNET:6", value: "dotnet6" },
            { label: "ASPNET:V4.8", value: "aspnetV4.8" },
            { label: "ASPNET:V3.5", value: "aspnetV3.5" },
            { label: "NODE:18LTS", value: "node18Lts" },
            { label: "NODE:16LTS", value: "node16Lts" },
            { label: "java:1.8:Java SE:8", value: "java1.8" },
            { label: "java:11:Java SE:11", value: "java11" },
            { label: "java:17:Java SE:17", value: "java17" },
            { label: "java:1.8:TOMCAT:10.0", value: "java1.8Tomcat10" },
            { label: "java:11:TOMCAT:10.0", value: "java11Tomcat10" },
            { label: "java:17:TOMCAT:10.0", value: "java17Tomcat10" },
            { label: "java:1.8:TOMCAT:9.0", value: "java1.8Tomcat9" },
            { label: "java:11:TOMCAT:9.0", value: "java11Tomcat9" },
            { label: "java:17:TOMCAT:8.5", value: "java17Tomcat8.5" }
        ],
        Categery: "App_Page",
        Group: "Web_App_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Region",
        Required: true,
        Name: "webAppRegion",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Region",
        ErrorMessage: "Region is required*",
        Tooltip: "Region Selection",
        HelperText: "",
        Type: "select",
        List: [
            { label: "East US", value: "East US" },
            { label: "North Europe", value: "North Europe" },
            { label: "West Europe", value: "West Europe" },
            { label: "Southeast Asia", value: "Southeast Asia" },
            { label: "East Asia", value: "East Asia" },
            { label: "West US", value: "West US" },
            { label: "Japan West", value: "Japan West" },
            { label: "Japan East", value: "Japan East" },
            { label: "East US 2", value: "East US 2" },
            { label: "North Central US", value: "North Central US" },
            { label: "Brazil South", value: "Brazil South" },
            { label: "Australia East", value: "Australia East" },
            { label: "Australia Southeast", value: "Australia Southeast" },
            { label: "Central US", value: "Central US" },
            { label: "East Asia (Stage)", value: "East Asia (Stage)" },
            { label: "North Central US (Stage)", value: "North Central US (Stage)" },
            { label: "Central India", value: "Central India" },
            { label: "West India", value: "West India" },
            { label: "South India", value: "South India" },
            { label: "Canada Central", value: "Canada Central" },
            { label: "Canada East", value: "Canada East" },
            { label: "West Central US", value: "West Central US" },
            { label: "West US 2", value: "West US 2" },
            { label: "UK West", value: "UK West" },
            { label: "UK South", value: "UK South" },
            { label: "East US 2 EUAP", value: "East US 2 EUAP" },
            { label: "Central US EUAP", value: "Central US EUAP" },
            { label: "Korea South", value: "Korea South" },
            { label: "Korea Central", value: "Korea Central" },
            { label: "France South", value: "France South" },
            { label: "France  Central", value: "France  Central" },
            { label: "Australia Central 2", value: "Australia Central 2" },
            { label: "Australia Central ", value: "Australia Central " },
            { label: "South Africa North", value: "South Africa North" },
            { label: "South Africa West", value: "South Africa West" },
            { label: "Switzerland North", value: "Switzerland North" },
            { label: "Germany West Central", value: "Germany West Central" },
            { label: "Germany  North", value: "Germany North" },
            { label: "Switzerland West", value: "Switzerland West" },
            { label: "UAE Central", value: "UAE Central" },
            { label: "UAE   North", value: "UAE  North" },
            { label: "Norway West", value: "Norway West" },
            { label: "Norway East", value: "Norway East" },
            { label: "Brazil Southeast", value: "Brazil Southeast" },
            { label: "West US 3", value: "West US 3" },
            { label: "Jio India West", value: "Jio India West" },
            { label: "Jio India Central", value: "Jio India Central" },
            { label: "Sweden Central", value: "Sweden Central" },
            { label: "Qatar Central", value: " Qatar Central" },
            { label: "Sweden South", value: " Sweden South" },
            { label: "Poland Central", value: " Poland Central" },
            { label: "Italy North", value: "Italy North" },
            { label: "Israel Central", value: "Israel Central" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Git Url",
        Required: false,
        Name: "gitUrl",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Git Url",
        ErrorMessage: "Git Url is required*",
        Tooltip: "Git Url",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "App_Page",
        Group: "Web_App_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Git Branch Name",
        Required: false,
        Name: "gitBranchName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Branch Name",
        ErrorMessage: "Branch Name is required*",
        Tooltip: "Branch Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "App_Page",
        Group: "Web_App_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
