import Navigators from "./ButtonGroups/Navigators";
import Checkbox from "./Checkbox/index";
// eslint-disable-next-line import/no-named-as-default
import ReportCsv from "./Csv/index";
import CardComponent from "./Card";
import CustomDrawer from "./CustomDrawer";
import DataTable from "./DataGrid";
// eslint-disable-next-line import/no-cycle
import GenerateForm from "./GenerateForms";
import IconButtonComp from "./IconButton";
// import SelectMultiple from "./SelectMultiple/index";
import Input from "./Input";
import ModalComponent from "./Modal";
import ModalButtons from "./ModelButtons";
import SelectBox from "./SelectBox";
import SwitchComponent from "./Switch";
import TableComponent from "./Table";
import DataGridTable from "./TableComponent";

const HoC = {
    Input,
    // SelectMultiple,
    Checkbox,
    ModalComponent,
    ModalButtons,
    TableComponent,
    CardComponent,
    DataTable,
    GenerateForm,
    Navigators,
    IconButtonComp,
    CustomDrawer,
    SelectBox,
    DataGridTable,
    ReportCsv,
    SwitchComponent
};
export default HoC;
