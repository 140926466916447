import React from "react";
import { CSVLink } from "react-csv";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Tooltip } from "@mui/material";

import { useAppSelector } from "../../Redux/Hooks";
// import { buttonStyle } from "../../Theme/Theme";

interface props {
    columns?: any;
    tableData?: any[];
    paramData?: string;
    downLoadInvoice?: boolean;
}
const ReportCsv: React.FC<props> = () => {
    const { newPages, pageData } = useAppSelector((state: any) => state);
    console.log("", pageData);
    const createCsvFileName = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace(/:/g, "-").slice(0, -5);
        return `ReportForm_${formattedDate}.csv`;
    };
    const headers = [
        { label: "Column Name", key: "columnName" },
        { label: "Column Value", key: "columnValue" }
    ];
    const data: any[] = [];

    if (newPages.pageDetails.length > 0) {
        newPages.pageDetails.forEach((e: any) => {
            data.push(e);
        });
    }

    return (
        <CSVLink
            data={data.map((item) => ({
                ...item,
                columnName: `=""${item.columnName}""`
            }))}
            headers={headers}
            filename={createCsvFileName()}
            target="_blank"
            style={{ textDecoration: "none", outline: "none", height: "5vh" }}
        >
            <Tooltip title="Download as CSV">
                <FileDownloadIcon />
            </Tooltip>
        </CSVLink>
    );
};
export default ReportCsv;
