import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import StorageIcon from "@mui/icons-material/Storage";
import { Box, Grid, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import SaveButton from "../../../Hoc/Button/SaveButton";
import Select from "../../../Hoc/Select/index";
import { diskSizeOptions } from "../../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../../Redux/Reducers";

import { DiskStoragePage } from "./Utilities";

import "react-toastify/dist/ReactToastify.css";

const DiskStorage = ({ setIsAllSaved, isAllSaved }: any) => {
    const dispatch = useAppDispatch();
    const { hostUi, hostLayerType, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;
    const [selectedOptions, setSelectedOptions] = useState<any>({ disk: "", diskType: "", diskSize: "" });
    const [afterSave, setAfterSave] = useState(false);

    useEffect(() => {
        if (layer === en.isUiLayer) {
            setSelectedOptions({
                disk: hostUi?.virtualMachine?.diskStorageDetails?.disk || "",
                diskType: hostUi?.virtualMachine?.diskStorageDetails?.diskType || "",
                diskSize: hostUi?.virtualMachine?.diskStorageDetails?.diskSize || ""
            });
        } else if (layer === en.isApiLayer) {
            setSelectedOptions({
                disk: hostApiLayer?.virtualMachine?.diskStorageDetails?.disk || "",
                diskType: hostApiLayer?.virtualMachine?.diskStorageDetails?.diskType || "",
                diskSize: hostApiLayer?.virtualMachine?.diskStorageDetails?.diskSize || ""
            });
        } else if (layer === en.isDbLayer) {
            setSelectedOptions({
                disk: hostDbLayer?.virtualMachine?.diskStorageDetails?.disk || "",
                diskType: hostDbLayer?.virtualMachine?.diskStorageDetails?.diskType || "",
                diskSize: hostDbLayer?.virtualMachine?.diskStorageDetails?.diskSize || ""
            });
        }
    }, [hostUi]);

    const formikProps = useFormik({
        initialValues: {
            ...DiskStoragePage.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async () => {
            if (layer === en.isUiLayer)
                dispatch(
                    handleHostUi({
                        virtualMachine: {
                            ...hostUi.virtualMachine,
                            diskStorageDetails: {
                                disk: selectedOptions?.disk,
                                diskType: selectedOptions?.diskType,
                                diskSize: selectedOptions.diskSize
                            }
                        }
                    })
                );
            else if (layer === en.isApiLayer)
                dispatch(
                    handleHostApiLayer({
                        virtualMachine: {
                            ...hostApiLayer?.virtualMachine,
                            diskStorageDetails: {
                                disk: selectedOptions?.disk,
                                diskType: selectedOptions?.diskType,
                                diskSize: selectedOptions.diskSize
                            }
                        }
                    })
                );
            else if (layer === en.isDbLayer)
                dispatch(
                    handleHostDbLayer({
                        virtualMachine: {
                            ...hostDbLayer?.virtualMachine,
                            diskStorageDetails: {
                                disk: selectedOptions?.disk,
                                diskType: selectedOptions?.diskType,
                                diskSize: selectedOptions.diskSize
                            }
                        }
                    })
                );
            setAfterSave(true);
        }
    });

    useEffect(() => {
        isAllSaved.DiskStorage = afterSave;
        setIsAllSaved({ ...isAllSaved });
    }, [afterSave]);

    const isZonal = selectedOptions?.disk === "zoneRedundant";
    const isLocal = selectedOptions?.disk === "locallyRedundant";

    const options = [
        {
            label: "Locally-redundant storage",
            value: "locallyRedundant",
            description: "Data is replicated within a single datacenter",
            icon: <StorageIcon />
        },
        {
            label: "ZONE-redundant storage",
            value: "zoneRedundant",
            description: "Data is replicated to three zones",
            icon: <StorageIcon />
        }
    ];
    const zonalList = [
        { label: "Premium SSD", value: "ssdPremiumZonal" },
        { label: "Standard SSD", value: "ssdStandardZonal" },
        { label: "Standard HDD", value: "hddStandardZonal" }
    ];

    const localList = [
        { label: "Premium SSD", value: "ssdPremiumZonal" },
        { label: "Standard SSD", value: "ssdStandardZonal" }
    ];

    const handlediskTypeChange = (value: any) => {
        setSelectedOptions((prev: any) => ({ ...prev, diskType: value }));
        setAfterSave(false);
    };
    const handleDiskSizeChange = (value: any) => {
        setSelectedOptions((prev: any) => ({ ...prev, diskSize: value }));
        setAfterSave(false);
    };

    return (
        <Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h6" marginTop="5px">
                    Disk Storage Details
                </Typography>
                <Box px={0.1} py={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography>Select Disk Size</Typography>
                            </Box>
                            <Box>
                                <div>
                                    <Select
                                        id="diskSize"
                                        value={selectedOptions?.diskSize}
                                        name="diskSize"
                                        label="Disk Size"
                                        onChange={handleDiskSizeChange}
                                        error={false}
                                        list={diskSizeOptions}
                                    />
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} py={1}>
                            <Typography>Select Disk Type</Typography>
                        </Grid>
                        {options.map((option: { label: string; icon: any; value: string; description: string }) => (
                            <Grid item xs={12} sm={6} key={option.value}>
                                <Box
                                    px={2}
                                    className="transition"
                                    sx={{
                                        minHeight: "100px",
                                        backgroundColor: option.value === selectedOptions?.disk ? "#edededdd" : "#ffff",
                                        border: "1px solid #edededdd",
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center"
                                    }}
                                    onClick={() => {
                                        setSelectedOptions((prev: any) => ({ ...prev, disk: option.value }));
                                    }}
                                >
                                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                        {option.icon}
                                        <Box>
                                            <Typography>{option.label}</Typography>
                                            <Typography variant="subtitle2"> {option.description}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            {isZonal && (
                                <div>
                                    <Select
                                        id="zonalRedundant"
                                        value={selectedOptions?.diskType}
                                        name="ZonalRedundant"
                                        label="Zonal Redundant"
                                        onChange={handlediskTypeChange}
                                        error={false}
                                        list={zonalList}
                                    />
                                </div>
                            )}
                            {isLocal && (
                                <div>
                                    <Select
                                        id="localRedundant"
                                        value={selectedOptions?.diskType}
                                        name="localRedundant"
                                        label="Local Redundant"
                                        onChange={handlediskTypeChange}
                                        error={false}
                                        list={localList}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ display: afterSave ? "none" : "inline" }}>
                <SaveButton handleSubmit={formikProps.handleSubmit} />
            </Box>
        </Box>
    );
};

export default DiskStorage;
