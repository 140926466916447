import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Container, Paper } from "@mui/material";

import RoutesEnum from "../../../enums/Routes.enum";
import Navigators from "../../../Hoc/ButtonGroups/Navigators";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostServiceLayer } from "../../../Redux/Reducers";
import { validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { StorageSelection } from "./Utilities";

const StorageAccountPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hostServiceLayer } = useAppSelector((state) => state);

    const formikProps = useFormik({
        initialValues: {
            ...StorageSelection.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values) => {
            dispatch(handleHostServiceLayer({ ...hostServiceLayer, ...values }));
            navigate(RoutesEnum.servicesBiscript);
        },
        validate: (values) => validateFormOnSubmit(values, [StorageSelection])
    });
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh"
            }}
        >
            <Container maxWidth="md">
                <Paper
                    style={{
                        padding: "16px"
                    }}
                    elevation={3}
                >
                    <h2>Storage Account </h2>
                    <GenerateForm FormData={StorageSelection} FormikProps={formikProps} />
                    <Navigators handleNext={formikProps.submitForm} />
                </Paper>
            </Container>
        </div>
    );
};

export default StorageAccountPage;
