import React, { useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";

import { imageUploadWithPreviewType } from "./types";

import "./index.css";

const ImageUploadPreview = ({ handleImage }: imageUploadWithPreviewType) => {
    const imageRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<any>();
    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedImage = e.target.files?.[0];
        if (selectedImage) {
            handleImage(selectedImage);
            setImage(URL.createObjectURL(selectedImage));
        }
    };
    const handleClick = () => {
        imageRef.current?.click();
    };
    return (
        <Box>
            <Box className="wrapper" sx={{ height: "9vw" }}>
                <Box className="box" sx={{ marginLeft: "0px" }}>
                    <Box className={image ? "js--image-preview-selected" : "js--image-preview"}>
                        {image ? (
                            <img src={image} style={{ width: "100%", height: "100%", objectFit: "contain", objectPosition: "center" }} alt="logo" />
                        ) : null}
                    </Box>

                    <Box className="upload-options">
                        <IconButton size="large" onClick={handleClick}>
                            <AddIcon sx={{ color: "white" }} />
                        </IconButton>
                        <input type="file" className="image-upload" accept="image/*" ref={imageRef} onChange={handleImageChange} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ImageUploadPreview;
