import React from "react";

import { Box, Button } from "@mui/material";

import { en } from "../../../dictionary/en";
import { useAppSelector } from "../../../Redux/Hooks";
import { DownloadText, VmScript } from "../../../ReusableCodes/Resuseablefunctions";

const BicryptText = () => {
    const { hostLayerType, hostUi, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const uitypeOfHost = hostUi?.virtualMachine?.hostType;
    const uiVmName = hostUi?.virtualMachine?.VmName;
    const uiVmSize = hostUi?.virtualMachine?.size;
    const uiVmImage = hostUi?.virtualMachine?.image;
    const uiOsDiskSize = hostUi?.virtualMachine?.diskStorageDetails?.diskSize?.value;
    const uiVnetName = hostUi?.virtualMachine?.virtualNetwork;
    const uiVnetNameIp = `${uiVnetName}-IP`;
    const uiAuthType = hostUi?.virtualMachine?.authType?.AuthorizationType;
    const uiImage = uiVmImage?.includes(en.windows) ? en.windows : en.ubuntu;
    const uiSSH_UserName = hostUi?.virtualMachine?.authType?.userNameSSH;
    const uiSSH_KeypairName = hostUi?.virtualMachine?.authType?.keyPairName;
    const ui_UserName = hostUi?.virtualMachine?.authType?.userName;
    const ui_Password = hostUi?.virtualMachine?.authType?.password;
    const ui_Port = hostUi?.virtualMachine?.publicInboundPorts?.map((e: any) => {
        return e.value;
    });

    const apitypeOfHost = hostApiLayer?.virtualMachine?.hostType;
    const apiVmName = hostApiLayer?.virtualMachine?.VmName;
    const apiVmSize = hostApiLayer?.virtualMachine?.size;
    const apiVmImage = hostApiLayer?.virtualMachine?.image;
    const apiOsDiskSize = hostApiLayer?.virtualMachine?.diskStorageDetails?.diskSize?.value;
    const apiVnetName = hostApiLayer?.virtualMachine?.virtualNetwork;
    const apiVnetNameIp = `${apiVnetName}-IP`;
    const apiAuthType = hostApiLayer?.virtualMachine?.authType?.AuthorizationType;
    const apiImage = apiVmImage?.includes(en.windows) ? en.windows : en.ubuntu;
    const apiSSH_UserName = hostApiLayer?.virtualMachine?.authType?.userNameSSH;
    const apiSSH_KeypairName = hostApiLayer?.virtualMachine?.authType?.keyPairName;
    const api_UserName = hostApiLayer?.virtualMachine?.authType?.userName;
    const api_Password = hostApiLayer?.virtualMachine?.authType?.password;
    const api_Port = hostApiLayer?.virtualMachine?.publicInboundPorts?.map((e: any) => {
        return e.value;
    });

    const dbtypeOfHost = hostDbLayer?.virtualMachine?.hostType;
    const dbVmName = hostDbLayer?.virtualMachine?.VmName;
    const dbVmSize = hostDbLayer?.virtualMachine?.size;
    const dbVmImage = hostDbLayer?.virtualMachine?.image;
    const dbOsDiskSize = hostDbLayer?.virtualMachine?.diskStorageDetails?.diskSize?.value;
    const dbVnetName = hostDbLayer?.virtualMachine?.virtualNetwork;
    const dbVnetNameIp = `${dbVnetName}-IP`;
    const dbAuthType = hostDbLayer?.virtualMachine?.authType?.AuthorizationType;
    const dbImage = dbVmImage?.includes(en.windows) ? en.windows : en.ubuntu;
    const dbSSH_UserName = hostDbLayer?.virtualMachine?.authType?.userNameSSH;
    const dbSSH_KeypairName = hostDbLayer?.virtualMachine?.authType?.keyPairName;
    const db_UserName = hostDbLayer?.virtualMachine?.authType?.userName;
    const db_Password = hostDbLayer?.virtualMachine?.authType?.password;
    const isVmDatabase = dbtypeOfHost === en.dbVm;
    const db_Port = hostDbLayer?.virtualMachine?.publicInboundPorts?.map((e: any) => {
        return e.value;
    });

    // sql db
    const serverName = hostDbLayer?.serverName;
    const sqlDatabaseName = hostDbLayer?.sqlDatabaseName;
    const administratorLogin = hostDbLayer?.administratorLogin;
    const administratorLoginPassword = hostDbLayer?.administratorLoginPassword;
    const sqlDbRegion = hostDbLayer?.sqlDbRegion?.value;
    const serviceTier = hostDbLayer?.serviceTier?.value;

    const layer = hostLayerType?.layer;

    let content = "";

    if (layer === en.isUiLayer) {
        content = VmScript(
            uiAuthType,
            uiVnetName,
            uiVnetNameIp,
            ui_Port,
            uiImage,
            uitypeOfHost,
            uiVmSize,
            uiVmImage,
            uiOsDiskSize,
            uiVmName,
            ui_UserName,
            ui_Password,
            uiSSH_UserName,
            uiSSH_KeypairName
        );
    } else if (layer === en.isApiLayer) {
        content = VmScript(
            apiAuthType,
            apiVnetName,
            apiVnetNameIp,
            api_Port,
            apiImage,
            apitypeOfHost,
            apiVmSize,
            apiVmImage,
            apiOsDiskSize,
            apiVmName,
            api_UserName,
            api_Password,
            apiSSH_UserName,
            apiSSH_KeypairName
        );
    } else if (layer === en.isDbLayer) {
        if (isVmDatabase) {
            content = VmScript(
                dbAuthType,
                dbVnetName,
                dbVnetNameIp,
                db_Port,
                dbImage,
                dbtypeOfHost,
                dbVmSize,
                dbVmImage,
                dbOsDiskSize,
                dbVmName,
                db_UserName,
                db_Password,
                dbSSH_UserName,
                dbSSH_KeypairName
            );
        } else {
            content = `Provide me a bicep script for creating a sqlserver named ${serverName} and database named ${sqlDatabaseName} with administrator login as ${administratorLogin}, password as ${administratorLoginPassword} in the region ${sqlDbRegion} with the ${serviceTier} sku`;
        }
    }

    const handleDownloadClick = () => {
        DownloadText(content, layer);
    };
    return (
        <Box width="100vw" sx={{ textAlign: "-webkit-center" }}>
            <Box
                component="div"
                width="80vw"
                sx={{
                    position: "relative",
                    top: "210px",
                    padding: "40px",
                    boxShadow: "10px 8px 20px 5px rgba(0,0,0,0.1)",
                    textAlign: "left",
                    lineHeight: "25px"
                }}
            >
                <p>{content}</p>
                <br />
                <Button onClick={handleDownloadClick}>ChatGPT Integration</Button>
            </Box>
        </Box>
    );
};

export default BicryptText;
