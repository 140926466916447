export const en: any = {
    addfield: "Add Field",
    fieldname: "fieldName",
    fieldheader: "Field Name",
    fieldtype: "fieldType",
    typeheader: "Field Type",
    typeselect: "singleSelect",
    fieldorder: "fieldOrder",
    orderheader: "Field Order",
    typenumber: "number",
    typecheckbox: "boolean",
    fieldmandatory: "mandatory",
    madatoryheader: "Mandatory",
    fieldaction: "actions",
    backbtn: "Back",
    downloadupjson: "Download Updated JSON",
    clmname: "columnName",
    clmheader: "Column Name",
    clmtype: "columnType",
    clmtypeheader: "Column Type",
    clmValueName: "columnValue",
    clmorder: "columnOrder",
    clmorderheader: "Column Order",
    clmproperty: "columnProperties",
    clmpropertyhead: "ColumnProperties",
    clmdefaultCheckboxheader: "Default Check box",
    clmdefaultCheckbox: "defaultCheckbox",
    reportclm: "columnName",
    reportclmheader: "Column Name",
    reportvalue: "columnValue",
    reporthead: "Column Value",
    reporttype: "columnType",
    reportheadtype: "Column Type",
    reportorder: "columnOrder",
    reportheadorder: "Column Order",
    dataform: "Data Form",
    deletebtn: "Delete",
    cancelbtn: "Cancel",
    clearbtn: "Clear",
    savebtn: "Save",
    listform: "List Page",
    reportform: "Report Page",
    crudform: "CRUD Page",
    pagetype: "Page Type ",
    nxtbtn: "Next",
    genjson: "Generate JSON",
    uploadjson: "Upload JSON",
    creatpagebtn: "Create Page",
    lowdataform: "dataform",
    lowlistform: "listform",
    lowreportform: "reportform",
    lowcrudform: "crudform",
    createprojectbtn: "Create Project",
    gencodebtn: "Generate Code ",
    genpagebtn: "Generate Page",
    uploadpagebtn: "Upload Page JSON:",
    genprojectbtn: "Generate Project",
    uploadprojectbtn: "Upload Project JSON:",
    manageprojectbtn: "Manage Project",
    managepagebtn: "Manage Page",
    filesize: "size is too large. Maximum allowed size is 1 MB.",
    errorbtn: "Error",
    okbtn: "Ok",
    logo: "Logo",
    uploadbtn: "Upload",
    filediscrip: "upload .jpg or .png  image of maximum size 1 MB",
    favicon: "FavIcon",
    themecolor: "Theme Color",
    appfront: "App Font",
    downloadjson: "Download JSON",
    welcomtext: "Welcome to our website!",
    submitbtn: "Submit",
    editproject: "edit Project",
    generate: "Generate",
    createHost: "Create Host",
    uiLayer: "UI Layer",
    apiLayer: "API Layer",
    dbLayer: "dbLayer",
    hostType1Tooltip: "Click to choose IaaS as Host",
    hostType2Tooltip: "Click to choose PaaS as Host",
    hostIaaS: "iaas",
    hostPaaS: "paas",
    appService: "appServicePlan",
    funcPremium: "functionsPremium",
    dbVm: "vm",
    dbSql: "azureSqlDb",
    isUiLayer: "UI Layer",
    isApiLayer: "API Layer",
    isDbLayer: "Database Layer",
    isServiceLayer: "serviceLayer",
    appServicePlan: "App Service Plan",
    editPageType: "EDIT_PAGE",
    editProjectType: "EDIT_PROJECT",
    isApiType: "IS_API",
    appendProjectType: "APPEND_PROJECT",
    EditProjectTitle: "Edit Project",
    EditPageTitle: "Edit Page",
    ProjectListTitle: "Project List",
    vmPrefix: "vm-",
    vnetPrefix: "vnet-",
    functionPrefix: "fa-",
    webAppPrefix: "wa-",
    appServicePlanPrefix: "asp-",
    dbSqlPrefix: "sql-",
    ServiceStorageAccount: "storageAccount",
    AzureCache: "azureCacheForRedis",
    TypeAsFunction: "functions",
    functionHostPlanAsAppService: "appServicePlan",
    functionHostPlanAsConsumption: "consumption",
    password: "password",
    windows: "Windows",
    ubuntu: "Ubuntu",
    storageAccountService: "storageAccount"
};

export const messages: any = {
    anErrormessage: "An error occurred:",
    jsonparseerror: "Error parsing JSON:",
    fetchingerror: "Error fetching data:",
    deleteerror: "Error deleting data:",
    projectnameerror: "Project name is required",
    kindlyProvideDetails: "Kindly provide the necessary page details to proceed.",
    PleaseChooseProject: "Please choose a project to add pages",
    IncorrectUser: "Username or Password Incorrect"
};
