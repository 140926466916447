import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import reduxState from "./Reducers";

export const initalState = configureStore({
    reducer: reduxState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export type AppDispatch = typeof initalState.dispatch;
export type RootState = ReturnType<typeof initalState.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
