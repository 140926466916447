import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";

import { en } from "../../../dictionary/en";
import HoC from "../../../Hoc";
import NextButton from "../../../Hoc/Button/NextButton";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handleHostApiLayer, handleHostUi } from "../../../Redux/Reducers";
import { PrefixAdder } from "../../../ReusableCodes/Resuseablefunctions";

import FunctionAppHost from "./FunctionOtherInfo";
import FunctionVmPlan from "./FunctionVNet";
import { FunctionAppPlan } from "./Utilities";

const FunctionAppPlanPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hostLayerType, hostUi, hostApiLayer } = useAppSelector((state) => state);
    const [nextButton, setNextButton] = useState(false);
    const layer = hostLayerType?.layer;
    const formikProps = useFormik({
        initialValues: {
            ...FunctionAppPlan.reduce((acc: any, field: any) => {
                acc[field.Name] = field.InitialValue;
                return acc;
            }, {})
        },
        onSubmit: async (values) => {
            const combinedValues = {
                ...values
            };

            if (layer === en.isUiLayer) dispatch(handleHostUi(combinedValues));
            else if (layer === en.isApiLayer) dispatch(handleHostApiLayer(combinedValues));
            dispatch(handleDrawerOpen({ open: true, type: "FUNCTIONS_SERVICES" }));
        }
    });
    // Use useEffect to pre-fill the form with host values
    useEffect(() => {
        if (layer === en.isUiLayer) {
            formikProps.setValues({
                ...formikProps.values,
                ...FunctionAppPlan.reduce((acc: any, field: any) => {
                    if (hostUi[field.Name] !== undefined) {
                        acc[field.Name] = hostUi[field.Name];
                    }
                    return acc;
                }, {})
            });
        } else if (layer === en.isApiyer) {
            formikProps.setValues({
                ...formikProps.values,
                ...FunctionAppPlan.reduce((acc: any, field: any) => {
                    if (hostApiLayer[field.Name] !== undefined) {
                        acc[field.Name] = hostApiLayer[field.Name];
                    }
                    return acc;
                }, {})
            });
        }
    }, [hostUi, hostApiLayer, layer, formikProps]);
    const handleBack = () => {
        navigate(-1);
    };
    const handleNext = () => {
        setNextButton(true);
    };
    const functionAppName = formikProps?.values?.functionAppName;
    formikProps.values.functionAppName = PrefixAdder(functionAppName, en.functionPrefix);
    return (
        <HoC.CardComponent cardTitle="Functions App Services" handleBack={handleBack} handleNext={formikProps.handleSubmit}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box px={4} py={2}>
                    <GenerateForm FormData={FunctionAppPlan} FormikProps={formikProps} />
                </Box>
            </Box>
            <HoC.CustomDrawer
                title="Web App"
                type="FUNCTIONS_SERVICES"
                handleDrawerOnClose={() => {
                    dispatch(handleDrawerClose());
                }}
            >
                <FunctionAppHost nextButton={nextButton} />
                <hr className="line" />
                <FunctionVmPlan nextButton={nextButton} />
                <Box sx={{ textAlign: "end" }}>
                    <NextButton handleClick={handleNext} />
                </Box>
            </HoC.CustomDrawer>
        </HoC.CardComponent>
    );
};

export default FunctionAppPlanPage;
