import { useState } from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { DataTableProps } from "./types";

const DataTable: React.FC<DataTableProps> = ({
    rows,
    columns,
    checkboxSelection = false,
    handleChange,
    rowsPerPageOptions = [5, 10],
    initialState = {},
    onSelectionModelChange,
    loading
}) => {
    const [page, setPage] = useState(5);

    return (
        <Box style={{ height: "100%", width: "100%" }} display="flex">
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold"
                    }
                }}
                initialState={initialState}
                rows={rows}
                columns={columns}
                loading={loading}
                pageSize={page}
                onPageSizeChange={(newPageSize: any) => setPage(newPageSize)}
                onSelectionModelChange={onSelectionModelChange}
                getRowId={(row: any) => row.id}
                pagination
                rowsPerPageOptions={rowsPerPageOptions}
                onStateChange={(e: any) => handleChange?.(e)}
                checkboxSelection={checkboxSelection}
                // setRowSelectionMode={(rowIds: GridRowId[]) => onRowSelectionModelChange(rowIds)}
            />
        </Box>
    );
};

export default DataTable;
