import { InitialState, newProjectState } from "./types";

export const newPagesInitialState = {
    pageType: "",
    pageName: "",
    pagePath: "",
    pageDetails: []
};
export const newProjectInitialState: newProjectState = {
    projectDetails: {
        projectName: "",
        tagName: "",
        appFont: "",
        base64Logo: "",
        faviIconBase64Image: "",
        themeColor: "",
        buttons: {
            primary: { backgroundColor: "", fontColor: "", variant: "contained" },
            secondary: { backgroundColor: "", fontColor: "", variant: "outlined" }
        },
        toastConfig: { position: "top-right", theme: "light", type: "success" }
    },
    technology: {
        api: "",
        database: { databaseName: "mysql", hostName: "", userName: "", password: "" },
        frontend: ""
    },
    component: {
        Authentication: [{ authenticationType: "emailLogin" }],
        Media: [{ mediaType: "" }],
        Payments: [{ paymentType: "" }],
        "File Upload": [{ fileUploadType: "" }],
        Chat: false
    }
};
export const initialState: InitialState = {
    projectData: [],
    loaderState: {
        loading: false
    },
    userData: {},
    newProject: newProjectInitialState,
    hostLayerType: {},
    hostUi: {
        virtualMachine: {
            VmName: "",
            region: "",
            image: "",
            size: "",
            authType: {
                AuthorizationType: "",
                userNameSSH: "",
                keyPairName: "",
                userName: "",
                password: "",
                confirmPassword: ""
            },
            publicInboundPorts: [],
            diskStorageDetails: {
                disk: "",
                diskType: {
                    label: "",
                    value: ""
                },
                diskSize: {
                    label: "",
                    value: ""
                }
            },
            virtualNetwork: ""
        },
        webAppServices: {
            webAppName: "",
            webAppRegion: "",
            runTimeStack: "",
            operatingSystem: "",
            publish: "",
            gitBranchName: "",
            gitUrl: "",
            appServicePlanName: "",
            appServiceRegion: "",
            pricingTier: ""
        }
    },
    hostApiLayer: {
        virtualMachine: {
            VmName: "",
            region: "",
            image: "",
            size: "",
            authType: {
                AuthorizationType: "",
                userNameSSH: "",
                keyPairName: "",
                userName: "",
                password: "",
                confirmPassword: ""
            },
            publicInboundPorts: [],
            diskStorageDetails: {
                disk: "",
                diskType: {
                    label: "",
                    value: ""
                },
                diskSize: {
                    label: "",
                    value: ""
                }
            },
            virtualNetwork: ""
        },
        webAppServices: {
            webAppName: "",
            webAppRegion: "",
            runTimeStack: "",
            operatingSystem: "",
            publish: "",
            gitBranchName: "",
            gitUrl: "",
            appServicePlanName: "",
            appServiceRegion: "",
            pricingTier: ""
        }
    },
    hostDbLayer: {
        virtualMachine: {
            VmName: "",
            region: "",
            image: "",
            size: "",
            authType: {
                AuthorizationType: "",
                userNameSSH: "",
                keyPairName: "",
                userName: "",
                password: "",
                confirmPassword: ""
            },
            publicInboundPorts: [],
            diskStorageDetails: {
                disk: "",
                diskType: {
                    label: "",
                    value: ""
                },
                diskSize: {
                    label: "",
                    value: ""
                }
            },
            virtualNetwork: ""
        },
        webAppServices: {
            webAppName: "",
            webAppRegion: "",
            runTimeStack: "",
            operatingSystem: "",
            publish: "",
            gitBranchName: "",
            gitUrl: "",
            appServicePlanName: "",
            appServiceRegion: "",
            pricingTier: ""
        }
    },
    hostServiceLayer: {},
    pageData: [],
    newPages: newPagesInitialState,
    alertMessage: {
        open: false,
        position: "",
        message: "",
        duration: 5000,
        closableOnBackDropClick: true,
        type: "success",
        transitionState: {
            grow: true
        }
    },
    drawerState: {
        open: false,
        title: "",
        type: ""
    }
};
