import APiEndPoints from "../enums/ApiEndPoints";

import axios from "./Configuration/apiConfiguration";

const userLogin = (data: any) =>
    new Promise((resolve, reject) => {
        axios
            .post(APiEndPoints.userLogin, data)
            .then((response) => {
                resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });

export default { userLogin };
