// import StorageIcon from "@mui/icons-material/CloudUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import TopNavBarIcon from "@mui/icons-material/ArrowUpward";
import EmailLoginIcon from "@mui/icons-material/Email";

import angularIonic from "../../assets/ionic-angular.png";
import rectIonic from "../../assets/ionic-react.png";
import vueIonic from "../../assets/ionic-vue.png";
import { createProjectConfig } from "../../inputconfig";
import { FormData } from "../../types/InputProps";

export const tableHead: string[] = ["Project ID", "Project Name", "Project TagLine", "Created Date", "Modified Date", "Edit", "Delete", "Download"];

export const ProjectDetailsConfig: FormData[] = [
    {
        Label: "Project Name",
        Required: true,
        Name: "projectName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter your Project Name",
        ErrorMessage: "Field Project name is required*",
        Tooltip: "Enter Your Project Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Project",
        Group: "Project",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "Email",
        Disabled: false
    },
    {
        Label: "Tag Line",
        Required: true,
        Name: "tagName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter your Project Tag Line",
        ErrorMessage: "Field Tag Line is required*",
        Tooltip: "Enter Your Tag Line",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Project",
        Group: "Project",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];

export const FrontEndConfig: FormData[] = [
    {
        Label: "",
        Required: true,
        Name: "frontEnd",
        InitialValue: "",
        Regex: [],
        RegExError: "Please choose frontend technology",
        ErrorMessage: "Please choose frontend technology",
        Tooltip: "Enter Your Project Name",
        HelperText: "",
        Type: "radio",
        List: [
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology1Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology1
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology2Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology2
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology3Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology3
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology4Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology4
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology5Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology5
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology6Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology6
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology7Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology7
            },
            {
                value: createProjectConfig?.projectData?.frontendValue?.technology8Value,
                label: createProjectConfig?.projectData?.frontendOption?.technology8
            }
        ],
        Categery: "Project",
        Group: "Project",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "Email",
        Disabled: false,
        additionalData: {
            splitColumns: false
        }
    }
];
export const BackEndConfig: FormData[] = [
    {
        Label: "",
        Required: true,
        Name: "backEnd",
        InitialValue: "",
        Regex: [],
        RegExError: "Please choose backend technology",
        ErrorMessage: "Please choose backend technology",
        Tooltip: "Enter Your Project Name",
        HelperText: "",
        Type: "radio",
        List: [
            {
                value: createProjectConfig?.projectData?.backendValue?.technology1Value,
                label: createProjectConfig?.projectData?.backendOption?.technology1
            },
            {
                value: createProjectConfig?.projectData?.backendValue?.technology2Value,
                label: createProjectConfig?.projectData?.backendOption?.technology2
            },
            {
                value: createProjectConfig?.projectData?.backendValue?.technology3Value,
                label: createProjectConfig?.projectData?.backendOption?.technology3
            },
            {
                value: createProjectConfig?.projectData?.backendValue?.technology4Value,
                label: createProjectConfig?.projectData?.backendOption?.technology4
            }
            // Add more options here
        ],
        Categery: "Project",
        Group: "Project",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "Email",
        Disabled: false,
        additionalData: {
            splitColumns: false
        }
    }
];

export const DataBaseConfig: FormData[] = [
    {
        Label: "",
        Required: true,
        Name: "database",
        InitialValue: "",
        Regex: [],
        RegExError: "Please choose database",
        ErrorMessage: "Please choose database",
        Tooltip: "Enter Your Project Name",
        HelperText: "",
        Type: "radio",
        List: [
            {
                value: createProjectConfig?.projectData?.databaseValue?.technology1Value,
                label: createProjectConfig?.projectData?.databaseOption?.option1
            },
            {
                value: createProjectConfig?.projectData?.databaseValue?.technology2Value,
                label: createProjectConfig?.projectData?.databaseOption?.option2
            },
            {
                value: createProjectConfig?.projectData?.databaseValue?.technology3Value,
                label: createProjectConfig?.projectData?.databaseOption?.option3
            }
        ],
        Categery: "Project",
        Group: "Project",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "Email",
        Disabled: false,
        additionalData: {
            splitColumns: false
        }
    }
];
// export const AuthenticationOptions = [
//     { label: "Email", value: "emailLogin", icon: <EmailLoginIcon style={{ color: "red", fontSize: "48px" }} /> },
//     {
//         label: "Facebook",
//         value: "facebook",
//         icon: <img width="48" height="48" src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="facebook-new" />
//     },
//     {
//         label: "Google",
//         value: "google",
//         icon: <img width="48" height="48" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" />
//     },
//     {
//         label: "Auth0",
//         value: "auth0",
//         icon: (
//             <img
//                 width="48"
//                 height="48"
//                 src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/external-auth0-the-solution-you-need-for-web-mobile-iot-and-internal-applications-logo-shadow-tal-revivo.png"
//                 alt="external-auth0-the-solution-you-need-for-web-mobile-iot-and-internal-applications-logo-shadow-tal-revivo"
//             />
//         )
//     }
// ];
export const AuthenticationOptions: any = [
    {
        label: "Email",
        value: "emailLogin",
        title: "Email Login",
        icon: <EmailLoginIcon style={{ color: "red", fontSize: window.innerWidth <= 1750 ? "40" : "48px" }} />
    },
    {
        label: "Facebook",
        value: "facebook",
        title: "Facebook",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="facebook-new" />
    },
    {
        label: "Google",
        value: "google",
        title: "Google",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" />
    },
    {
        label: "Auth0",
        value: "auth0",
        title: "Auth0",
        icon: (
            <img
                width="38"
                height="38"
                src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/external-auth0-the-solution-you-need-for-web-mobile-iot-and-internal-applications-logo-shadow-tal-revivo.png"
                alt="external-auth0-the-solution-you-need-for-web-mobile-iot-and-internal-applications-logo-shadow-tal-revivo"
            />
        )
    }
];
export const PaymentsOptions: any = [
    {
        label: "Razorpay",
        value: "razPay",
        title: "Razorpay",
        icon: <img width="38" height="38" src="https://img.icons8.com/material-rounded/48/cheap-2.png" alt="cheap-2" />
    },
    {
        label: "PayPal",
        value: "payPal",
        title: "PayPal",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/paypal.png" alt="paypal" />
    },
    {
        label: "Stripe",
        value: "stripe",
        title: "Stripe",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/stripe.png" alt="stripe" />
    }
];

export const MediaOptions: any = [
    {
        label: "Youtube",
        value: "youtube",
        title: "Youtube",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/youtube-play.png" alt="youtube-play" />
    },
    {
        label: "Vimeo",
        value: "vimeo",
        title: "Vimeo",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/vimeo.png" alt="vimeo" />
    },
    {
        label: "Facebook",
        value: "facebookmedia",
        title: "Facebook Media",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="facebook-new" />
    }
];
export const FileUploadOptions: any = [
    {
        label: "FTP",
        value: "ftp",
        title: "FTP",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/ftp.png" alt="ftp" />
    },
    {
        label: "Storage",
        value: "storage",
        title: "Storage",
        icon: <img width="38" height="38" src="https://img.icons8.com/fluency/48/cloud-storage.png" alt="cloud-storage" />
    }
];
export const ChatOptions: any = [
    {
        label: "Web-Chat",
        value: true,
        title: "Web-Chat",
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/chat.png" alt="youtube-play" />
    }
];
export const componentoptions: any = {
    Authentication: [
        { label: "Email", value: "emailLogin", icon: <EmailLoginIcon style={{ color: "red", fontSize: "48px" }} /> },
        {
            label: "Facebook",
            value: "facebook",
            icon: <img width="48" height="48" src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="facebook-new" />
        },
        {
            label: "Google",
            value: "google",
            icon: <img width="48" height="48" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" />
        },
        {
            label: "Auth0",
            value: "auth0",
            icon: (
                <img
                    width="48"
                    height="48"
                    src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/external-auth0-the-solution-you-need-for-web-mobile-iot-and-internal-applications-logo-shadow-tal-revivo.png"
                    alt="external-auth0-the-solution-you-need-for-web-mobile-iot-and-internal-applications-logo-shadow-tal-revivo"
                />
            )
        }
    ],
    Payments: [
        {
            label: "Razorpay",
            value: "razPay",
            icon: <img width="48" height="48" src="https://img.icons8.com/material-rounded/48/cheap-2.png" alt="cheap-2" />
        },
        { label: "PayPal", value: "payPal", icon: <img width="48" height="48" src="https://img.icons8.com/color/48/paypal.png" alt="paypal" /> },
        { label: "Stripe", value: "stripe", icon: <img width="48" height="48" src="https://img.icons8.com/color/48/stripe.png" alt="stripe" /> }
    ],
    Media: [
        {
            label: "Youtube",
            value: "youtube",
            icon: <img width="48" height="48" src="https://img.icons8.com/color/48/youtube-play.png" alt="youtube-play" />
        },
        { label: "Vimeo", value: "vimeo", icon: <img width="48" height="48" src="https://img.icons8.com/color/48/vimeo.png" alt="vimeo" /> },
        {
            label: "Facebook",
            value: "facebook",
            icon: <img width="48" height="48" src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="facebook-new" />
        }
    ],
    "File Upload": [
        { label: "FTP", value: "ftp", icon: <img width="48" height="48" src="https://img.icons8.com/color/48/ftp.png" alt="ftp" /> },
        {
            label: "Storage",
            value: "storage",
            icon: <img width="48" height="48" src="https://img.icons8.com/fluency/48/cloud-storage.png" alt="cloud-storage" />
        }
    ]
};

export const frontendOption: any = [
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology1Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology1,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/react-native.png" alt="react-native" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology2Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology2,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/vue-js.png" alt="vue-js" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology3Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology3,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/angularjs.png" alt="angularjs" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology4Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology4,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/ionic.png" alt="ionic" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology5Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology5,
        icon: <img width="38" height="38" src={rectIonic} alt="react-ionic" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology6Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology6,
        icon: <img width="38" style={{ objectFit: "cover" }} height="38" src={vueIonic} alt="vue-ionic" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology7Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology7,
        icon: <img width="38" style={{ objectFit: "cover" }} height="38" src={angularIonic} alt="angularjs-ionic" />
    },
    {
        value: createProjectConfig?.projectData?.frontendValue?.technology8Value,
        label: createProjectConfig?.projectData?.frontendOption?.technology8,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/react-native.png" alt="react-native" />
    }
];

export const backendOption: any = [
    {
        value: createProjectConfig?.projectData?.backendValue?.technology1Value,
        label: createProjectConfig?.projectData?.backendOption?.technology1,
        icon: <img width="38" height="38" src="https://img.icons8.com/fluency/48/node-js.png" alt="node-js" />
    },
    {
        value: createProjectConfig?.projectData?.backendValue?.technology2Value,
        label: createProjectConfig?.projectData?.backendOption?.technology2,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/c-sharp-logo.png" alt="c-sharp-logo" />
    },
    {
        value: createProjectConfig?.projectData?.backendValue?.technology3Value,
        label: createProjectConfig?.projectData?.backendOption?.technology3,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/spring-logo.png" alt="spring-logo" />
    },
    {
        value: createProjectConfig?.projectData?.backendValue?.technology4Value,
        label: createProjectConfig?.projectData?.backendOption?.technology4,
        icon: <img width="38" height="38" src="https://img.icons8.com/material-outlined/48/api-settings.png" alt="api-settings" />
    }
];

export const databaseOptions: any = [
    {
        value: createProjectConfig?.projectData?.databaseValue?.technology1Value,
        label: createProjectConfig?.projectData?.databaseOption?.option1,
        icon: <img width="38" height="38" src="https://img.icons8.com/nolan/48/mongo-db.png" alt="mongo-db" />
    },
    {
        value: createProjectConfig?.projectData?.databaseValue?.technology2Value,
        label: createProjectConfig?.projectData?.databaseOption?.option2,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/mysql-logo.png" alt="mysql-logo" />
    },
    {
        value: createProjectConfig?.projectData?.databaseValue?.technology3Value,
        label: createProjectConfig?.projectData?.databaseOption?.option3,
        icon: <img width="38" height="38" src="https://img.icons8.com/color/48/microsoft-sql-server.png" alt="microsoft-sql-server" />
    }
];

export const appFontOptions = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier New"
    // Add more font options here
];
export const navOptions: any = [
    { option: "right", icon: <ArrowForwardIosIcon /> },
    { option: "left", icon: <ArrowBackIosIcon /> }
];

// [
//     { option: "top", icon: <TopNavBarIcon /> },
//     { option: "right", icon: <RightNavBarIcon /> },
//     { option: "left", icon: <LeftNavBarIcon /> }
// ];
