import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { en } from "../../../dictionary/en";
import { useAppDispatch } from "../../../Redux/Hooks";
import { handleDrawerClose } from "../../../Redux/Reducers";
import EditDataFormPage from "../../Form/Editpage/EditDataForm";
import EditListFormPage from "../../Form/Editpage/EditListForm";
import EditReportFormPage from "../../Form/Editpage/EditReportForm";

const EditPage = ({ page }: any) => {
    const dispatch = useAppDispatch();
    const [pageData] = useState<any>(page[0]);

    const [dataPage, setDataPage] = useState(false);
    const [listPage, setListPage] = useState(false);
    const [reportPage, setReportPage] = useState(false);
    console.log(pageData);
    useEffect(() => {
        if (pageData?.pageType) {
            if (pageData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowdataform) {
                setDataPage(true);
            } else if (pageData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowlistform) {
                setListPage(true);
            } else if (pageData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowreportform) {
                setReportPage(true);
            }
        }
    }, [pageData]);
    console.log({ pageData });
    const handleClose = () => {
        dispatch(handleDrawerClose());
    };
    return (
        <Grid container p={2} rowGap={2}>
            <Grid item xs={12} py={2}>
                {dataPage ? (
                    <EditDataFormPage
                        jsonDatavalue={pageData}
                        onPropsHandleChange={() => {
                            setDataPage(false);
                            handleClose();
                        }}
                    />
                ) : listPage ? (
                    <EditListFormPage
                        jsonDatavalue={pageData}
                        onPropsHandleChange={() => {
                            setListPage(false);
                            handleClose();
                        }}
                    />
                ) : reportPage ? (
                    <EditReportFormPage
                        jsonDatavalue={pageData}
                        onPropsHandleChange={() => {
                            setReportPage(false);
                            handleClose();
                        }}
                    />
                ) : null}
            </Grid>
        </Grid>
    );
};

export default EditPage;
