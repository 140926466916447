import { toastConfigOptionType } from "./types";

export const ToastConfigOptions: toastConfigOptionType[] = [
    {
        title: "position",
        options: [
            { label: "Top-Right", value: "top-right" },
            { label: "Top-Center", value: "top-center" },
            { label: "Bottom-Right", value: "bottom-right" },
            { label: "Bottom-Center", value: "bottom-center" }
        ]
    },
    {
        title: "type",
        options: [
            { label: "Info", value: "info" },
            { label: "Success", value: "success" },
            { label: "Warning", value: "warn" },
            { label: "Error", value: "error" }
        ]
    },
    {
        title: "theme",
        options: [
            { label: "Light", value: "light" },
            { label: "Dark", value: "dark" },
            { label: "Colored", value: "colored" }
        ]
    }
];
