import { FormData } from "../../../types/InputProps";

export const AppServicePlanPage: FormData[] = [
    {
        Label: "Virtual Machine Name",
        Required: true,
        Name: "VmName",
        InitialValue: "",
        Regex: [
            // '^(?!.*[/"\\[\\]:|<>+=;,?*@&\\s]|^_|_$|[.-]$)(?!administrator|admin|user|user1|test|user2|test1|user3|admin1|1|123|a|actuser|adm|admin2|aspnet|backup|console|david|guest|john|owner|root|server|sql|support|support_388945a0|sys|test2|test3|user4|user5)[^s]{1,64}$'
        ],

        RegExError: "Please Enter VM Name",
        ErrorMessage: "VM Name cannot contain special characters, spaces, or be too long.",
        Tooltip: " Enter VM Name ",
        HelperText: "vm-nimbusjs-ui-dev",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false,
        Prefix: "vm-"
    },
    {
        Label: " Region",
        Required: true,
        Name: "region",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Region",
        ErrorMessage: "Region is required*",
        Tooltip: "Region Selection",
        HelperText: "Recommended Regions are Displayed",
        Type: "select",
        List: [
            { label: "(US) East US", value: "(US) East US" },
            { label: "(US) East US 2", value: "(US) East US 2" },
            { label: "(US) South Central  US", value: "(US) South Central  US" },
            { label: "(US) West US 2", value: "(US) West US 2" },
            { label: "(US) West US 3", value: "(US) West US 3" },
            { label: "(Asia Pacific) Australia East", value: "(Asia Pacific) Australia East" },
            { label: "(Asia Pacific) South East Asia", value: "(Asia Pacific) South East Asia" },
            { label: "(Europe) North Europe", value: "(Europe) North Europe" },
            { label: "(Europe) Sweden Central", value: "(Europe) Sweden Central" },
            { label: "(Europe) UK South", value: "(Europe) UK South" },
            { label: "(Europe) West Europe", value: "(Europe) West Europe" },
            { label: "(US)  Central  US", value: "(US)  Central  US" },
            { label: "(Africa) South Africa North", value: "(Africa) South Africa North" },
            { label: "(Asia Pacific) Central India", value: "(Asia Pacific) Central India" },
            { label: "(Asia Pacific) East Asia", value: "(Asia Pacific) East Asia" },
            { label: "(Asia Pacific) Japan East ", value: "(Asia Pacific) Japan East " },
            { label: "(Asia Pacific) Korea Central ", value: "(Asia Pacific) Korea Central " },
            { label: "(Canada) Canada Central ", value: "(Canada) Canada Central " },
            { label: "(Europe) France Central", value: "(Europe) France Central" },
            { label: "(Europe) Germany West Central", value: "(Europe) Germany West Central" },
            { label: "(Europe) Italy North", value: "(Europe) Italy North" },
            { label: "(Europe) Norway East", value: "(Europe) Norway East" },
            { label: "(Europe) Poland Central", value: "(Europe) Poland Central" },
            { label: "(Europe) Swizerland North", value: "(Europe) Swizerland North" },
            { label: "(Middle East) UAE North", value: "(Middle East) UAE North" },
            { label: "(South America) Brazil South", value: "(South America) Brazil South" },
            { label: "(Middle East) Israel Central", value: "(Middle East) Israel Central" },
            { label: "(Middle East) Qatar Central", value: "(Middle East) Qatar Central" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Image",
        Required: true,
        Name: "image",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Image",
        ErrorMessage: "Image is required*",
        Tooltip: "Image Selection",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Ubuntu Server 20.04 LTS -x64 Gen2", value: "Ubuntu Server 20.04 LTS -x64 Gen2" },
            { label: "Ubuntu Server 22.04 LTS -x64 Gen2", value: "Ubuntu Server 22.04 LTS -x64 Gen2" },
            {
                label: "Windows Server 2022 Datacenter:Azure Edition Hotpatch - x64 Gen2",
                value: "Windows Server 2022 Datacenter:Azure Edition Hotpatch - x64 Gen2"
            },
            {
                label: "Windows Server 2022 Datacenter:Azure Edition Hotpatch - x64 Gen2",
                value: "Windows Server 2022 Datacenter:Azure Edition  - x64 Gen2"
            },
            { label: "Windows Server 2019 Datacenter - x64 Gen2", value: "Windows Server 2022 Datacenter - x64 Gen2" },
            { label: "Windows Server 2016 Datacenter - x64 Gen2", value: "Windows Server 2016 Datacenter - x64 Gen2" },
            { label: "Windows Server 2016 Datacenter - x64 Gen2", value: "Windows Server 2016 Datacenter - x64 Gen2" },
            { label: "Windows 10 Pro,version 22H2 - x64 Gen2", value: "Windows 10 Pro,version 22H2 - x64 Gen2" },
            { label: "Windows 11 Pro,version 22H2 - x64 Gen2", value: "Windows 11 Pro,version 22H2 - x64 Gen2" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Size",
        Required: true,
        Name: "size",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Size",
        ErrorMessage: "Size is required*",
        Tooltip: "Size Selection",
        HelperText:
            "Note: Please be aware that the pricing provided above is merely an approximate figure. For a precise cost estimate, we recommend consulting the Azure pricing calculator.",
        Type: "select",
        List: [
            {
                label: "Standard_D2s_v3 -2 -vcpus, 8 GiB memory(5503.70 Inr/month Approx.)",
                value: "Standard_D2s_v3 -2 -vcpus, 8 GiB memory( 5503.70 Inr/month  Approx.)"
            },
            {
                label: "Standard_D4s_v3 -4 -vcpus, 16 GiB memory( 11,007.70 Inr/month  Approx. )",
                value: "Standard_D4s_v3 -4 -vcpus, 16 GiB memory( 11,007.70 Inr/month  Approx.)"
            },
            {
                label: "Standard_E2s_v3 -2 vcpus, 16 GiB memory( 7223.70 Inr/month  Approx.)",
                value: "Standard_E2s_v3 -2 vcpus, 16 GiB memory( 7223.70 Inr/month  Approx.)"
            }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const StoragePage: any = [
    {
        Label: " Admin UserName",
        Required: true,
        Name: "adminUserName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter UserName",
        ErrorMessage: "UserName is required*",
        Tooltip: "Please Enter the UserName",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "App_Storage_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Admin Password",
        Required: true,
        Name: "adminPassword",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Password",
        ErrorMessage: "Url is required*",
        Tooltip: "Url Selection",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "App_Storage_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Storage Size",
        Required: true,
        Name: "storageSize",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Storage Size ",
        ErrorMessage: "Storage Size is required*",
        Tooltip: "Storage Size",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "App_Storage_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const AdminDetails: any = [
    {
        Label: "Authorization Type ",
        Required: true,
        Name: "AuthorizationType",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Authorization Type ",
        ErrorMessage: "Authorization Type  is required*",
        Tooltip: "Authorization Type ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "SSH key", value: "sshKey" },
            { label: "Password", value: "password" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const DiskStoragePage: any = [
    {
        Label: "OS Disk Size ",
        Required: true,
        Name: "osDiskSize",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Disk Size ",
        ErrorMessage: "Disk Size  is required*",
        Tooltip: "Disk Size ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Default size (30 GiB)", value: "Default size (30 GiB)" },
            { label: "Resize to 32 GiB(P4)", value: "Resize to 32 GiB(P4)" },
            { label: "Resize to 64 GiB(P6) Free account eligible", value: "Resize to 64 GiB(P6) Free account eligible" },
            { label: "Resize to 128 GiB(P10) ", value: "Resize to 128 GiB(P10)" },
            { label: "Resize to 256 GiB(P15) ", value: "Resize to 256 GiB(P15)" },
            { label: "Resize to 512 GiB(P20) ", value: "Resize to 512 GiB(P20)" },
            { label: "Resize to 1 TiB(P30) ", value: "Resize to 1 TiB(P30)" },
            { label: "Resize to 2 TiB(P40) ", value: "Resize to 2 TiB(P40)" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "OS Disk Type ",
        Required: true,
        Name: "osDiskType",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Disk Type ",
        ErrorMessage: "Disk Type  is required*",
        Tooltip: "Disk Type ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Locally-reduntant storage (data is replicated within a single datacenter)", value: "locallyRedundant" },
            { label: "ZONE-reduntant storage(data is replicated to three zones)", value: "zoneRedundant" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const SelectPort: any = [
    {
        Label: "Public Inbound Ports",
        Required: true,
        Name: "publicInboundPorts",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter  ports ",
        ErrorMessage: "ports  is required*",
        Tooltip: "ports",
        HelperText: "",
        Type: "select",
        List: [
            { label: "HTTPS(443)", value: "https(443)" },
            { label: "HTTP(80)", value: "http(80)" },
            { label: "RDP(3389)", value: "rdp(3389)" },
            { label: "SSH(22)", value: "ssh(22)" },
            { label: "MySql(3306)", value: "mysql(3306)" },
            { label: "PostGreySql(5432)", value: "postgreySql(5432)" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 2,
        PlaceHolder: "",
        Disabled: false
    }
];
export const VnetPage: any = [
    {
        Label: "Virtual Network",
        Required: true,
        Name: "virtualNetwork",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Virtual Network",
        ErrorMessage: "Virtual Network is required*",
        Tooltip: "Virtual Network",
        HelperText: "vnet-vm-nimbusjs-ui-dev",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
    // {
    //   Label: "Public Inbound Ports",
    //   Required: true,
    //   Name: "publicInboundPorts",
    //   InitialValue: "",
    //   Regex: [],
    //   RegExError: "Please Select  Inbound Ports",
    //   ErrorMessage: "Public Inbound Ports is required*",
    //   Tooltip: " Select Inbound Ports ",
    //   HelperText: "",
    //   Type: "select",
    //   List: [
    //     { label: "HTTPS(443)", value: "https" },
    //     { label: "HTTP(80)", value: "http" },
    //     { label: "SSH(22)", value: "ssh" },
    //     { label: "MySql(3306)", value: "mysql" },
    //     { label: "PostGreySql(5432)", value: "postgreySql" },
    //   ],
    //   Categery: "Generate Page",
    //   Group: "VM_Data",
    //   MinValue: null,
    //   MaxValue: null,
    //   Multiple: 2,
    //   PlaceHolder: "",
    //   Disabled: false,
    // },
];

export const AzureSqlDb: any = [
    {
        Label: "Server Name",
        Required: true,
        Name: "serverName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Server Name",
        ErrorMessage: "Server Name is required*",
        Tooltip: "Server Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Sql Database Name",
        Required: true,
        Name: "sqlDatabaseName",
        InitialValue: "",
        Regex: [
            // '^(?!.*[/"\\[\\]:|<>+=;,?*@&\\s]|^_|_$|[.-]$)(?!administrator|admin|user|user1|test|user2|test1|user3|admin1|1|123|a|actuser|adm|admin2|aspnet|backup|console|david|guest|john|owner|root|server|sql|support|support_388945a0|sys|test2|test3|user4|user5)[^s]{1,64}$'
        ],

        RegExError: "Please Enter Sql Database Name",
        ErrorMessage: "Sql Database Name cannot contain special characters, spaces, or be too long.",
        Tooltip: " Enter Sql Database Name ",
        HelperText: "db-nimbusjs-dev",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Administrator Login",
        Required: true,
        Name: "administratorLogin",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Administrator Login",
        ErrorMessage: "Administrator Login is required*",
        Tooltip: "Administrator Login ",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Administrator Login Password",
        Required: true,
        Name: "administratorLoginPassword",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Administrator Login Password",
        ErrorMessage: "Administrator Login Password is required*",
        Tooltip: "Administrator Login Password",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Region",
        Required: true,
        Name: "sqlDbRegion",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Region",
        ErrorMessage: "Region is required*",
        Tooltip: "Region Selection",
        HelperText: "Recommended Regions are Displayed",
        Type: "select",
        List: [
            { label: "(US) East US", value: "(US) East US" },
            { label: "(US) East US 2", value: "(US) East US 2" },
            { label: "(US) South Central  US", value: "(US) South Central  US" },
            { label: "(US) West US 2", value: "(US) West US 2" },
            { label: "(US) West US 3", value: "(US) West US 3" },
            { label: "(Asia Pacific) Australia East", value: "(Asia Pacific) Australia East" },
            { label: "(Asia Pacific) South East Asia", value: "(Asia Pacific) South East Asia" },
            { label: "(Europe) North Europe", value: "(Europe) North Europe" },
            { label: "(Europe) Sweden Central", value: "(Europe) Sweden Central" },
            { label: "(Europe) UK South", value: "(Europe) UK South" },
            { label: "(Europe) West Europe", value: "(Europe) West Europe" },
            { label: "(US)  Central  US", value: "(US)  Central  US" },
            { label: "(Africa) South Africa North", value: "(Africa) South Africa North" },
            { label: "(Asia Pacific) Central India", value: "(Asia Pacific) Central India" },
            { label: "(Asia Pacific) East Asia", value: "(Asia Pacific) East Asia" },
            { label: "(Asia Pacific) Japan East ", value: "(Asia Pacific) Japan East " },
            { label: "(Asia Pacific) Korea Central ", value: "(Asia Pacific) Korea Central " },
            { label: "(Canada) Canada Central ", value: "(Canada) Canada Central " },
            { label: "(Europe) France Central", value: "(Europe) France Central" },
            { label: "(Europe) Germany West Central", value: "(Europe) Germany West Central" },
            { label: "(Europe) Italy North", value: "(Europe) Italy North" },
            { label: "(Europe) Norway East", value: "(Europe) Norway East" },
            { label: "(Europe) Poland Central", value: "(Europe) Poland Central" },
            { label: "(Europe) Swizerland North", value: "(Europe) Swizerland North" },
            { label: "(Middle East) UAE North", value: "(Middle East) UAE North" },
            { label: "(South America) Brazil South", value: "(South America) Brazil South" },
            { label: "(Middle East) Israel Central", value: "(Middle East) Israel Central" },
            { label: "(Middle East) Qatar Central", value: "(Middle East) Qatar Central" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Service Tier",
        Required: true,
        Name: "serviceTier",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Service Tier",
        ErrorMessage: "Service Tier is required*",
        Tooltip: "Service Tier",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Basic(For less demanding workloads)", value: "basic" },
            { label: "Standard(Budget friendly)", value: "standard" },
            { label: "Premium (Highest availability and performance)", value: "premium" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
