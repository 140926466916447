import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../../Redux/Reducers";
import { validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { FuncAppVmPage } from "./Utilities";

const FunctionVmPlan = ({ nextButton }: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hostLayerType, hostApiLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;
    const formikProps = useFormik({
        initialValues: {
            ...FuncAppVmPage.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values) => {
            if (layer === en.isUiLayer) {
                dispatch(handleHostUi(values));
            } else if (layer === en.isApiLayer) {
                if (hostApiLayer?.hostingOption === en.functionHostPlanAsConsumption) {
                    dispatch(handleHostApiLayer(values));
                    navigate(RoutesEnum.functionsBiScript);
                } else if (hostApiLayer?.hostingOption !== en.functionHostPlanAsConsumption) {
                    dispatch(handleHostApiLayer(values));
                    navigate(RoutesEnum.functionsBiScript);
                }
            } else if (layer === en.isDbLayer) {
                dispatch(handleHostDbLayer(values));
                navigate(RoutesEnum.appServicePlan);
            }
        },
        validate: (values) => validateFormOnSubmit(values, [FuncAppVmPage])
    });
    useEffect(() => {
        if (nextButton) {
            formikProps.handleSubmit();
        }
    }, [nextButton]);
    return (
        <Box sx={{ marginTop: "18px" }}>
            <Typography>Storage Account</Typography>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box px={0.1} py={2}>
                    <GenerateForm FormData={FuncAppVmPage} FormikProps={formikProps} />
                </Box>
            </Box>
        </Box>
    );
};

export default FunctionVmPlan;
