import { toast } from "react-toastify";

import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "../API";

export const GetProjects = createAsyncThunk("GetProjects", async () => {
    try {
        const data = await API.Projects.getProjects();
        return data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Something Went Wrong");
    }
});
export const GetPages = createAsyncThunk("GetPages", async () => {
    try {
        const data = await API.Projects.getPages();
        return data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Something Went Wrong");
    }
});
export const UserLogin = createAsyncThunk("UserLogin", async (data: any) => {
    try {
        const response: any = await API.Users.userLogin(data);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        throw new Error(error?.message ?? "Something Went Wrong");
    }
});
