/* eslint-disable no-underscore-dangle */
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Delete, Download } from "@mui/icons-material";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import API from "../../API";
import { en } from "../../dictionary/en";
import ConfigEnum from "../../enums/Config.enum";
import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handleProjects, handleProjectsData } from "../../Redux/Reducers";
import { downloadJsonFile, uploadJSON, validateFormOnSubmit } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../Theme/Theme";

import EditProject from "./EditProject";
import { ProjectDetailsConfig, tableHead } from "./Uitilities";

const CreateProject = () => {
    const dispatch = useAppDispatch();
    const { projectData, newProject } = useAppSelector((state) => state);
    const [selectedProject, setSelectedProject] = useState(null);
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    // const handleClick = () => {
    //     navigate(RoutesEnum.projectDetails);
    // };

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleClose = () => {
        dispatch(handleDrawerClose());
    };
    const formikProps = useFormik({
        initialValues: {
            ...ProjectDetailsConfig.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a })),
            ...newProject.projectDetails
        },
        onSubmit: async (values) => {
            dispatch(
                handleProjects({
                    projectDetails: {
                        projectName: values?.projectName,
                        tagName: values.tagName
                    }
                })
            );
            handleClose();
            navigate(RoutesEnum.technologies);
        },
        validate: (values) => {
            return validateFormOnSubmit(values, [ProjectDetailsConfig]);
        }
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (inputRef.current) {
            uploadJSON(event, ConfigEnum.uploadProjectJson)
                .then(() => {
                    setTimeout(() => {
                        navigate(RoutesEnum.projectDetails);
                    }, 500);
                })
                .catch((err) => {
                    toast.error(err);
                });
        }
    };
    const handleCreateProject = () => {
        dispatch(handleDrawerOpen({ open: true, type: "CREATE_PROJECT" }));
    };
    const handleEdit = (data: any) => {
        setSelectedProject(projectData.filter((e: any) => e._id === data._id));
        dispatch(handleDrawerOpen({ open: true, type: en.editProjectType }));
    };
    const handleDelete = async (data: any) => {
        const deletedData = projectData?.filter((project: any) => project._id !== data._id);
        const res: any = await API.Projects.deleteProjects(data._id);
        if (res?.data) {
            dispatch(handleProjectsData(deletedData));
        }
        toast.success(`Deleted ${data.projectDetails.projectName.toLowerCase()} project`);
    };
    const handleDownload = (data: any, filename: any) => {
        downloadJsonFile(data, filename);
    };
    return (
        <>
            <HoC.CardComponent>
                <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
                    <Box display="flex" gap={2} paddingBottom={5}>
                        <Button
                            sx={{
                                fontSize: mediaQuery ? "1rem" : "1.25rem"
                            }}
                            style={buttonStyle}
                            className="NextButton"
                            onClick={handleCreateProject}
                            startIcon={<CreateNewFolderOutlinedIcon />}
                        >
                            {en.createprojectbtn}
                        </Button>
                        <Button
                            sx={{
                                fontSize: mediaQuery ? "1rem" : "1.25rem"
                            }}
                            style={buttonStyle}
                            className="NextButton"
                            onClick={handleUpload}
                            startIcon={<UploadFileIcon />}
                        >
                            {en.uploadjson}
                        </Button>
                        <input type="file" ref={inputRef} style={{ display: "none" }} onChange={handleChange} />
                    </Box>
                    <Box className="scrollOver400px">
                        <HoC.TableComponent
                            tableHead={tableHead}
                            tableData={projectData}
                            editButton={
                                <Button sx={{ justifyContent: "flex-start" }}>
                                    <EditIcon sx={{ color: "#303f9f" }} />
                                </Button>
                            }
                            deleteButton={
                                <Button sx={{ justifyContent: "flex-start" }}>
                                    <Delete sx={{ color: "#303f9f" }} />
                                </Button>
                            }
                            downloadButton={
                                <Button sx={{ justifyContent: "flex-center" }}>
                                    <Download sx={{ color: "#303f9f" }} />
                                </Button>
                            }
                            handleDownload={handleDownload}
                            handleEditData={handleEdit}
                            handleDeleteData={handleDelete}
                        />
                    </Box>
                </Box>
            </HoC.CardComponent>
            <HoC.CustomDrawer type={en.editProjectType} title={en.EditProjectTitle} handleDrawerOnClose={handleClose}>
                <EditProject project={selectedProject} />
            </HoC.CustomDrawer>
            <HoC.CustomDrawer type="CREATE_PROJECT" title="Project Details" handleDrawerOnClose={handleClose}>
                <Box px={4} py={2}>
                    <HoC.GenerateForm FormikProps={formikProps} FormData={ProjectDetailsConfig} />
                    <Box>
                        <HoC.Navigators handleNext={formikProps.handleSubmit} />
                    </Box>
                </Box>
            </HoC.CustomDrawer>
        </>
    );
};

export default CreateProject;
