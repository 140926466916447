import {
  UPDATE_LOGIN_TYPE,
  CREATE_PAGE,
  CREATE_PROJECT,
  CREATE_CUSTOM_PROJECT,
  UPDATE_PROJECT_DETAILS,
  UPDATE_TECHNOLOGY,
  UPDATE_COMPONENT,
  UPDATE_PROJECT_DETAILS_FROM_JSON,
  UPDATE_TECHNOLOGY_FROM_JSON,
  UPLOAD_PAGE_JSON,
  CLEAR_PROJECT_STATE,
  UPDATE_DEFAULT_CHECKBOX,
  LOGIN,
  CREATE_HOST,
  UILAYER_IAAS,
  APILAYER_IAAS,
  DBLAYER_IAAS,
  LAYER_HOST,
  CREATE_HOST_API,
} from "./actionTypes";

export const updateLoginType = (loginType) => {
  return {
    type: UPDATE_LOGIN_TYPE,
    payload: loginType,
  };
};
export const createproject = (createProject) => {
  return {
    type: CREATE_PROJECT,
    payload: createProject,
  };
};
export const createpage = (createpage) => {
  return {
    type: CREATE_PAGE,
    payload: createpage,
  };
};
export const createCustomProject = (createCustomProject) => {
  return {
    type: CREATE_CUSTOM_PROJECT,
    payload: createCustomProject,
  };
};

export const updateProjectDetails = (updatedDetails) => {
  return {
    type: UPDATE_PROJECT_DETAILS,
    payload: {
      projectDetails: updatedDetails,
    },
  };
};

export const updateTechnology = (updatedTechnology) => {
  return {
    type: UPDATE_TECHNOLOGY,
    payload: {
      technology: updatedTechnology,
    },
  };
};
export const updateComponent = (updatedComponent) => {
  return {
    type: UPDATE_COMPONENT,
    payload: {
      component: updatedComponent,
    },
  };
};
export const updateProjectFromJson = (updatedDetailsFromJson) => {
  return {
    type: UPDATE_PROJECT_DETAILS_FROM_JSON,
    payload: {
      ...updatedDetailsFromJson,
    },
  };
};
export const updateTechnologyFromJson = (updatedTechnologyFromJson) => {
  return {
    type: UPDATE_TECHNOLOGY_FROM_JSON,
    payload: {
      technology: updatedTechnologyFromJson,
    },
  };
};
export const updatePageJson = (data) => {
  return {
    type: UPLOAD_PAGE_JSON,
    payload: {
      pageJson: data,
    },
  };
};
export const updateDefaultCheckbox = (data) => {
  return {
    type: UPDATE_DEFAULT_CHECKBOX,
    payload: {
      defaultCheckbox: data,
    },
  };
};
export const clearProject = (data) => {
  return {
    type: CLEAR_PROJECT_STATE,
    payload: {
      projectDetails: {
        projectName: null,
        tagName: null,
        base64Logo: "",
        faviIconBase64Image: "",
        themeColor: [],
        appFont: "",
      },

      technology: {
        frontend: null,
        api: null,
        database: null,
        host: null,
      },

      component: [],

      pageJson: {
        formType: "",
        pageType: "",
        pageName: "",
        pageDetails: [],
      },
    },
  };
};

export const UpdateLoggedIn = (data) => {
  return {
    type: LOGIN,
    payload: data,
  };
};
export const createHost=(data)=>{
  return{
    type:CREATE_HOST,
    payload:{
      host:data
    }
  }
}
export const createHostApi=(data)=>{
  return{
    type:CREATE_HOST_API,
    payload:{
      hostApi:data
    }
  }
}
export const layerHost=(data)=>{
  return{
    type:LAYER_HOST,
    payload:{
      layer:data
    }
  }
}

export const uiLayerIaas = (data) => {
  return {
    type: UILAYER_IAAS,
    payload: {
      uiIaas: data,
    },
  };
};
export const apiLayerIaas = (data) => {
  return {
    type: APILAYER_IAAS,
    payload: {
      apiIaas: data,
    },
  };
};
export const dbLayerIaas = (data) => {
  return {
    type: DBLAYER_IAAS,
    payload: {
      dbIaas: data,
    },
  };
};
