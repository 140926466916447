import React from "react";

import { Button } from "@mui/material";
import { Box } from "@mui/system";

import { en } from "../../../dictionary/en";
import { useAppSelector } from "../../../Redux/Hooks";
import { DownloadText } from "../../../ReusableCodes/Resuseablefunctions";

const BiScriptAppServices = () => {
    const { hostLayerType, hostUi, hostApiLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;
    let content = "";
    // const typeOfHostUi = hostUi?.hostType;
    // const typeOfHostApi = hostApiLayer?.hostType;
    const appServicePlanUi = hostUi?.webAppServices?.appServicePlanName;
    const webAppNameUi = hostUi?.webAppServices?.webAppName;
    const osUi = hostUi?.webAppServices?.operatingSystem;
    const osVersionUi = hostUi?.webAppServices?.runTimeStack;
    const gitBranchUi = hostUi?.webAppServices?.gitBranchName;
    const pricePlanUi = hostUi?.webAppServices?.pricingTier;
    const gitUrlUi = hostUi?.webAppServices?.gitUrl;
    const isGitUi = hostApiLayer?.webAppServices?.gitUrl === "";
    const appServicePlanApi = hostApiLayer?.webAppServices?.appServicePlanName;
    const webAppNameApi = hostApiLayer?.webAppServices?.webAppName;
    const osApi = hostApiLayer?.webAppServices?.operatingSystem;
    const osVersionApi = hostApiLayer?.webAppServices?.runTimeStack;
    const gitUrlApi = hostApiLayer?.webAppServices?.gitUrl;
    const gitBranchApi = hostApiLayer?.webAppServices?.gitBranchName;
    const pricePlanApi = hostApiLayer?.webAppServices?.pricingTier;
    const isGitApi = hostApiLayer?.webAppServices?.gitUrl === "";

    if (layer === en.isUiLayer) {
        if (isGitUi) {
            content = `
            1. Provide me with an Azure BICEP script for generating a simple ${pricePlanUi} appservice plan named ${appServicePlanUi}.
            2. create an Azure web application named ${webAppNameUi} using the above-mentioned appservice plan with  ${osUi}FxVersion as ${osVersionUi}.
           `;
        } else {
            content = `
            1. Provide me with an Azure BICEP script for generating a simple ${pricePlanUi} appservice plan named ${appServicePlanUi}.
            2. create an Azure web application named ${webAppNameUi} using the above-mentioned appservice plan with  ${osUi}FxVersion as ${osVersionUi}.
            3. I wish to integrate the web application's source code, which is available
            at ${gitUrlUi} with the ${gitBranchUi} branch and continuous integration turned on.
            `;
        }
    } else if (layer === en.isApiLayer) {
        if (isGitApi) {
            content = `
            1. Provide me with an Azure BICEP script for generating a simple ${pricePlanApi} appservice plan named ${appServicePlanApi}.
            2. create an Azure web application named ${webAppNameApi} using the above-mentioned appservice plan with  ${osApi}FxVersion as ${osVersionApi}.
           `;
        } else {
            content = `
        1. Provide me with an Azure BICEP script for generating a simple ${pricePlanApi} appservice plan named ${appServicePlanApi}.
        2. create an Azure web application named ${webAppNameApi} using the above-mentioned appservice plan with  ${osApi}FxVersion as ${osVersionApi}.
        3. I wish to integrate the web application's source code, which is available
        at ${gitUrlApi} with the ${gitBranchApi} branch and continuous integration turned on.
      `;
        }
    }

    const handleDownloadClick = () => {
        DownloadText(content, layer);
    };

    return (
        <Box width="100vw" sx={{ textAlign: "-webkit-center" }}>
            <Box
                component="div"
                width="80vw"
                sx={{
                    position: "relative",
                    top: "210px",
                    padding: "40px",
                    boxShadow: "10px 8px 20px 5px rgba(0,0,0,0.1)",
                    textAlign: "left",
                    lineHeight: "25px"
                }}
            >
                <p>{content}</p>
                <br />
                <Button onClick={handleDownloadClick}>ChatGPT Integration</Button>
            </Box>
        </Box>
    );
};

export default BiScriptAppServices;
