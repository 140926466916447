import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Grid, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";

import { useAppSelector } from "../../Redux/Hooks";

import { CustomDrawerProps } from "./types";

const CustomDrawer = (props: CustomDrawerProps) => {
    const drawerState = useAppSelector((state) => state.drawerState);
    const { type, title, children, handleDrawerOnClose } = props;

    const formattedType = type.replace(/_/g, " ").toLowerCase();
    const formattedTitle = title?.toLowerCase();

    const breadcrumbs = [
        <Box onClick={handleDrawerOnClose} sx={{ cursor: "pointer" }}>
            {formattedType
                .split(" ")
                .map((val) => {
                    if (val === "vm" || val === "crud") {
                        return val.toUpperCase();
                    }
                    return val[0].toUpperCase() + val.slice(1);
                })
                .join(" ")}
        </Box>,
        <Typography color="text.primary">
            {formattedTitle
                ?.split(" ")
                .map((val) => {
                    if (val === "ftp") {
                        return val.toUpperCase();
                    }
                    if (val) {
                        return val[0].toUpperCase() + val.slice(1);
                    }
                    return val;
                })
                .join(" ")}
        </Typography>
    ];

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            sx={{
                "& .MuiDrawer-modal": {
                    backgroundColor: "#fff"
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0)"
                }
            }}
            open={drawerState.type === type ? drawerState.open : false}
            onClose={handleDrawerOnClose}
        >
            <Box sx={{ maxWidth: "660px", width: "100%", display: "flex", justifyContent: "center" }}>
                <Grid container spacing={2} px={2} py={4}>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Stack spacing={2}>
                                <Breadcrumbs separator="›" aria-label="breadcrumb">
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>

                            <CloseIcon onClick={handleDrawerOnClose} sx={{ cursor: "pointer" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
};

export default CustomDrawer;
