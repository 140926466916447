import { en } from "../../dictionary/en";

export const tableHead: string[] = ["Page ID", "Page Name", "Page Type", "Created Date", "Modified Date", "Edit", "Delete", "Download"];

export const pageTypeButtons: any = [
    { pageType: "DataForm", buttonText: en.dataform },
    { pageType: "ListForm", buttonText: en.listform },
    { pageType: "ReportForm", buttonText: en.reportform },
    { pageType: "CrudForm", buttonText: en.crudform }
];
