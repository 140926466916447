export const FunctionAppPlan: any = [
    {
        Label: "Function App Name",
        Required: true,
        Name: "functionAppName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Function App Name",
        ErrorMessage: "Function App Name is required*",
        Tooltip: "Function App Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "App_Page",
        Group: "Functions_App__Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false,
        Prefix: "fa-"
    },
    {
        Label: "Run Time Stack ",
        Required: true,
        Name: "runTimeStack",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Run Time Stack",
        ErrorMessage: "Run Time Stack is required*",
        Tooltip: "Select Run time Stack  ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Node.js", value: "nodeJs" }
            // { label: "Node 16 LTS", value: "node16Lts" },
            // { label: "DOTNETCORE:8.0", value: "dotnetCore8" },
            // { label: "DOTNETCORE:7.0", value: "dotnetCore7" },
            // { label: "DOTNETCORE:6.0", value: "dotnetCore6" },
            // { label: "NODE:18-lts", value: "node18Lts" },
            // { label: "NODE:16-lts", value: "node16Lts" },
            // { label: "PYTHON:3.11", value: "python311" },
            // { label: "PYTHON:3.10", value: "python310" },
            // { label: "PYTHON:3.9", value: "python39" },
            // { label: "PYTHON:3.8", value: "python38" },
            // { label: "PHP:8.2", value: "php82" },
            // { label: "PHP:8.1", value: "php81" },
            // { label: "PHP:8.0", value: "php80" },
            // { label: "JAVA:17-java17", value: "java17" },
            // { label: "JAVA:11-java11", value: "java11" },
            // { label: "JAVA:8-jre8", value: "java8" },
            // { label: "JBOSSEAP:7-java17", value: "jbossEap7" },
            // { label: "JBOSSEAP:7-java11", value: "jbossEap7" },
            // { label: "JBOSSEAP:7-java8", value: "jbossEap7" },
            // { label: "TOMCAT:10.0-java17", value: "tomcat10Java17" },
            // { label: "TOMCAT:10.0-java11", value: "tomcat10Java11" },
            // { label: "TOMCAT:10.0-jre8", value: "tomcat10Jre8" },
            // { label: "TOMCAT:9.0-java17", value: "tomcat9Java17" },
            // { label: "TOMCAT:9.0-java11", value: "tomcat9Java11" },
            // { label: "TOMCAT:9.0-jre8", value: "tomcat9Jre8" },
            // { label: "TOMCAT:8.5-java11", value: "tomcat8.5Java11" },
            // { label: "TOMCAT:8.5-jre8", value: "tomcat8.5Jre8" },
            // { label: "GO:1.19", value: "go1.19" },
            // { label: "DOTNET:8", value: "dotnet8" },
            // { label: "DOTNET:7", value: "dotnet7" },
            // { label: "DOTNET:6", value: "dotnet6" },
            // { label: "ASPNET:V4.8", value: "aspnetV4.8" },
            // { label: "ASPNET:V3.5", value: "aspnetV3.5" },
            // { label: "NODE:18LTS", value: "node18Lts" },
            // { label: "NODE:16LTS", value: "node16Lts" },
            // { label: "java:1.8:Java SE:8", value: "java1.8" },
            // { label: "java:11:Java SE:11", value: "java11" },
            // { label: "java:17:Java SE:17", value: "java17" },
            // { label: "java:1.8:TOMCAT:10.0", value: "java1.8Tomcat10" },
            // { label: "java:11:TOMCAT:10.0", value: "java11Tomcat10" },
            // { label: "java:17:TOMCAT:10.0", value: "java17Tomcat10" },
            // { label: "java:1.8:TOMCAT:9.0", value: "java1.8Tomcat9" },
            // { label: "java:11:TOMCAT:9.0", value: "java11Tomcat9" },
            // { label: "java:17:TOMCAT:8.5", value: "java17Tomcat8.5" }
        ],
        Categery: "App_Page",
        Group: "Functions_App__Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Version",
        Required: true,
        Name: "version",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Version",
        ErrorMessage: "Version is required*",
        Tooltip: "Version",
        HelperText: "",
        Type: "select",
        List: [
            { label: "20 (preview)", value: "20(preview)" },
            { label: "18 LTS", value: "18 LTS" },
            { label: "16 LTS", value: "16 LTS" },
            { label: "14 LTS", value: "14 LTS" }
        ],
        Categery: "App_Page",
        Group: "Functions_App__Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
    // {
    //   Label: " Region",
    //   Required: false,
    //   Name: "Region",
    //   InitialValue: "",
    //   Regex: [],
    //   RegExError: "Please Select Region",
    //   ErrorMessage: "Region is required*",
    //   Tooltip: "Region Selection",
    //   HelperText: "",
    //   Type: "select",
    //   List: [
    //     { label: "Australia Central", value: "Australia Central" },
    //     { label: "Australia East", value: "Australia East" },
    //     { label: "Australia Southeast", value: "Australia Southeast" },
    //     { label: "Brazil South", value: "Brazil South" },
    //     { label: "Canada Central", value: "Canada Central" },
    //     { label: "Canada East", value: "Canada East" },
    //     { label: "Central India", value: "Central India" },
    //     { label: "Central US", value: "Central US" },
    //     { label: "East Asia", value: "East Asia" },
    //     { label: "East US ", value: "East US " },
    //     { label: "East US 2", value: "East US 2" },
    //     { label: "France  Central", value: "France  Central" },
    //     { label: "Germany West Central", value: "Germany West Central" },
    //     { label: "Japan East", value: "Japan East" },
    //     { label: "Japan West", value: "Japan West" },
    //     { label: "Korea Central", value: "Korea Central" },
    //     { label: "Korea South", value: "Korea South" },
    //     { label: "North Central US (Stage)", value: "North Central US (Stage)" },
    //     { label: "North Europe", value: "North Europe" },
    //     { label: "Norway East", value: "Norway East" },
    //     { label: "Poland Central", value: " Poland Central" },
    //     { label: "Qatar Central", value: " Qatar Central" },
    //     { label: "South Africa North", value: "South Africa North" },
    //     { label: "South Central US", value: "South Central US" },
    //     { label: "South India", value: "South India" },
    //     { label: "Southeast Asia", value: "Southeast Asia" },
    //     { label: "Sweden Central", value: "Sweden Central" },
    //     { label: "Switzerland North", value: "Switzerland North" },
    //     { label: "UAE   North", value: "UAE  North" },
    //     { label: "UK South", value: "UK South" },
    //     { label: "UK West", value: "UK West" },
    //     { label: "West Central US", value: "West Central US" },
    //     { label: "West Europe", value: "West Europe" },
    //     { label: "West India", value: "West India" },
    //     { label: "West US", value: "West US" },
    //     { label: "West US 2", value: "West US 2" },
    //     { label: "West US 3", value: "West US 3" },

    //      ],
    //   Categery: "Generate Page",
    //   Group: "Functions_App_Data",
    //   MinValue: null,
    //   MaxValue: null,
    //   Multiple: 0,
    //   PlaceHolder: "",
    //   Disabled: false,
    // },
    // {
    //   Label: "Operating System  ",
    //   Required: false,
    //   Name: "operatingSystem",
    //   InitialValue: "",
    //   Regex: [],
    //   RegExError: "Please Enter Operating System",
    //   ErrorMessage: "Operating System is required*",
    //   Tooltip: "Select Operating System  ",
    //   HelperText: "",
    //   Type: "select",
    //   List: [
    //     { label: "Linux", value: "linux" },
    //     { label: "Windows", value: "windows" },],
    //   Categery: "App_Page",
    //   Group: "Functions_App__Data",
    //   MinValue: null,
    //   MaxValue: null,
    //   Multiple: 0,
    //   PlaceHolder: "",
    //   Disabled: false,
    // },
    // {
    //   Label: "Hosting Option",
    //   Required: false,
    //   Name: "hostingOption",
    //   InitialValue: "",
    //   Regex: [],
    //   RegExError: "Please Select Hosting Plan",
    //   ErrorMessage: "Hosting Plan is required*",
    //   Tooltip: "Select Pricing Plan ",
    //   HelperText: "",
    //   Type: "select",
    //   List: [
    //     { label: "Consumption", value: "consumption" },
    //     { label: "Functions Premium", value: "functionsPremium" },
    //     { label: "App Service Plan", value: "appServicePlan" },
    //   ],
    //   Categery: "App_Page",
    //   Group: "Functions_App__Data",
    //   MinValue: null,
    //   MaxValue: null,
    //   Multiple: 0,
    //   PlaceHolder: "",
    //   Disabled: false,
    // },
    // {
    //   Label: "Pricing Plan ",
    //   Required: false,
    //   Name: "pricingPlan",
    //   InitialValue: "",
    //   Regex: [],
    //   RegExError: "Please Enter Pricing Plan",
    //   ErrorMessage: "Pricing Plan is required*",
    //   Tooltip: "Select Pricing Plan ",
    //   HelperText: "",
    //   Type: "select",
    //   List: [
    //     { label: "Basic B1 1,031.94 INR/Month (Estimated) ACU:100,Memory:1.75 GB .vCPU:1", value: "Basic B1 1,031.94 INR/Month (Estimated) ACU:100,Memory:1.75 GB ,vCPU:1" },
    //     { label: "Premium V3 P1V3 9,746.13 INR/Month (Estimated) ACU:195,Memory:8 GB .vCPU:2", value: "Premium V3 P1V3 9,746.13 INR/Month (Estimated) ACU:195,Memory:8 GB ,vCPU:2" },
    //     { label: "Premium V3 P2V3 19,492.26 INR/Month (Estimated) ACU:195,Memory:16 GB .vCPU:4", value: "Premium V3 P2V3 19,492.26 INR/Month (Estimated) ACU:195,Memory:16 GB ,vCPU:4" },
    //     { label: "Premium V3 P3V3 38,984.53INR/Month (Estimated) ACU:195,Memory:32 GB .vCPU:8", value: "Premium V3 P3V3 38,984.53INR/Month (Estimated) ACU:195,Memory:32 GB ,vCPU:8" },
    //   ],
    //   Categery: "App_Page",
    //   Group: "Functions_App__Data",
    //   MinValue: null,
    //   MaxValue: null,
    //   Multiple: 0,
    //   PlaceHolder: "",
    //   Disabled: false,
    // },
];
export const appServicePlanList = [
    {
        label: "Basic B1 1,031.94 INR/Month (Estimated) ACU:100,Memory:1.75 GB ,vCPU:1",
        value: "Basic B1 1,031.94 INR/Month (Estimated) ACU:100,Memory:1.75 GB ,vCPU:1"
    },
    { label: "Standard S1  ACU:100,Memory:1.75 GB ,vCPU:1", value: "Standard S1  ACU:100,Memory:1.75 GB ,vCPU:1" },
    {
        label: "Premium V3 P1V3 9,746.13 INR/Month (Estimated) ACU:195,Memory:8 GB ,vCPU:2",
        value: "Premium V3 P1V3 9,746.13 INR/Month (Estimated) ACU:195,Memory:8 GB ,vCPU:2"
    },
    {
        label: "Premium V3 P2V3 19,492.26 INR/Month (Estimated) ACU:195,Memory:16 GB ,vCPU:4",
        value: "Premium V3 P2V3 19,492.26 INR/Month (Estimated) ACU:195,Memory:16 GB ,vCPU:4"
    },
    {
        label: "Premium V3 P3V3 38,984.53INR/Month (Estimated) ACU:195,Memory:32 GB ,vCPU:8",
        value: "Premium V3 P3V3 38,984.53INR/Month (Estimated) ACU:195,Memory:32 GB ,vCPU:8"
    }
];
export const functionList = [
    { label: "Elastic Premium  ACU:210,Memory:3.5 GB ,vCPU:1", value: "Elastic Premium  ACU:210,Memory:3.5 GB ,vCPU:1" },
    { label: "Elastic Premium  ACU:420,Memory:7 GB ,vCPU:2", value: "Elastic Premium  ACU:420,Memory:7 GB ,vCPU:2" },
    { label: "Elastic Premium  ACU:840,Memory:14 GB ,vCPU:4", value: "Elastic Premium  ACU:840,Memory:14 GB ,vCPU:4" }
];
export const FuncAppVmPage: any = [
    {
        Label: "Application Insight",
        Required: true,
        Name: "applicationInsight",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Application Insight",
        ErrorMessage: "  Application Insight is required*",
        Tooltip: "",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
        ],
        Categery: "App_Page",
        Group: "Functions_App__Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Storage Account Name",
        Required: false,
        Name: "storageAccountName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Storage Account Name",
        ErrorMessage: "Storage Account Name is required*",
        Tooltip: "Storage Account Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
    // {
    //   Label: "Enable network injection",
    //   Required: true,
    //   Name: "enableNetworkInjection",
    //   InitialValue: "",
    //   Regex: [],
    //   RegExError: "Please select  network injection type",
    //   ErrorMessage: " network injection type is required*",
    //   Tooltip: "",
    //   HelperText: "",
    //   Type: "select",
    //   List: [
    //     { label: "Yes", value: "true" },
    //     { label: "No", value: "false" },
    //   ],
    //   Categery: "App_Page",
    //   Group: "Functions_App__Data",
    //   MinValue: null,
    //   MaxValue: null,
    //   Multiple: 0,
    //   PlaceHolder: "",
    //   Disabled: false,
    // },
];
