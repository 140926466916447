import React from "react";

import { Box, Paper, Typography } from "@mui/material";

import Navigators from "../ButtonGroups/Navigators";

import { CardComponentProps } from "./types";

const CardComponent: React.FC<CardComponentProps> = ({ cardTitle, children, paperHeight, handleBack, handleNext, buttonText1, buttonType }) => {
    return (
        <Box component="div" width="100%" height="calc(100vh - 100px)" display="flex" justifyContent="center" alignItems="center" marginTop="100px">
            <Paper elevation={3} sx={{ height: paperHeight || "90%", width: "90%", p: 2, pl: 2 }}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    <Box component="div" width="100%" display="flex" flexDirection="column" height="100%">
                        {cardTitle && (
                            <Box component="div">
                                <Typography variant="h5" fontWeight={700} sx={{ textAlign: "center" }}>
                                    {cardTitle}
                                </Typography>
                            </Box>
                        )}
                        {children}
                    </Box>
                    <Box>
                        <Navigators buttonType={buttonType} buttonText1={buttonText1} handleBack={handleBack} handleNext={handleNext} />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};
export default CardComponent;
