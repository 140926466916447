import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import KeyIcon from "@mui/icons-material/Key";
import PasswordIcon from "@mui/icons-material/Password";
import { Box, Grid, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import HoC from "../../../Hoc";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Input from "../../../Hoc/Input";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostServiceLayer } from "../../../Redux/Reducers";

const SelectService = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [selectedOptions, setSelectedOptions] = useState<any>("");
    console.log("select", selectedOptions);
    const { hostServiceLayer } = useAppSelector((state) => state);
    const handleSubmit = () => {
        try {
            if (selectedOptions === en.AzureCache) {
                dispatch(handleHostServiceLayer({ ...hostServiceLayer, serviceName: selectedOptions }));
                navigate(RoutesEnum.servicesAzureCache);
            } else if (selectedOptions === en.ServiceStorageAccount) {
                dispatch(handleHostServiceLayer({ ...hostServiceLayer, serviceName: selectedOptions }));
                navigate(RoutesEnum.servicesStorageAccount);
            }
        } catch (error: any) {
            console.error("Validation error:", error);
            // Show toast message with error message
            toast.error(error.message);
        }
    };

    const handleBack = () => {
        if (!selectedOptions?.AuthorizationType.length) return navigate(-1);
        return setSelectedOptions({ AuthorizationType: "" });
    };

    const Options = [
        {
            label: "Azure Cache For Redis",
            value: "azureCacheForRedis",
            icon: <KeyIcon />
        },

        {
            label: "Storage Account",
            value: "storageAccount",
            icon: <PasswordIcon />
        }
    ];

    return (
        <HoC.CardComponent cardTitle="Admin Details" handleNext={handleSubmit} handleBack={handleBack}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box px={4} py={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Choose Service</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {Options.map((option: { label: string; value: string; icon: any }) => (
                                    <Grid item xs={6} key={option.value}>
                                        <HoC.SelectBox
                                            openDrawer={false}
                                            option={option}
                                            selectedOptions={selectedOptions}
                                            setSelectedOptions={setSelectedOptions}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </HoC.CardComponent>
    );
};

export default SelectService;
