import * as React from "react";
import { useNavigate } from "react-router-dom";

import { AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
// import Button from "@mui/material/Button";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Logo from "../../assets/Logo.png";
import paripoornaLogo from "../../assets/Paripoorna-Logo.png";
import RoutesEnum from "../../enums/Routes.enum";
import SideNavbar from "../../Hoc/Sidenav";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleHost, handleUser } from "../../Redux/Reducers";

import { SideNavMenu } from "./Uitilities";

// import TemporaryDrawer from "./SideNaveBar";

type Anchor = "left";
const Navbar = () => {
    const dispatch = useAppDispatch();
    const {
        userData: { user }
    } = useAppSelector((state) => state);
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        left: !!user
    });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLogOut = () => {
        setAnchorEl(null);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        dispatch(handleUser({ user: null, token: null }));
        navigate("/login");
    };
    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handleLocation = (hostLayer: any) => {
        dispatch(handleHost({ layer: hostLayer }));
    };

    return (
        <>
            <Box>
                <AppBar position="fixed" color="inherit">
                    <Toolbar sx={{ width: "100%", display: "flex" }}>
                        <Box component="div" width="27%" display="flex" height="100px" alignItems="center">
                            {user && (
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={toggleDrawer("left", true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                            {user ? (
                                <img src={Logo} alt="Logo" style={{ width: "150px" }} />
                            ) : (
                                <img src={paripoornaLogo} alt="Logo" style={{ width: "150px" }} />
                            )}
                        </Box>
                        <Box width="70%" display="flex" justifyContent="flex-end">
                            {user ? (
                                <Box component="div">
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle /> <Typography>{user?.email}</Typography>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                        <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
                                    </Menu>
                                </Box>
                            ) : (
                                <Typography style={{ cursor: "pointer" }} onClick={() => navigate(RoutesEnum.login)}>
                                    Sign In
                                </Typography>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <SideNavbar
                state={state}
                logo={SideNavMenu.logo}
                Menus={SideNavMenu.menus}
                setState={setState}
                toggleDrawer={toggleDrawer}
                onChange={(childMenuText: any) => handleLocation(childMenuText)}
            />
        </>
    );
};
export default Navbar;
