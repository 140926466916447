import React from "react";

import { Autocomplete, Box, Chip, TextField, Tooltip } from "@mui/material";

import { SelectMultipleProps } from "./types";

const SelectMultiple: React.FC<SelectMultipleProps> = ({
    id,
    value,
    name,
    label,
    onChange,
    required = false,
    error,
    toolTip,
    helperText,
    defaultValue,
    list,
    errorText
}) => {
    const handleTags = (index: number) => onChange(value?.filter((e, i) => index !== i) || []);

    return (
        <Box paddingY={1} width="100%" gap={2} position="relative">
            <Tooltip title={toolTip || ""} placement="top-start">
                <Autocomplete
                    multiple
                    id={id}
                    value={value || []}
                    defaultValue={defaultValue}
                    options={list || []}
                    onChange={(event, newValue: any) => onChange(newValue)}
                    filterSelectedOptions
                    getOptionDisabled={(option: any) => typeof option === "object" && option?.additional?.disabled}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    renderOption={(props, option, { selected }) => (
                        <Box {...props} component="li">
                            {typeof option === "object" ? option?.label : option}
                        </Box>
                    )}
                    renderTags={(tagValue) => (
                        <Box overflow="clip" width="100%" display="flex">
                            {tagValue.map((option: any, index) => (
                                <Box margin={0.5} key={option.label}>
                                    <Chip
                                        style={{ cursor: "pointer" }}
                                        size="small"
                                        label={option.label || ""}
                                        onDelete={() => handleTags(index)}
                                        variant="outlined"
                                        color="primary"
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            helperText={error ? errorText : helperText}
                            defaultValue={defaultValue}
                            required={required || false}
                            value={value || []}
                            name={name}
                            error={error}
                            label={label}
                        />
                    )}
                />
            </Tooltip>
        </Box>
    );
};

export default SelectMultiple;
