import React from "react";

import { Box, Button } from "@mui/material";

import { buttonStyle } from "../../Theme/Theme";

import { ModalButtonProps } from "./types";

const ModalButtons: React.FC<ModalButtonProps> = ({
    okText,
    cancelText,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disableDelete,
    disableOkBtn,
    disableCancel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDelete,
    onCancel,
    loading,
    onOk
}) => {
    return (
        <Box component="div" marginTop={1.5} display="flex" flexDirection="row" gap={1} width="100%">
            <Box component="div" display="flex" justifyContent="space-between" flexDirection="row" width="100%">
                {cancelText && (
                    <Button variant="outlined" disabled={disableCancel || loading} onClick={onCancel}>
                        {cancelText}
                    </Button>
                )}
                {okText && (
                    <Button
                        disabled={disableOkBtn || loading}
                        style={buttonStyle}
                        type={onOk ? "button" : "submit"}
                        variant="contained"
                        onClick={() => onOk?.()}
                    >
                        {okText}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ModalButtons;
