export const getCrudPageConfig = () => {
    const NumberList = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 100; i++) {
        NumberList.push({ label: `${i}`, value: i });
    }
    const CrudPageFormConfig: any = [
        {
            Label: "Fields",
            Required: false,
            Name: "fields",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter fields",
            ErrorMessage: "fields is required*",
            Tooltip: "Fields",
            HelperText: "",
            Type: "header",
            List: null,
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Field Name",
            Required: true,
            Name: "fieldName",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Field Name",
            ErrorMessage: "Field Name is required*",
            Tooltip: "Field Name",
            HelperText: "",
            Type: "text",
            List: null,
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Field Type",
            Required: true,
            Name: "fieldType",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Field Type",
            ErrorMessage: "Field Type is required*",
            Tooltip: "Field Type",
            HelperText: "",
            Type: "select",
            List: [
                { label: "Text", value: "Text" },
                { label: "Radio", value: "Radio" },
                { label: "Checkbox", value: "Checkbox" },
                // { label: "Toggle", value: "Toggle" },
                { label: "Select", value: "Select" },
                { label: "Date", value: "Date" }
            ],
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Mandatory",
            Required: true,
            Name: "mandatory",
            InitialValue: "",
            Regex: [],
            RegExError: "",
            ErrorMessage: "Mandatory field is Required",
            Tooltip: "",
            HelperText: "",
            Type: "select",
            List: [
                { label: "true", value: true },
                { label: "false", value: false }
            ],
            Categery: "Generate Page",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Field Order",
            Required: true,
            Name: "fieldOrder",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Field Order",
            ErrorMessage: "Field Order is Required",
            Tooltip: "",
            HelperText: "",
            Type: "select",
            List: NumberList,
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Field Options",
            Required: false,
            Name: "options",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Field Options",
            ErrorMessage: "Field Options is Required",
            Tooltip: "Enter Opions separated by comma",
            HelperText: "",
            Type: "text",
            List: null,
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Field Alignment",
            Required: true,
            Name: "alignment",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Field Alignment",
            ErrorMessage: "Field Alignment is required*",
            Tooltip: "Select Field Alignment",
            HelperText: "",
            Type: "select",
            List: [
                { label: "Left", value: "left" },
                { label: "Right", value: "right" },
                { label: "Center", value: "center" }
            ],
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Enable Filter",
            Required: true,
            Name: "enableFilter",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Enable Filters",
            ErrorMessage: "Enable Filters is required*",
            Tooltip: "Enable Filters",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Enable sorting",
            Required: true,
            Name: "enableSorting",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Enable sorting",
            ErrorMessage: "Enable sorting is required*",
            Tooltip: "Enable sorting",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Hide Field",
            Required: false,
            Name: "hideField",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Hide Field",
            ErrorMessage: "Hide Field is required*",
            Tooltip: "Hide Field",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Toolbar",
            Required: false,
            Name: "",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Toolbar",
            ErrorMessage: "Toolbar is required*",
            Tooltip: "Toolbar",
            HelperText: "",
            Type: "header",
            List: null,
            Categery: "GeneratePage",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Position",
            Required: false,
            Name: "toolBarPosition",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Position",
            ErrorMessage: "Position is Required",
            Tooltip: "Toolbar Position",
            HelperText: "",
            Type: "select",
            List: [
                { label: "Top", value: "top" },
                { label: "Bottom", value: "bottom" }
            ],
            Categery: "tableSetting",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Enable Multi Row Selection",
            Required: false,
            Name: "enableMultiRowSelection",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Enable Multi Row Selection",
            ErrorMessage: "Enable Multi Row Selection is required*",
            Tooltip: "Enable Multi Row Selection",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Show Filter Button",
            Required: false,
            Name: "showFilterButton",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Show Filter Button",
            ErrorMessage: "Show Filter Button is required*",
            Tooltip: "Show Filter Button",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Show Download Button",
            Required: false,
            Name: "showDownloadButton",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Show Download Button",
            ErrorMessage: "Show Download Button is required*",
            Tooltip: "Show Download Button",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Show Refresh Button",
            Required: false,
            Name: "showRefreshButton",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Show Refresh Button",
            ErrorMessage: "Show Refresh Button is required*",
            Tooltip: "Show Refresh Button",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "Crud_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        }
    ];
    return CrudPageFormConfig;
};
