/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import useMediaQuery from "@mui/material/useMediaQuery";

import API from "../../../API";
import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import HoC from "../../../Hoc";
import ImageUploadPreview from "../../../Hoc/ImageUploadWithPreview";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { newProjectInitialState } from "../../../Redux/ReducerInitialState";
import { handleDrawerClose, handleDrawerOpen, handleProjects, handleProjectsData } from "../../../Redux/Reducers";
import { base64File, downloadJsonFile } from "../../../ReusableCodes/Resuseablefunctions";
import { appFontOptions, navOptions } from "../Uitilities";

import { buttonsOptionsType, toastConfigType } from "./types";
import { ToastConfigOptions } from "./utilities";

const BrandingAndLogo = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const MaxFileSize = 1 * 1024 * 1024;
    const { newProject, projectData } = useAppSelector((state) => state);
    console.log("newProject: ", { newProject });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [toastConfig, setToastConfig] = useState<toastConfigType>({
        position: "top-right",
        type: "success",
        theme: "light"
    });
    const [base64Image, setBase64Image] = useState<string>(newProject?.projectDetails?.base64Logo || "");
    const [base64FavIcon, setBase64FavIcon] = useState<string>(newProject?.projectDetails?.faviIconBase64Image || "");
    const [theme, setTheme] = useState<string>(newProject?.projectDetails?.themeColor || "");
    const [Navbar, setNavbar] = useState({ key: "", value: "" });
    const [downloadJson, setDownloadJson] = useState<boolean>(false);
    const [selectedButton, setSelectedButton] = useState<"secondary" | "primary">("secondary");
    const [appFont, setAppFont] = useState<string>(newProject?.projectDetails?.appFont || "");

    const [buttons, setbuttons] = useState<{
        secondary: buttonsOptionsType;
        primary: buttonsOptionsType;
    }>({
        secondary: {
            color: "#ab47bc",
            backgroundColor: "#0000",
            variant: "contained"
        },
        primary: {
            color: "#5468ff",
            backgroundColor: "#0000",
            variant: "outlined"
        }
    });
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    console.log({ buttons });
    console.log({ selectedButton });
    const handleButtonClick = (buttonType: "secondary" | "primary") => {
        setDownloadJson(false);
        setSelectedButton(buttonType);
        dispatch(handleDrawerOpen({ open: true, type: "BUTTON_EDIT" }));
    };
    const handleDrawerSaveOnClose = () => {
        setDownloadJson(false);
        dispatch(handleDrawerClose());
    };
    const handleButtonFontColorChange = (color: string) => {
        setDownloadJson(false);
        setbuttons((prev) => ({ ...prev, [selectedButton]: { ...prev[selectedButton], color } }));
    };
    const handleButtonBackgroundColorChange = (color: string) => {
        setDownloadJson(false);
        setbuttons((prev) => ({ ...prev, [selectedButton]: { ...prev[selectedButton], backgroundColor: color } }));
    };
    const handleButtonVariantColorChange = (variant: string) => {
        setDownloadJson(false);
        setbuttons((prev) => ({ ...prev, [selectedButton]: { ...prev[selectedButton], variant } }));
    };

    // eslint-disable-next-line consistent-return
    const handleLogoChange = async (e: any) => {
        setDownloadJson(false);
        if (e) {
            if (e.size > MaxFileSize) {
                return toast.error(en.filesize);
            }
            base64File(e, setBase64Image);
        }
    };

    // eslint-disable-next-line consistent-return
    const handleFavIconChane = async (e: any) => {
        setDownloadJson(false);
        if (e) {
            if (e.size > MaxFileSize) {
                return toast.error(en.filesize);
            }
            base64File(e, setBase64FavIcon);
        }
    };

    const handleToastConfigChange = (e: any) => {
        setDownloadJson(false);
        setToastConfig((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleShowToast = () => {
        setDownloadJson(false);
        toast[toastConfig.type](" Welcome to NimbusJS", {
            position: toastConfig.position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: toastConfig.theme
        });
    };

    const handleOptionsSubmit = async () => {
        if (base64Image === "") return toast.error("Please upload a logo.");
        if (base64FavIcon === "") return toast.error("Please upload a FavIcon.");
        if (Navbar.key === "" && Navbar.value === "") return toast.error("Please select a menu.");
        if (appFont === "") return toast.error("Please select a font.");
        dispatch(
            handleProjects({
                projectDetails: {
                    ...newProject.projectDetails,
                    base64Logo: base64Image,
                    faviIconBase64Image: base64FavIcon,
                    themeColor: theme,
                    appFont,
                    Navbar,
                    buttons,
                    toastConfig
                }
            })

            // update navbar component
        );
        toast.success("Data has been submitted; please download the JSON.");
        setDownloadJson(true);
        return true;
    };
    const handleDownload = async () => {
        if (newProject?._id) {
            const res: any = await API.Projects.updateProjects(newProject);
            downloadJsonFile(res?.data, `${newProject?.projectDetails?.projectName}`);
        } else {
            const response: any = await API.Projects.createProjects(newProject);
            downloadJsonFile(response?.data, `${newProject?.projectDetails?.projectName}`);
            dispatch(handleProjectsData([...projectData, response?.data]));
        }
        dispatch(handleProjects(newProjectInitialState));
        navigate(RoutesEnum.manageProject);
    };
    const handleNext = () => {
        handleOptionsSubmit();
    };
    const handleBack = () => {
        navigate(RoutesEnum.appComponents);
    };
    return (
        <HoC.CardComponent
            cardTitle="Configure Your Brand"
            buttonText1={downloadJson ? en.downloadjson : "Submit"}
            handleBack={handleBack}
            handleNext={downloadJson ? handleDownload : handleNext}
        >
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" sx={{ height: mediaQuery ? "370px" : "100%" }}>
                <Box px={4} py={2} height="100%">
                    <Grid container spacing={4} height="auto" sx={{ justifyContent: "center" }}>
                        <Grid item xs={2.4} height="100%" sx={{ paddingLeft: "15px!important" }}>
                            <Box
                                className="box-shadow"
                                sx={{
                                    boxShadow: "0px 2px 10px 5px rgba(0,0,0,0.1)",
                                    borderRadius: "10px",
                                    flex: "1",
                                    p: 2,
                                    height: "23vw"
                                }}
                            >
                                <Grid container sx={{ flexDirection: "column", height: "100%" }}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <Typography fontSize="16px" fontWeight="bold">
                                                Logo
                                            </Typography>
                                            <ImageUploadPreview handleImage={handleLogoChange} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <Typography fontSize="16px" fontWeight="bold">
                                                FavIcon
                                            </Typography>
                                            <ImageUploadPreview handleImage={handleFavIconChane} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={3} height="100%" sx={{ paddingLeft: "15px!important" }}>
                            <Box
                                sx={{
                                    boxShadow: "0px 2px 10px 5px rgba(0,0,0,0.1)",
                                    borderRadius: "10px",
                                    flex: "1",
                                    p: 2,
                                    height: "23vw"
                                }}
                            >
                                <Typography fontSize="16px" fontWeight="bold">
                                    {en.themecolor}
                                </Typography>
                                <Box sx={{ height: mediaQuery ? "225px" : "100%", width: "100%", textAlign: "-webkit-center" }}>
                                    <ChromePicker
                                        className="custom-chrome-picker-brand"
                                        color={theme}
                                        onChange={(color: any) => setTheme(color.hex)}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2.5} height="100%" sx={{ paddingLeft: "15px!important" }}>
                            <Box
                                sx={{
                                    height: "23vw"
                                }}
                            >
                                <Box sx={{ display: "flex", height: "100%", flexDirection: "column", gap: 1 }}>
                                    <Box
                                        sx={{
                                            boxShadow: "0px 2px 10px 5px rgba(0,0,0,0.1)",
                                            borderRadius: "10px",
                                            flex: "1",
                                            p: 2,
                                            paddingBottom: "7px",
                                            paddingTop: "9px"
                                        }}
                                    >
                                        <Box>
                                            <Typography fontSize="16px" fontWeight="bold">
                                                Menu
                                            </Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            {navOptions.map((item: any) => (
                                                <HoC.IconButtonComp
                                                    item={item}
                                                    navbar={Navbar}
                                                    handleIcon={() => setNavbar({ key: "NavBar", value: item.option })}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            boxShadow: "0px 2px 10px 5px rgba(0,0,0,0.1)",
                                            borderRadius: "10px",
                                            flex: "1",
                                            p: 2,
                                            paddingTop: "9px"
                                        }}
                                    >
                                        <Typography fontSize="16px" fontWeight="bold">
                                            {en.appfront}
                                        </Typography>
                                        <FormControl fullWidth sx={{ marginTop: "4px" }}>
                                            <InputLabel id="demo-simple-select-label">Select Font</InputLabel>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={appFont}
                                                defaultValue={appFont}
                                                onChange={(e) => setAppFont(e.target.value as string)}
                                                label="App Font"
                                                variant="outlined"
                                                size="small"
                                                sx={{ marginTop: 1 }}
                                            >
                                                {appFontOptions.map((font) => (
                                                    <MenuItem key={font} value={font}>
                                                        {font}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box
                                        sx={{
                                            boxShadow: "0px 2px 10px 5px rgba(0,0,0,0.1)",
                                            borderRadius: "10px",
                                            flex: "1",
                                            p: 2,
                                            paddingTop: "9px"
                                        }}
                                    >
                                        <Grid container spacing={2} flexDirection="row" justifyContent="center">
                                            <Grid item xs={12}>
                                                <Typography fontSize="16px" fontWeight="bold">
                                                    Buttons
                                                </Typography>
                                            </Grid>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "8px",
                                                    alignItems: "flex-start"
                                                }}
                                            >
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant={buttons.primary.variant}
                                                        onClick={() => handleButtonClick("primary")}
                                                        sx={{ color: buttons.primary.color, backgroundColor: buttons.primary.backgroundColor }}
                                                    >
                                                        Primary
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant={buttons.secondary.variant}
                                                        onClick={() => handleButtonClick("secondary")}
                                                        sx={{ color: buttons.secondary.color, backgroundColor: buttons.secondary.backgroundColor }}
                                                    >
                                                        Secondary
                                                    </Button>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {/* Toast Conatiner */}
                        <Grid item xs={4} sx={{ height: "100%", paddingLeft: "15px!important" }}>
                            <Box
                                sx={{
                                    boxShadow: "0px 2px 10px 5px rgba(0,0,0,0.1)",
                                    borderRadius: "10px",
                                    flex: "1",
                                    p: 2,
                                    height: "23vw"
                                }}
                            >
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography fontSize="16px" fontWeight="bold">
                                            Toast
                                        </Typography>
                                        <Button variant="outlined" sx={{ padding: "5px 10px" }} className="glow-button" onClick={handleShowToast}>
                                            <Typography variant="button">Show Toast</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        {ToastConfigOptions.map((item, i) => (
                                            <Grid item xs={12} sx={{ paddingTop: i !== 0 ? "18px !important" : "auto" }}>
                                                <FormControl fullWidth>
                                                    <FormLabel id="demo-radio-buttons-group-label">
                                                        {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        value={toastConfig[item.title]}
                                                        name={item.title.toLocaleLowerCase()}
                                                        onChange={handleToastConfigChange}
                                                    >
                                                        <Grid container width="100%">
                                                            {item.options.map((option) => (
                                                                <Grid item xs={6}>
                                                                    <FormControlLabel
                                                                        value={option.value}
                                                                        control={
                                                                            <Radio
                                                                                sx={{
                                                                                    "& .MuiSvgIcon-root": {
                                                                                        fontSize: mediaQuery ? 10 : 18
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={option.label}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                                {ToastConfigOptions.length - (i + 1) !== 0 ? <hr className="line" /> : null}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <HoC.CustomDrawer
                title={`${selectedButton.slice(0, 1).toLocaleUpperCase() + selectedButton.slice(1, selectedButton.length)} Button`}
                type="BUTTON_EDIT"
                handleDrawerOnClose={handleDrawerSaveOnClose}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Font Color</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ChromePicker
                                    color={buttons[selectedButton].color}
                                    onChange={(color: any) => handleButtonFontColorChange(color.hex)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Background Color</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ChromePicker
                                    color={buttons[selectedButton].backgroundColor}
                                    onChange={(color: any) => {
                                        handleButtonBackgroundColorChange(color.hex);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Variant</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    value={buttons[selectedButton].variant}
                                    label="Button Variant"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handleButtonVariantColorChange(e.target.value)}
                                >
                                    <MenuItem value="contained">Contained</MenuItem>
                                    <MenuItem value="outlined">Outlined</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </HoC.CustomDrawer>
        </HoC.CardComponent>
    );
};

export default BrandingAndLogo;
