import React, { useState } from "react";
import { toast } from "react-toastify";

import { Backdrop, Box, Button } from "@mui/material";

import API from "../../API";
import RoutesEnum from "../../enums/Routes.enum";
import { buttonStyle, disabled, Spinner } from "../../Theme/Theme";

interface CloningSourceCodeProps {
    jsonData: any; // Replace 'any' with a more specific type if possible
}

const CloningSourceCode: React.FC<CloningSourceCodeProps> = ({ jsonData }) => {
    const [loading, setLoading] = useState(false);
    const [isCloneProject, setIsCloneProject] = useState(false);

    const data = {
        cloningfolder: "Code-repo-code",
        destination: "destination",
        subfolderName: "Source-Code",
        jsonData
    };
    const handleClick = async () => {
        setLoading(true);
        if (!jsonData) return;

        try {
            const response: any = await API.Projects.cloneFolder(data);
            console.log("response", response);

            // if (jsonData.pages.length > 0) {
            //   await axiosPrivate.post(`${"editdataformswagger"}`, jsonData);
            // }
            if (response?.success) {
                setTimeout(() => {
                    setIsCloneProject(true);
                    setLoading(false);
                    toast.success("Data has been submitted; please download the file.");
                }, 8000);
            }
        } catch (error) {
            // handleClick();
            console.error("Error cloning file:", error);
            setLoading(false);
        }
    };

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/download-zipfile`);
            const blob = await response?.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${jsonData?.projectDetails?.projectName}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setLoading(false);
            window.location.reload();
        } catch (error) {
            console.error("Error downloading ZIP archive:", error);
            setLoading(false);
        }
    };
    const spinner = () => {
        return (
            <Backdrop open={loading}>
                <Spinner loading={loading} />
            </Backdrop>
        );
    };

    return (
        <Box
            component="div"
            width="100%"
            display="flex"
            justifyContent={window.location.pathname === RoutesEnum.generateProject ? "center" : "flex-end"}
        >
            {loading ? (
                spinner?.()
            ) : (
                <div>
                    {isCloneProject ? (
                        <Button onClick={handleDownload} style={buttonStyle}>
                            Download Code
                        </Button>
                    ) : (
                        <Button
                            variant={jsonData ? "contained" : "outlined"}
                            onClick={handleClick}
                            style={jsonData ? buttonStyle : disabled}
                            disabled={!jsonData}
                        >
                            Create File
                        </Button>
                    )}
                </div>
            )}
        </Box>
    );
};

export default CloningSourceCode;
