import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import { en } from "../../../dictionary/en";
import HoC from "../../../Hoc";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handleHostApiLayer, handleHostUi } from "../../../Redux/Reducers";
import { PrefixAdder, validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";
import PlanAppServicePage from "../AppService-Plan";

import { WebAppPlan } from "./Utilities";

const WebAppPlanPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hostLayerType, hostUi, hostApiLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;

    const formikProps = useFormik({
        initialValues: {
            ...WebAppPlan.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values) => {
            console.log("jj", values);
            if (layer === en.isUiLayer)
                dispatch(
                    handleHostUi({
                        webAppServices: {
                            ...hostUi.webAppServices,
                            webAppName: values?.webAppName,
                            webAppRegion: values?.webAppRegion?.value,
                            runTimeStack: values?.runTimeStack?.value,
                            operatingSystem: values?.operatingSystem?.value,
                            publish: values?.publish?.value,
                            gitBranchName: values?.gitBranchName,
                            gitUrl: values?.gitUrl
                        }
                    })
                );
            else if (layer === en.isApiLayer)
                dispatch(
                    handleHostApiLayer({
                        webAppServices: {
                            ...hostApiLayer.webAppServices,
                            webAppName: values?.webAppName,
                            webAppRegion: values?.webAppRegion?.value,
                            runTimeStack: values?.runTimeStack?.value,
                            operatingSystem: values?.operatingSystem?.value,
                            publish: values?.publish?.value,
                            gitBranchName: values?.gitBranchName,
                            gitUrl: values?.gitUrl
                        }
                    })
                );
            dispatch(handleDrawerOpen({ open: true, type: "APP_SERVICES" }));
        },
        validate: (values) => validateFormOnSubmit(values, [WebAppPlan])
    });

    // Use useEffect to pre-fill the form with host values
    useEffect(() => {
        if (layer === en.isUiLayer) {
            formikProps.setValues({
                ...formikProps.values,
                ...WebAppPlan.reduce((acc: any, field: any) => {
                    if (hostUi[field.Name] !== undefined) {
                        acc[field.Name] = hostUi[field.Name];
                    }
                    return acc;
                }, {})
            });
        } else if (layer === en.isApiLayer) {
            formikProps.setValues({
                ...formikProps.values,
                ...WebAppPlan.reduce((acc: any, field: any) => {
                    if (hostApiLayer[field.Name] !== undefined) {
                        acc[field.Name] = hostApiLayer[field.Name];
                    }
                    return acc;
                }, {})
            });
        }
    }, [hostUi, hostApiLayer]);

    const handleBack = () => {
        navigate(-1);
    };
    const webAppName = formikProps?.values?.webAppName;
    formikProps.values.webAppName = PrefixAdder(webAppName, en.webAppPrefix);
    return (
        <HoC.CardComponent cardTitle="Web App Services" handleBack={handleBack} handleNext={formikProps.handleSubmit}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box px={4} py={2}>
                    <GenerateForm FormData={WebAppPlan} FormikProps={formikProps} />
                </Box>
            </Box>
            <HoC.CustomDrawer
                title="Web App"
                type="APP_SERVICES"
                handleDrawerOnClose={() => {
                    dispatch(handleDrawerClose());
                }}
            >
                <PlanAppServicePage />
            </HoC.CustomDrawer>
        </HoC.CardComponent>
    );
};

export default WebAppPlanPage;
