import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Box, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import SaveButton from "../../../Hoc/Button/SaveButton";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../../Redux/Reducers";
import { validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { SelectPort } from "./Utilities";

const PortSelection = ({ setIsAllSaved, isAllSaved }: any) => {
    const dispatch = useAppDispatch();
    const { hostLayerType, hostUi, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;
    const isDbLayer = layer === en.isDbLayer;
    const [afterSave, setAfterSave] = useState(false);

    const updatedSelectPort = SelectPort.map((field: any) => {
        if (field.Name === "publicInboundPorts") {
            return {
                ...field,
                List: isDbLayer ? field.List.slice(3, 5) : field.List.slice(0, 4)
            };
        }
        return field;
    });

    // Initialize initialValues based on layer
    const initialValues = (() => {
        if (layer === en.isUiLayer) {
            return { ...hostUi?.virtualMachine };
        }
        if (layer === en.isApiLayer) {
            return { ...hostApiLayer?.virtualMachine };
        }
        if (layer === en.isDbLayer) {
            return { ...hostDbLayer?.virtualMachine };
        }
        return {};
    })();

    const formikProps = useFormik({
        initialValues: {
            ...updatedSelectPort.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a })),
            ...initialValues // Merge values based on layer here
        },
        onSubmit: async (values: any) => {
            if (layer === en.isUiLayer)
                dispatch(
                    handleHostUi({
                        virtualMachine: {
                            ...hostUi.virtualMachine,
                            publicInboundPorts: values?.publicInboundPorts
                        }
                    })
                );
            else if (layer === en.isApiLayer)
                dispatch(
                    handleHostApiLayer({
                        virtualMachine: {
                            ...hostApiLayer?.virtualMachine,
                            publicInboundPorts: values?.publicInboundPorts
                        }
                    })
                );
            else if (layer === en.isDbLayer)
                dispatch(
                    handleHostDbLayer({
                        virtualMachine: {
                            ...hostDbLayer.virtualMachine,
                            publicInboundPorts: values?.publicInboundPorts
                        }
                    })
                );
            setAfterSave(true);
        },
        validate: (values) => {
            setAfterSave(false);
            validateFormOnSubmit(values, [updatedSelectPort]);
        }
    });
    useEffect(() => {
        isAllSaved.PortSelection = afterSave;
        setIsAllSaved({ ...isAllSaved });
    }, [afterSave]);
    return (
        <Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h6" marginTop="5px">
                    Port Details
                </Typography>

                <Box px={0.1}>
                    <GenerateForm FormData={updatedSelectPort} FormikProps={formikProps} />
                </Box>
            </Box>

            <Box sx={{ display: afterSave ? "none" : "inline" }}>
                <SaveButton handleSubmit={formikProps.handleSubmit} />
            </Box>
        </Box>
    );
};

export default PortSelection;
