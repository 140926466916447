import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button } from "@mui/material";

import Logo from "../../assets/Logo.png";
import HoC from "../../Hoc";
import { UserLogin } from "../../Redux/AsyncThunk";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { buttonStyle } from "../../Theme/Theme";

import { LoginProps } from "./Uitilities";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        userData: { user }
    } = useAppSelector((state) => state);

    const validationSchema = Yup.object({
        password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
        email: Yup.string().email("Invalid email address").required("Email is required")
    });

    const formikProps = useFormik({
        initialValues: {
            ...LoginProps.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const userRes: any = await dispatch(UserLogin(values));
                if (userRes?.payload?.data) {
                    navigate("/");
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.message);
            }
        }
    });
    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [navigate, user]);

    return (
        <form onSubmit={formikProps.handleSubmit}>
            <HoC.CardComponent>
                <Box display="flex" justifyContent="center" width="100%" height="100%" alignItems="center">
                    <Box
                        sx={{
                            boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            padding: "5px"
                        }}
                    >
                        <Box
                            component="div"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height="60%"
                            width="90%"
                        >
                            <Box width="100%" display="flex" justifyContent="center">
                                <img src={Logo} alt="Logo" style={{ width: "130px", height: "100px" }} />
                            </Box>
                            {/* input Fields */}
                            <Box my={2} width="100%">
                                <HoC.GenerateForm FormData={LoginProps} FormikProps={formikProps} />
                            </Box>
                            {/* forgot Password Link */}
                            <Box
                                my={2}
                                component="a"
                                href="#forgotPasswors"
                                sx={{
                                    color: "#303f9f",
                                    textDecoration: "none"
                                }}
                            >
                                Forgot Password?
                            </Box>
                            <Button variant="contained" fullWidth style={buttonStyle} type="submit" sx={{ my: 2 }}>
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </HoC.CardComponent>
        </form>
    );
};

export default Login;
