import React from "react";
import { MutatingDots } from "react-loader-spinner";

import { Backdrop, Box } from "@mui/material";

export const buttonStyle: React.CSSProperties = {
    backgroundColor: "#303f9f",
    color: "white",
    textTransform: "none",
    padding: window.innerWidth <= 1750 ? "10px 20px" : "20px 30px"
};
export const NextButtonStyle: React.CSSProperties = {
    backgroundColor: "#303f9f",
    color: "white",
    textTransform: "uppercase",
    padding: window.innerWidth <= 1750 ? "10px 20px" : "20px 30px"
};
export const BackButtonStyle: React.CSSProperties = {
    textTransform: "uppercase",
    padding: window.innerWidth <= 1750 ? "10px 20px" : "20px 30px"
};
export const unSelectedButton: React.CSSProperties = {
    backgroundColor: "#d4d4eb",
    color: "black",
    textTransform: "none",
    padding: "10px",
    border: "1px solid #bbb9b9",
    fontSize: window.innerWidth <= 1750 ? "1.1rem" : "1.5rem"
};
export const textWhite: React.CSSProperties = {
    color: "white"
};

export const mediumText: React.CSSProperties = {
    fontSize: window.innerWidth <= 1750 ? "1.1rem" : "1.5rem"
};
export interface SpinnerProps {
    loading: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ loading }) => {
    return (
        <Backdrop open={loading}>
            <Box component="div" width="100%" height="70vh" display="flex" justifyContent="center" alignItems="center">
                <MutatingDots
                    height={100}
                    width={100}
                    color="#eb7023"
                    secondaryColor="#303f9f"
                    radius={12.5}
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loading}
                />
            </Box>
        </Backdrop>
    );
};

export const disabled: React.CSSProperties = {
    backgroundColor: "white"
};
export const imageStyle = {
    width: "130px",
    height: "120px"
};
export const containerStyle = {
    minHeight: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
