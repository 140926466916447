export const UPDATE_LOGIN_TYPE = "UPDATE_LOGIN_TYPE";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PAGE = "CREATE_PAGE";
export const CREATE_CUSTOM_PROJECT = "CREATE_CUSTOM_PROJECT";
export const UPDATE_PROJECT_DETAILS = "UPDATE_PROJECT_DETAILS";
export const UPDATE_TECHNOLOGY = "UPDATE_TECHNOLOGY";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";

export const UPDATE_PROJECT_DETAILS_FROM_JSON =
  "UPDATE_PROJECT_DETAILS_FROM_JSON";
export const UPDATE_TECHNOLOGY_FROM_JSON = "UPDATE_TECHNOLOGY_FROM_JSON";
export const UPLOAD_PAGE_JSON = "UPLOAD_PAGE_JSON";
export const CLEAR_PROJECT_STATE = "CLEAR_PROJECT_STATE";
export const UPDATE_DEFAULT_CHECKBOX='UPDATE_DEFAULT_CHECKBOX'
export const LOGIN = 'LOGIN'
export const LAYER_HOST='LAYER_HOST'
export const CREATE_HOST='CREATE_HOST'
export const CREATE_HOST_API='CREATE_HOST_API'
export const UILAYER_IAAS='UILAYER_IAAS'
export const APILAYER_IAAS='APILAYER_IAAS'
export const DBLAYER_IAAS='DBLAYER_IAAS'
