import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import HoC from "../../../Hoc";
import NextButton from "../../../Hoc/Button/NextButton";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../../Redux/Reducers";
import { PrefixAdder, validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { AppServicePlanPage } from "./Utilities";
import AdminDetailsPage from "./VmAdmin";
import DiskStorage from "./VmDiskStorage";
import PortSelection from "./VmPort";
import SelectVnetPage from "./VmVirtualNetwork";

const AppServicePlan = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isAllSaved, setIsAllSaved] = useState({
        AdminDetailsPage: false,
        PortSelection: false,
        DiskStorage: false,
        SelectVnetPage: false
    });
    const [show, setShow] = useState(false);
    const { hostLayerType, hostUi, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const layer = hostLayerType?.layer;

    //  initial values based on hostUi, hostApiLayer, or hostDbLayer
    const initialValues = {
        ...AppServicePlanPage.reduce((result: any, item: any) => {
            if (layer === en.isUiLayer && hostUi) {
                result[item.Name] = hostUi[item.Name] || item.InitialValue;
            } else if (layer === en.isApiLayer && hostApiLayer) {
                result[item.Name] = hostApiLayer[item.Name] || item.InitialValue;
            } else if (layer === en.isDbLayer && hostDbLayer) {
                result[item.Name] = hostDbLayer[item.Name] || item.InitialValue;
            } else {
                result[item.Name] = item.InitialValue;
            }
            return result;
        }, {})
    };
    const formikProps = useFormik({
        initialValues,

        onSubmit: async (values) => {
            if (layer === en.isUiLayer)
                dispatch(
                    handleHostUi({
                        virtualMachine: {
                            ...hostUi.virtualMachine,
                            VmName: values.VmName,
                            region: values.region.value,
                            image: values.image.value,
                            size: values.size.value
                        }
                    })
                );
            else if (layer === en.isApiLayer)
                dispatch(
                    handleHostApiLayer({
                        virtualMachine: {
                            ...hostApiLayer.virtualMachine,
                            VmName: values.VmName,
                            region: values.region.value,
                            image: values.image.value,
                            size: values.size.value
                        }
                    })
                );
            else if (layer === en.isDbLayer)
                dispatch(
                    handleHostDbLayer({
                        virtualMachine: {
                            ...hostDbLayer.virtualMachine,
                            VmName: values.VmName,
                            region: values.region.value,
                            image: values.image.value,
                            size: values.size.value
                        }
                    })
                );
            dispatch(handleDrawerOpen({ open: true, type: "VM_PLAN" }));
        },
        validate: (values) => validateFormOnSubmit(values, [AppServicePlanPage])
    });

    const handleBack = () => {
        navigate(-1);
    };
    const handleNext = () => {
        navigate(RoutesEnum.vmBiscript);
    };
    useEffect(() => {
        if (isAllSaved.AdminDetailsPage && isAllSaved.PortSelection && isAllSaved.DiskStorage && isAllSaved.SelectVnetPage) {
            setShow(true);
        }
    }, [isAllSaved]);
    const VirtualName = formikProps?.values?.VmName;
    formikProps.values.VmName = PrefixAdder(VirtualName, en.vmPrefix);
    return (
        <HoC.CardComponent cardTitle="Select App Type" handleBack={handleBack} handleNext={formikProps.handleSubmit}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box px={4} py={2}>
                    <GenerateForm FormData={AppServicePlanPage} FormikProps={formikProps} />
                </Box>
            </Box>
            <HoC.CustomDrawer
                title="App Type"
                type="VM_PLAN"
                handleDrawerOnClose={() => {
                    dispatch(handleDrawerClose());
                }}
            >
                <AdminDetailsPage setIsAllSaved={setIsAllSaved} isAllSaved={isAllSaved} />
                <hr className="line" />
                <PortSelection setIsAllSaved={setIsAllSaved} isAllSaved={isAllSaved} />
                <hr className="line" />
                <DiskStorage setIsAllSaved={setIsAllSaved} isAllSaved={isAllSaved} />
                <hr className="line" />
                <SelectVnetPage setIsAllSaved={setIsAllSaved} isAllSaved={isAllSaved} />
                <Box sx={{ textAlign: "end", display: show ? "block" : "none" }}>
                    <NextButton handleClick={handleNext} />
                </Box>
            </HoC.CustomDrawer>
        </HoC.CardComponent>
    );
};

export default AppServicePlan;
