import React, { useState } from "react";
import JSZip from "jszip";
import { toast } from "react-toastify";

import { Box, Button } from "@mui/material";

import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { buttonStyle, disabled, Spinner } from "../../Theme/Theme";

import { DownloadCodeProps } from "./types";

const DownloadCode: React.FC<DownloadCodeProps> = ({ jsonData }) => {
    const [loading, setLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [route, setEndPoint] = useState<string>("");
    const [isCloneProject, setIsCloneProject] = useState(false);

    // eslint-disable-next-line consistent-return
    const handleClick = async () => {
        if (!jsonData) return toast.error("upload json");
        setLoading(true);
        try {
            setEndPoint(jsonData?.pageType === "DataForm" ? "test" : "generatelist");

            const response = await axiosPrivate.post(`${jsonData?.pageType === "DataForm" ? "test" : "generatelist"}`, jsonData);
            if (jsonData?.pageType === "DataForm") {
                await axiosPrivate.post(`${"editdataformswagger"}`, jsonData);
            }
            if (response) {
                setTimeout(() => {
                    setIsCloneProject(true);
                    setLoading(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error cloning file:", error);
            setLoading(false);
        }
    };
    const handleDownload = async () => {
        setLoading(true);
        try {
            const downloadEndPoint = route === "test";
            let response: any;

            let sqlScriptResponse: any;
            let sqlScriptListPageResponse: any;
            let swaggerResponse: any;
            if (downloadEndPoint) {
                response = await fetch(`${process.env.REACT_APP_API_BASEURL}/download-form`);
                sqlScriptResponse = await fetch(`${process.env.REACT_APP_API_BASEURL}/sqlscriptdowload`);
            } else {
                response = await fetch(`${process.env.REACT_APP_API_BASEURL}/download-file`);
                sqlScriptListPageResponse = await fetch(`${process.env.REACT_APP_API_BASEURL}/sqlscriptdowloadlistpage`);
            }
            const apiResponse = await fetch(`${process.env.REACT_APP_API_BASEURL}/apidowload`);
            const scriptResponse = await fetch(`${process.env.REACT_APP_API_BASEURL}/scriptdowload`);
            if (jsonData?.pageType === "DataForm") {
                swaggerResponse = await fetch(`${process.env.REACT_APP_API_BASEURL}/swaggerdowload`);
            }
            const dataDictionary = await fetch(`${process.env.REACT_APP_API_BASEURL}/dictionarydowload`);
            const jestresponse = await fetch(`${process.env.REACT_APP_API_BASEURL}/jestdowload`);
            const bloblistpagesqlscript = await sqlScriptListPageResponse?.blob();
            const blob = await response.blob();
            const blobapi = await apiResponse.blob();
            const blobscript = await scriptResponse.blob();
            const zip = new JSZip();
            if (jsonData?.pageType === "DataForm") {
                const blobsqlscript = await sqlScriptResponse.blob();
                zip.file(`${jsonData?.pageName}-sql-script`, blobsqlscript);
                const blobswagger = await swaggerResponse.blob();
                zip.file(`${jsonData?.pageName}-swagger.json`, blobswagger);
            }
            const blobdatadictionary = await dataDictionary.blob();
            const jestblob = await jestresponse.blob();
            zip.file(`${jsonData?.pageName}.tsx`, blob);
            zip.file(`${jsonData?.pageName}-api.ts`, blobapi);
            zip.file(`${jsonData?.pageName}-script`, blobscript);
            if (bloblistpagesqlscript) {
                zip.file(`${jsonData?.pageName}-sql-script`, bloblistpagesqlscript);
            }

            zip.file(`${jsonData?.pageName}-datadictioanry.docx`, blobdatadictionary);
            zip.generateAsync({ type: "blob" })
                .then(() => {
                    const zip2 = new JSZip();
                    zip2.loadAsync(jestblob).then((zip2Contents) => {
                        Object.keys(zip2Contents.files).forEach((filename) => {
                            zip.file(filename, zip2Contents.files[filename].async("uint8array"));
                        });
                        zip.generateAsync({ type: "blob" }).then((mergedZipBlob) => {
                            const mergedZipUrl = URL.createObjectURL(mergedZipBlob);

                            // Create a download link for the merged zip folder
                            const link = document.createElement("a");
                            link.href = mergedZipUrl;
                            link.setAttribute("download", `${jsonData?.pageName}_merged.zip`);

                            // Append the link to the document body and trigger the download
                            document.body.appendChild(link);
                            link.click();

                            // Clean up by revoking the object URL
                            URL.revokeObjectURL(mergedZipUrl);
                        });
                    });
                })
                .catch((error) => {
                    console.error("Error:", error);
                });

            setLoading(false);
            setIsCloneProject(false);
        } catch (error) {
            console.error("Error downloading ZIP archive:", error);
            setLoading(false);
        }
    };

    return (
        <Box>
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <Box component="div">
                    {isCloneProject ? (
                        <Button onClick={handleDownload} style={buttonStyle}>
                            Download Code
                        </Button>
                    ) : (
                        <Button
                            variant={jsonData ? "contained" : "outlined"}
                            onClick={handleClick}
                            style={jsonData ? buttonStyle : disabled}
                            disabled={!jsonData}
                        >
                            Create File
                        </Button>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default DownloadCode;
