import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { Container, Paper } from "@mui/material";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import Navigators from "../../../Hoc/ButtonGroups/Navigators";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostDbLayer } from "../../../Redux/Reducers";
import { PrefixAdder, validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { AzureSqlDb } from "./Utilities";

const DbSqlFunction = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { hostDbLayer } = useAppSelector((state) => state);
    const formikProps = useFormik({
        initialValues: {
            ...AzureSqlDb.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values) => {
            dispatch(handleHostDbLayer({ ...hostDbLayer, ...values }));
            navigate(RoutesEnum.vmBiscript);
        },
        validate: (values) => validateFormOnSubmit(values, [AzureSqlDb])
    });

    const handleBack = () => {
        navigate(-1);
    };
    const ServerName = formikProps?.values?.serverName;
    formikProps.values.serverName = PrefixAdder(ServerName, en.dbSqlPrefix);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh"
            }}
        >
            <Container maxWidth="md">
                <Paper
                    style={{
                        padding: "16px"
                    }}
                    elevation={3}
                >
                    <h3>Select App Type</h3>
                    <GenerateForm FormData={AzureSqlDb} FormikProps={formikProps} />
                    <Navigators handleBack={handleBack} handleNext={formikProps.submitForm} disableNext={false} />
                </Paper>
            </Container>
        </div>
    );
};

export default DbSqlFunction;
