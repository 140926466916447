const NumberList = [];
// eslint-disable-next-line no-plusplus
for (let i = 1; i <= 100; i++) {
    NumberList.push({ label: `${i}`, value: i });
}
export const PageData: any = [
    {
        Label: "Page Name",
        Required: true,
        Name: "pageName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Page Name",
        ErrorMessage: "Page Name is required*",
        Tooltip: "Page Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Page_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    // {
    //     Label: "Add to Menu",
    //     Required: false,
    //     Name: "addToMenu",
    //     InitialValue: "",
    //     Regex: [],
    //     RegExError: "Please enter Add to Menu",
    //     ErrorMessage: "Add to Menu is required*",
    //     Tooltip: "Add to Menu",
    //     HelperText: "",
    //     Type: "checkbox",
    //     List: null,
    //     Categery: "Generate Page",
    //     Group: "Page_Data",
    //     MinValue: null,
    //     MaxValue: null,
    //     Multiple: 0,
    //     PlaceHolder: "",
    //     Disabled: false
    // },
    {
        Label: "Page Path",
        Required: true,
        Name: "pagePath",
        InitialValue: "/",
        Regex: [],
        RegExError: "Please enter Page Path",
        ErrorMessage: "Page Path is required*",
        Tooltip: "Page Path",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Page_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Page Menu Text",
        Required: true,
        Name: "childMenuText",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Menu Text",
        ErrorMessage: "Menu Text is required*",
        Tooltip: "Menu Text",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Page_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const contactDetails: any = [
    {
        Label: "Field Name",
        Required: true,
        Name: "fieldName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Field Name",
        ErrorMessage: "Field Name is required*",
        Tooltip: "Field Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Form_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Field Type",
        Required: true,
        Name: "fieldType",
        InitialValue: null,
        Regex: null,
        RegExError: "",
        ErrorMessage: "Field type  required*",
        Tooltip: "Please select the field type name from the list",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Text", value: "Text" },
            { label: "Radio", value: "Radio" },
            { label: "Checkbox", value: "Checkbox" },
            // { label: "Toggle", value: "Toggle" },
            { label: "Select", value: "Select" },
            { label: "Date", value: "Date" }
        ],
        Categery: "Generate Page",
        Group: "Form_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: ""
    },
    {
        Label: "Mandatory",
        Required: true,
        Name: "mandatory",
        InitialValue: "",
        Regex: [],
        RegExError: "",
        ErrorMessage: "Mandatory field is Required",
        Tooltip: "",
        HelperText: "",
        Type: "select",
        List: [
            { label: "true", value: true },
            { label: "false", value: false }
        ],
        Categery: "Generate Page",
        Group: "Form_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Field Order",
        Required: true,
        Name: "fieldOrder",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Field Order",
        ErrorMessage: "fieldOrder is Required",
        Tooltip: "",
        HelperText: "",
        Type: "select",
        List: NumberList,
        Categery: "Generate Page",
        Group: "Form_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const columns: any = [
    { field: "id", headerName: "ID", width: 70, editable: true },
    {
        field: "fieldName",
        headerName: "Field Name",
        flex: 1,
        editable: true
    },
    {
        field: "fieldType",
        headerName: "Field Type",
        flex: 1,
        editable: true,
        valueOptions: [
            { label: "Text", value: "Text" },
            { label: "Radio", value: "Radio" },
            { label: "Checkbox", value: "Checkbox" },
            { label: "Toggle", value: "Toggle" },
            { label: "Select", value: "Select" }
        ]
    },
    {
        field: "mandatory",
        headerName: "Mandatory",
        flex: 1,
        editable: true,
        valueOptions: [
            { label: "Yes", value: true },
            { label: "No", value: false }
        ]
    },
    {
        field: "fieldOrder",
        headerName: "Field Order",
        flex: 1,
        editable: true
    },
    {
        field: "options",
        headerName: "Options",
        flex: 1,
        editable: true
    }
];
export const ListPage: any = [
    {
        Label: "Column Name",
        Required: true,
        Name: "columnName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Name",
        ErrorMessage: "Column Name is required*",
        Tooltip: "Column Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Type",
        Required: true,
        Name: "columnType",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Type",
        ErrorMessage: "Column Type is required*",
        Tooltip: "Column Type",
        HelperText: "",
        Type: "select",
        List: [
            { label: "String", value: "string" },
            { label: "Number", value: "number" },
            { label: "Date", value: "date" },
            { label: "Boolean", value: "boolean" }
        ],
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Value",
        Required: true,
        Name: "columnValue",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Value",
        ErrorMessage: "Column Vlaue is required*",
        Tooltip: "Column Value",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Properties",
        Required: false,
        Name: "columnProperties",
        InitialValue: null,
        Regex: null,
        RegExError: "",
        ErrorMessage: "Column Type  required*",
        Tooltip: "Please select the field type name from the list",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Sort by ASC", value: "ASC" },
            { label: "Sort by DSC", value: "DSC" },
            { label: "Filter", value: "FIR" }
        ],
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: ""
    },
    {
        Label: "Column Order",
        Required: true,
        Name: "columnOrder",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Order",
        ErrorMessage: "ColumnOrder is Required",
        Tooltip: "",
        HelperText: "",
        Type: "select",
        List: [
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 }
        ],
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];

export const CrudPage: any = [
    {
        Label: "Column Name",
        Required: true,
        Name: "columnName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Name",
        ErrorMessage: "Column Name is required*",
        Tooltip: "Column Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Type",
        Required: true,
        Name: "columnType",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Type",
        ErrorMessage: "Column Type is required*",
        Tooltip: "Column Type",
        HelperText: "",
        Type: "select",
        List: [
            { label: "String", value: "string" },
            { label: "Number", value: "number" },
            { label: "Date", value: "date" },
            { label: "Boolean", value: "boolean" }
        ],
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Value",
        Required: true,
        Name: "columnValue",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Value",
        ErrorMessage: "Column Vlaue is required*",
        Tooltip: "Column Value",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Properties",
        Required: false,
        Name: "columnProperties",
        InitialValue: null,
        Regex: null,
        RegExError: "",
        ErrorMessage: "Column Type  required*",
        Tooltip: "Please select the field type name from the list",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Sort by ASC", value: "ASC" },
            { label: "Sort by DSC", value: "DSC" },
            { label: "Filter", value: "FIR" }
        ],
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: ""
    },
    {
        Label: "Column Order",
        Required: true,
        Name: "columnOrder",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Order",
        ErrorMessage: "ColumnOrder is Required",
        Tooltip: "",
        HelperText: "",
        Type: "select",
        List: [
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 }
        ],
        Categery: "Generate Page",
        Group: "List_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const ReportPage: any = [
    {
        Label: "Data Source",
        Required: false,
        Name: "datasource",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Data Source",
        ErrorMessage: "Data Source is required*",
        Tooltip: "Data Source",
        HelperText: "",
        Type: "header",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "API",
        Required: true,
        Name: "api",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter valid API",
        ErrorMessage: "API is required*",
        Tooltip: "API",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Columns",
        Required: true,
        Name: "columns",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Columns",
        ErrorMessage: "Columns is required*",
        Tooltip: "Columns",
        HelperText: "",
        Type: "header",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Name",
        Required: true,
        Name: "columnName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Name",
        ErrorMessage: "Column Name is required*",
        Tooltip: "Column Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Type",
        Required: true,
        Name: "columnType",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Type",
        ErrorMessage: "Column Type is required*",
        Tooltip: "Column Type",
        HelperText: "",
        Type: "select",
        List: [
            { label: "String", value: "string" },
            { label: "Number", value: "number" },
            { label: "Date", value: "date" },
            { label: "Boolean", value: "boolean" }
        ],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Value",
        Required: false,
        Name: "columnValue",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Value",
        ErrorMessage: "Column Vlaue is required*",
        Tooltip: "Column Value",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Order",
        Required: true,
        Name: "columnOrder",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Column Order",
        ErrorMessage: "ColumnOrder is Required",
        Tooltip: "",
        HelperText: "",
        Type: "select",
        List: NumberList,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Disable sorting",
        Required: true,
        Name: "disableSorting",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Disable sorting",
        ErrorMessage: "Disable sorting is required*",
        Tooltip: "Disable sorting",
        HelperText: "",
        Type: "checkbox",
        List: [],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Tool Bar",
        Required: false,
        Name: "toolBar",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Style",
        ErrorMessage: "Style is required*",
        Tooltip: "Style",
        HelperText: "",
        Type: "header",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Position",
        Required: true,
        Name: "toolBarPosition",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Position",
        ErrorMessage: "Position is Required",
        Tooltip: "Tool Bar Position",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Top", value: "top" },
            { label: "Bottom", value: "bottom" }
        ],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Show Filter Button",
        Required: true,
        Name: "showFilterButton",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Show Filter Button",
        ErrorMessage: "Show Filter Button is required*",
        Tooltip: "Show Filter Button",
        HelperText: "",
        Type: "checkbox",
        List: [],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Show Download Button",
        Required: true,
        Name: "showDownloadButton",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Show Download Button",
        ErrorMessage: "Show Download Button is required*",
        Tooltip: "Show Download Button",
        HelperText: "",
        Type: "checkbox",
        List: [],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Show Refresh Button",
        Required: true,
        Name: "showRefreshButton",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Show Refresh Button",
        ErrorMessage: "Show Refresh Button is required*",
        Tooltip: "Show Refresh Button",
        HelperText: "",
        Type: "checkbox",
        List: [],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Style",
        Required: false,
        Name: "style",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Style",
        ErrorMessage: "Style is required*",
        Tooltip: "Style",
        HelperText: "",
        Type: "header",
        List: null,
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Background",
        Required: true,
        Name: "background",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter background color",
        ErrorMessage: "Background color is required*",
        Tooltip: "Background Color",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Alignment",
        Required: true,
        Name: "alignment",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select Alignment",
        ErrorMessage: "Alignment is required*",
        Tooltip: "Select Alignment",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Left", value: "left" },
            { label: "Right", value: "right" },
            { label: "Center", value: "center" }
        ],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Column Pinning",
        Required: false,
        Name: "pinColumn",
        InitialValue: "",
        Regex: [],
        RegExError: "Please select column pinning",
        ErrorMessage: "Column Type is required*",
        Tooltip: "Column Pinning",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Left", value: "left" },
            { label: "Right", value: "right" }
        ],
        Categery: "Generate Page",
        Group: "Report_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];

export const Listcolumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "columnName", headerName: "Column Name", flex: 1 },
    { field: "columnType", headerName: "Column Type", flex: 1 },
    {
        field: "columnOrder",
        headerName: "Column Order",
        flex: 1
    }
];
export const Crudcolumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fieldName", headerName: "Field Name", flex: 1 },
    { field: "fieldType", headerName: "Field Type", flex: 1 },
    {
        field: "fieldOrder",
        headerName: "Field Order",
        flex: 1
    }
];
export const Reportcolumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "columnName", headerName: "Column Name", flex: 1 },
    {
        field: "columnValue",
        headerName: "Column Value",
        width: 130
    },
    { field: "columnType", headerName: "Column Type", flex: 1 },

    {
        field: "columnOrder",
        headerName: "Column Order",
        flex: 1
    }
];
export const pageName = [
    {
        Label: "Page Name",
        Required: true,
        Name: "pageName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Field Name",
        ErrorMessage: "Field Name is required*",
        Tooltip: "Field Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "Page_Name",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const config = {
    loginType: "email",
    projectData: {
        projectName: "react",
        tagName: "",
        frontendTechnology: "react",
        apiLayer: "node",
        db: "",
        host: "",
        appComponent: {},
        configProjectData: {}
    }
};
export const options = [
    { value: "React", label: "React Js" },
    { value: "Vue", label: "Vue Js" },
    { value: "Angular", label: "Angular" },
    { value: "Ionic", label: "Ionic" },
    { value: "ReactIonic", label: "React+IONIC" },
    { value: "VueIonic", label: "Vue+IONIC" },
    { value: "AngularIonic", label: "Angular+IONIC" },
    { value: "ReactNative", label: "React Native" }
    // Add more options here
];
export const Appoptions = [
    { value: "Auth", label: "Authenticate Login" },
    { value: "paymentGateway", label: "PaymentGateway" },
    { value: "SpringBoot", label: "Spring Boot" },
    { value: "restApi", label: "Rest Api" },
    { value: "graphql", label: "GraphQL" }

    // Add more options here
];
export const CreatedPage = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "pageType", headerName: "Page Type", width: 130 },
    { field: "pageName", headerName: "Page name", width: 130 },
    {
        field: "pageDetails",
        headerName: "Page Details",
        type: "singleSelect",
        width: 90
    }
];

export const createProjectConfig = {
    projectData: {
        projectDetails: " Project Details",
        projectName: "Project Name",
        tagName: "Tag Line",
        frontendPageTitle: "Frontend Technology",
        frontendOption: {
            technology1: "ReactJS",
            technology2: "VueJS",
            technology3: "Angular",
            technology4: "Ionic",
            technology5: "React+IONIC",
            technology6: "Vue+IONIC",
            technology7: "Angular+IONIC",
            technology8: "React Native"
        },
        frontendValue: {
            technology1Value: "reactJs",
            technology2Value: "vueJs",
            technology3Value: "angular",
            technology4Value: "ionic",
            technology5Value: "reactIonic",
            technology6Value: "vueIonic",
            technology7Value: "angularIonic",
            technology8Value: "reactNative"
        },
        backendPageTitle: "Backend Technology",
        backendOption: {
            technology1: "NodeJS",
            technology2: "CSharp",
            technology3: "SpringBoot",
            technology4: "RestApi",
            technology5: "GraphQL"
        },
        backendValue: {
            technology1Value: "nodeJs",
            technology2Value: "cSharp",
            technology3Value: "springBoot",
            technology4Value: "graphQl"
        },
        databasePageTitle: "Database",
        databaseOption: {
            option1: "MongoDB",
            option2: "MYSQL",
            option3: "MS-SQL"
        },
        databaseValue: {
            technology1Value: "mongodb",
            technology2Value: "mysql",
            technology3Value: "mssql"
        },
        hostPageTitle: "Host",
        hostOption: {
            technology1: "PaaS-AZURE",
            technology2: "IaaS-AZURE",
            technology3: "PaaS-AWS",
            technology4: "IaaS-AWS",
            technology5: "GCP"
        },
        hostValue: {
            technology1Value: "paas-azure",
            technology2Value: "iaas-azure",
            technology3Value: "paas-aws",
            technology4Value: "iaas-aws",
            technology5Value: "gcp"
        },
        componentPageTitle: "Application Components",
        components: {
            component1: {
                name: "Authentication",

                option1: "Login",
                option2: "Facebook",
                option3: "Google",
                option4: "Auth0",

                value1: "emailLogin",
                value2: "facebook",
                value3: "google",
                value4: "auth0"
            },
            component2: {
                name: "Payments integration",

                option1: "RazPay",
                option2: "PayPal",
                option3: "Stripe",

                value1: "razPay",
                value2: "payPal",
                value3: "stripe"
            },
            component3: {
                name: "Media",

                option1: "Youtube",
                option2: "Vimeo",
                option3: "Facebook",

                value1: "youtube",
                value2: "vimeo",
                value3: "facebookmedia"
            },
            component4: {
                name: "File Upload",

                option1: "FTP",
                option2: "Storage",

                value1: "ftp",
                value2: "storage"
            },
            component5: {
                name: "Navbar",

                option1: "Top",
                option2: "Right",
                option3: "Left",

                value1: "top",
                value2: "right",
                value3: "left"
            }
        }
    }
};
export const typeConfig = {
    string: "string",
    number: "number",
    boolean: "boolean",
    date: "date"
};

export const MySqlCredentialsForm = [
    {
        Label: "Host Name",
        Required: true,
        Name: "hostName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Host Name",
        ErrorMessage: "Host Name is required*",
        Tooltip: "Host Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Data Base Name",
        Required: true,
        Name: "dbName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Data Base Name",
        ErrorMessage: "Data Base Name is required*",
        Tooltip: "Data Base Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "User Name",
        Required: true,
        Name: "userName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter User Name",
        ErrorMessage: "User Name is required*",
        Tooltip: "User Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Password",
        Required: true,
        Name: "password",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Password",
        ErrorMessage: "Password is required*",
        Tooltip: "Password",
        HelperText: "",
        Type: "password",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const mongoDBCredentialsForm = [
    {
        Label: "Connection String",
        Required: true,
        Name: "connection_String",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Connection String",
        ErrorMessage: "Connection String is required*",
        Tooltip: "Connection String",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const MsSqlCredentialsForm = [
    {
        Label: "Server name",
        Required: true,
        Name: "servername",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Server name",
        ErrorMessage: "Server name is required*",
        Tooltip: "Server name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Login",
        Required: true,
        Name: "login",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Login",
        ErrorMessage: "Login is required*",
        Tooltip: "Login",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Password",
        Required: true,
        Name: "password",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter DB User Password",
        ErrorMessage: "DB User Password is required*",
        Tooltip: "Password",
        HelperText: "",
        Type: "password",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];

export const facebookCredentials = [
    {
        Label: "App ID",
        Required: true,
        Name: "appId",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter App ID",
        ErrorMessage: "App ID is required*",
        Tooltip: "App ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "App Secret",
        Required: true,
        Name: "appSecret",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter App Secret",
        ErrorMessage: "App Secret is required*",
        Tooltip: "App Secret",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Re-direct URIs",
        Required: true,
        Name: "reDirectUris",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Re-direct URIs",
        ErrorMessage: "Re-direct URIs is required*",
        Tooltip: "Re-direct URIs",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "DB Credentials",
        Group: "DB_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const EmailCredentials = [
    {
        Label: "Login Type",
        Required: true,
        Name: "loginType",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Login Type",
        ErrorMessage: "Login Type is required*",
        Tooltip: "Login Type",
        HelperText: "",
        Type: "radio",
        List: [
            { label: "Email with Password", value: "emailAndPassword" },
            { label: "Email with OTP", value: "emailAndOtp" },
            { label: "Phone with OTP", value: "phoneAndOtp" }
        ],
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Email",
        Required: true,
        Name: "email",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Email",
        ErrorMessage: "Email is required*",
        Tooltip: "Email your Email",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "EmailAndPassword",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Password",
        Required: true,
        Name: "password",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Password",
        ErrorMessage: "Password is required*",
        Tooltip: "Password",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "EmailAndPassword",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const Auth0Credentials = [
    {
        Label: "Client ID",
        Required: true,
        Name: "clientId",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client ID",
        ErrorMessage: "Client ID is required*",
        Tooltip: "Client ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Client Secret",
        Required: true,
        Name: "clientsecret",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client Secret",
        ErrorMessage: "Client Secret is required*",
        Tooltip: "Client Secret",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Domain",
        Required: true,
        Name: "domain",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Domain",
        ErrorMessage: "Domain is required*",
        Tooltip: "Domain",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const GoogleCredentials = [
    {
        Label: "Client ID",
        Required: true,
        Name: "clientId",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client ID",
        ErrorMessage: "Client ID is required*",
        Tooltip: "Client ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const YoutubeMediaCredentials = [
    {
        Label: "Google API Key",
        Required: true,
        Name: "googleapiKey",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Google API Key",
        ErrorMessage: "Google API Key is required*",
        Tooltip: "Google API Key",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Media Credentials",
        Group: "Media_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "OAuth 2.0 Client ID",
        Required: false,
        Name: "clientId",
        InitialValue: "",
        Regex: [],
        RegExError: "OAuth 2.0 Client ID",
        ErrorMessage: "OAuth 2.0 Client ID is required",
        Tooltip: "OAuth 2.0 Client ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Media Credentials",
        Group: "Media_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const VimeoCredentials = [
    {
        Label: "Client ID",
        Required: true,
        Name: "clientId",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client ID",
        ErrorMessage: "Client ID is required*",
        Tooltip: "Client ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Media Credentials",
        Group: "Media_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Access Token",
        Required: true,
        Name: "accessToken",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Access Token",
        ErrorMessage: "Access Token is required*",
        Tooltip: "Access Token",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Media Credentials",
        Group: "Media_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const FacebookMediaCredentials = [
    {
        Label: "App ID",
        Required: true,
        Name: "appId",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter App ID",
        ErrorMessage: "App ID is required*",
        Tooltip: "App ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Media Credentials",
        Group: "Media_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "App Secret",
        Required: true,
        Name: "appSecret",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter App Secret",
        ErrorMessage: "App Secret is required*",
        Tooltip: "App Secret",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Media Credentials",
        Group: "Media_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const RazorPayCredentials = [
    {
        Label: "API Key",
        Required: true,
        Name: "apiKey",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Razorpay API Key",
        ErrorMessage: "Razorpay API Key is required*",
        Tooltip: "Razorpay API Key",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Payments Credentials",
        Group: "Payments_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Key Secret",
        Required: true,
        Name: "keySecret",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Razorpay API Key Secret",
        ErrorMessage: "Razorpay API Key Secret is required*",
        Tooltip: "Razorpay API Key Secret",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Payments Credentials",
        Group: "Payments_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const StripeCredentials = [
    {
        Label: "Public Key",
        Required: true,
        Name: "publicKey",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Public Key",
        ErrorMessage: "Public Key is required*",
        Tooltip: "Public Key",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Payments Credentials",
        Group: "Payments_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Secret Key",
        Required: true,
        Name: "secretKey",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Secret Key",
        ErrorMessage: "Secret Key is required*",
        Tooltip: "Secret Key",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Payments Credentials",
        Group: "Payments_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const PaypalCredentials = [
    {
        Label: "Client ID",
        Required: true,
        Name: "clientID",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client ID",
        ErrorMessage: "Client ID is required*",
        Tooltip: "Client ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Payments Credentials",
        Group: "Payments_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Secret Key",
        Required: true,
        Name: "secretKey",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Secret Key",
        ErrorMessage: "Secret Key is required*",
        Tooltip: "Secret Key",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Payments Credentials",
        Group: "Payments_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const FtpCredentials = [
    {
        Label: "Server Address",
        Required: true,
        Name: "serverAddress",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter  Server Address",
        ErrorMessage: " Server Address is required*",
        Tooltip: " Server Address",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Client Secret",
        Required: true,
        Name: "clientSecret",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client Secret",
        ErrorMessage: "Client Secret is required*",
        Tooltip: "Client Secret",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const StorageCredentials = [
    {
        Label: "Client ID",
        Required: true,
        Name: "clientID",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client ID",
        ErrorMessage: "Client ID is required*",
        Tooltip: "Client ID",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Client Secret",
        Required: true,
        Name: "clientSecret",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Client Secret",
        ErrorMessage: "Client Secret is required*",
        Tooltip: "Client Secret",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Auth Credentials",
        Group: "Auth_Credentials",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const optionLabels: any = {
    emailLogin: "Email Login",
    google: "Google",
    auth0: "Auth0 ",
    razPay: "RazPay",
    payPal: "PayPal",
    stripe: "Stripe",
    youtube: "Youtube",
    vimeo: "Vimeo",
    facebook: "Facebook",
    ftp: "FTP",
    storage: "Storage",
    top: "Top",
    right: "Right",
    left: "Left"
};

export const createHostConfig: any = {
    selectAppTypeTitle: "HOST TYPE",
    appType: {
        type1: "Virtual Machine",
        type2: "App-Services",
        type3: "Functions",
        type4: "SQL-Database"
    },
    appValue: {
        value1: "vm",
        value2: "appServices",
        value3: "functions",
        value4: "azureSqlDb"
    }
};

export const diskSizeOptions = [
    { label: "Default size (30 GiB)", value: "Default size (30 GiB)" },
    { label: "Resize to 32 GiB(P4)", value: "Resize to 32 GiB(P4)" },
    { label: "Resize to 64 GiB(P6) Free account eligible", value: "Resize to 64 GiB(P6) Free account eligible" },
    { label: "Resize to 128 GiB(P10) ", value: "Resize to 128 GiB(P10)" },
    { label: "Resize to 256 GiB(P15) ", value: "Resize to 256 GiB(P15)" },
    { label: "Resize to 512 GiB(P20) ", value: "Resize to 512 GiB(P20)" },
    { label: "Resize to 1 TiB(P30) ", value: "Resize to 1 TiB(P30)" },
    { label: "Resize to 2 TiB(P40) ", value: "Resize to 2 TiB(P40)" }
];
export const regionOptions = [
    { label: "Australia Central", value: "Australia Central" },
    { label: "Australia East", value: "Australia East" },
    { label: "Australia Southeast", value: "Australia Southeast" },
    { label: "Brazil South", value: "Brazil South" },
    { label: "Canada Central", value: "Canada Central" },
    { label: "Canada East", value: "Canada East" },
    { label: "Central India", value: "Central India" },
    { label: "Central US", value: "Central US" },
    { label: "East Asia", value: "East Asia" },
    { label: "East US ", value: "East US " },
    { label: "East US 2", value: "East US 2" },
    { label: "France  Central", value: "France  Central" },
    { label: "Germany West Central", value: "Germany West Central" },
    { label: "Japan East", value: "Japan East" },
    { label: "Japan West", value: "Japan West" },
    { label: "Korea Central", value: "Korea Central" },
    { label: "Korea South", value: "Korea South" },
    { label: "North Central US (Stage)", value: "North Central US (Stage)" },
    { label: "North Europe", value: "North Europe" },
    { label: "Norway East", value: "Norway East" },
    { label: "Poland Central", value: " Poland Central" },
    { label: "Qatar Central", value: " Qatar Central" },
    { label: "South Africa North", value: "South Africa North" },
    { label: "South Central US", value: "South Central US" },
    { label: "South India", value: "South India" },
    { label: "Southeast Asia", value: "Southeast Asia" },
    { label: "Sweden Central", value: "Sweden Central" },
    { label: "Switzerland North", value: "Switzerland North" },
    { label: "UAE   North", value: "UAE  North" },
    { label: "UK South", value: "UK South" },
    { label: "UK West", value: "UK West" },
    { label: "West Central US", value: "West Central US" },
    { label: "West Europe", value: "West Europe" },
    { label: "West India", value: "West India" },
    { label: "West US", value: "West US" },
    { label: "West US 2", value: "West US 2" },
    { label: "West US 3", value: "West US 3" }
];
