/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from "react";
import { useFormik } from "formik";
import { MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Grid, Paper, useMediaQuery } from "@mui/material";

import API from "../../API";
import { en, messages } from "../../dictionary/en";
import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import CheckBox from "../../Hoc/Checkbox";
import GenerateForm from "../../Hoc/GenerateForms";
import Input from "../../Hoc/Input";
import Select from "../../Hoc/Select";
import { Reportcolumns, ReportPage } from "../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handlePageData, handlePages } from "../../Redux/Reducers";
import { downloadJsonFile } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../Theme/Theme";
import CloningSourceCode from "../codeCloning/CodeCloning";

import ProjectList from "./DataPage/ProjectList";

const ReportForm: React.FC = () => {
    const { projectData, newPages, pageData } = useAppSelector((state: any) => state);
    const dispatch = useAppDispatch();
    const [state, setState] = useState<any[]>([]);
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    console.log("selectionModel", state, "newPages", selectionModel);
    const [selectWhere, setSelectWhere] = React.useState<any>("");
    const [selectCondition, setSelectCondition] = React.useState<any>("");
    const [inputValue, setInputValue] = React.useState<any>("");
    const [filterFields, setFilterFields] = React.useState<any>(false);
    const [filter, setFilter] = React.useState<any>([]);
    const [dataSource, setDataSource] = React.useState([]);
    const [loading, setLoading] = React.useState<any>(false);
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");
    const [selectedProject, setSelectedProject] = useState<any>();
    const [defaultCheckbox, setDefaultCheckbox] = useState<boolean>(newPages?.defaultCheckbox || false);
    const [projectJson, setProjectJson] = useState<any>();

    console.log("filter", filter, filterFields, dataSource);
    const columns: MRT_ColumnDef[] = [];
    const navigate = useNavigate();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const content = e?.target?.result;
                const parsedData = JSON.parse(content as string);
                console.log("parsedData", parsedData);
            } catch (parseError) {
                console.error(messages.jsonparseerror, parseError);
            }
        };
        reader.readAsText(file);
    };
    console.log("KN", handleChange);

    const handleDeleteRows = async () => {
        try {
            const updatedState = state.filter((item: any) => !selectionModel.includes(item.id));
            await dispatch(handlePages({ ...newPages, updatedState }));
            setState(updatedState);
        } catch (error) {
            console.error(messages?.anErrormessage, error);
        }
    };
    const formikProps = useFormik({
        initialValues: {
            ...ReportPage.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
        },
        onSubmit: async (values) => {
            setState([...state, values]);
            dispatch(handlePages({ pageDetails: [...newPages.pageDetails, values] }));
            formikProps.resetForm();
            dispatch(handleDrawerClose());
            toast.success("Field added successfully");
        }
    });
    const handleDownloadJson = async () => {
        const response: any = await API.Projects.createPages(newPages);
        if (response?.success) {
            downloadJsonFile({ ...newPages }, `${newPages?.pageName}.json`);
            dispatch(handlePageData([...pageData, response?.data]));
            navigate(RoutesEnum?.managePage);
        }
    };
    console.log(handleDownloadJson);

    for (const column of Reportcolumns) {
        columns.push({ accessorKey: column.field, header: column.headerName });
    }
    const tableData = filter?.length ? filter : state;
    console.log("tableData", tableData);

    const fetchApiData = () => {
        try {
            setLoading(true);
            const Url: any = state.filter((col: any) => col.api)[0].api;
            fetch(Url)
                .then((res) => res.json())
                .then((res) => setDataSource(res))
                .catch((err) => err);
            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchApiData();
    }, []);
    const whereList = columns.map((e: any) => {
        return { label: e.header, value: e.accessorKey };
    });
    // filtering conditions
    const handleSearchFilter = () => {
        console.log("here", selectWhere.value, selectCondition.value, inputValue);
        let filteredData: any;
        if (selectCondition.value === ">") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] > inputValue);
        } else if (selectCondition.value === ">=") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] >= inputValue);
        } else if (selectCondition.value === "<") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] < inputValue);
        } else if (selectCondition.value === "<=") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] <= inputValue);
        } else if (selectCondition.value === "=") {
            const dataValue = Number.isNaN(parseInt(inputValue, 10)) ? inputValue : parseInt(inputValue, 10);
            filteredData = tableData.filter((e: any) => e[selectWhere.value] === dataValue);
        } else if (selectCondition.value === "!=") {
            const conditionValue = Number.isNaN(parseInt(inputValue, 10)) ? inputValue : parseInt(inputValue, 10);
            filteredData = tableData.filter((e: any) => e[selectWhere.value] === conditionValue);
        }
        console.log("filteredData", filteredData);
        setFilter(filteredData);
    };

    const handleOpen = () => {
        dispatch(handleDrawerOpen({ open: true, type: "REPORT_PAGE" }));
    };
    const handleProjectModal = async (project: any) => {
        setSelectedProject(project);
        const file = project;
        const Menus: any = file?.projectDetails?.Menus || [];
        if (file) {
            const newMenu = { childMenuText: newPages?.childMenuText, path: newPages?.pagePath };
            const projectDetails = {
                ...file?.projectDetails,
                Menus: [...Menus, newMenu]
            };
            const updatedProject = {
                ...file,
                projectDetails,
                pages: [...file?.pages, newPages],
                IsAddInProject: true
            };
            try {
                setProjectJson(updatedProject);
                await API.Projects.updateProjects(updatedProject);
            } catch (parseError) {
                console.error(messages.jsonparseerror, parseError);
            }
        }
    };
    const handleAppendProjectCancel = () => {
        if (!selectedProject) {
            setDefaultCheckbox(false);
        }
        dispatch(handleDrawerClose());
    };
    const handleApppendProjectOk = () => {
        if (selectedProject) {
            dispatch(handleDrawerClose());
            setDefaultCheckbox(true);
        } else {
            toast.error(messages?.PleaseChooseProject);
        }
    };
    const handleCheck = async (defaultCheck: boolean) => {
        if (!newPages?.pageDetails?.length) {
            toast.warning(messages.kindlyProvideDetails);
        } else {
            dispatch(handleDrawerOpen({ open: true, type: en.appendProjectType }));
            setDefaultCheckbox(defaultCheck);
        }
    };
    return (
        <Box component="form" pt={10} onSubmit={formikProps.handleSubmit}>
            <Box
                component="div"
                width="100%"
                height="calc(100vh - 100px)"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
            >
                <Paper elevation={3} sx={{ height: "90%", width: "90%", p: 2, pl: 2 }}>
                    <Box sx={{ height: mediaQuery ? "470px" : "100%", textAlign: "end" }}>
                        <Button onClick={handleOpen} sx={{ marginBottom: "8px" }}>
                            Add Field
                        </Button>
                        {/* Table Grid */}
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12} position="absolute" gap={2} mt={-15} display="flex" justifyContent="right-end">
                                {filterFields && (
                                    <Box component="form" p={5} gap={2} noValidate display="flex">
                                        <Select label="Where" value={selectWhere} list={whereList ?? []} onChange={(e: any) => setSelectWhere(e)} />
                                        <Select
                                            label="Condition"
                                            value={selectCondition}
                                            list={[
                                                { label: "equals", value: "=" },
                                                { label: "does not equal", value: "!=" },
                                                { label: "greater than", value: ">" },
                                                { label: "less than", value: "<" },
                                                { label: "greater than or equals", value: ">=" },
                                                { label: "less than or equals", value: "<=" },
                                                { label: "before", value: "before" },
                                                { label: "after", value: "after" },
                                                { label: "is empty", value: "empty" },
                                                { label: "is not empty", value: "empty" },
                                                { label: "contains", value: "contains" },
                                                { label: "does not constains", value: "constains" }
                                            ]}
                                            onChange={(e: any) => setSelectCondition(e)}
                                        />
                                        <Input label="Value" value={inputValue} onChange={(e: any) => setInputValue(e)} />
                                        <Button onClick={handleSearchFilter}>Search</Button>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} sx={{ height: "50vh" }}>
                                <HoC.DataTable
                                    rows={tableData?.map((row: any, index: any) => ({
                                        ...row,
                                        id: index + 1,
                                        columnName: row?.columnName || row?.columnName,
                                        columnValue: row?.columnValue || row?.columnValue,
                                        columnType: row?.columnType?.label || row?.columnType,
                                        columnOrder: row?.columnOrder?.label || row?.columnOrder
                                    }))}
                                    columns={Reportcolumns}
                                    loading={loading}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 }
                                        }
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                    onRowSelectionModelChange={(newRowSelectionModel: any) => {
                                        setSelectionModel(newRowSelectionModel);
                                        setState((prevState: any) =>
                                            prevState.map((row: any, index: any) => ({
                                                ...row,
                                                id: index + 1,
                                                columnName: row?.columnName || row?.columnName,
                                                columnValue: row?.columnValue || row?.columnValue,
                                                columnType: row?.columnType?.label || row?.columnType,
                                                columnOrder: row?.columnOrder?.label || row?.columnOrder
                                            }))
                                        );
                                    }}
                                />
                            </Grid>
                            <CheckBox
                                id="appendWithProject"
                                name="appendWithProject"
                                checked={defaultCheckbox}
                                onChange={handleCheck}
                                disabled={false}
                                label="Select to Append with Project"
                                toolTip="Select to Append with Project"
                            />
                            <Grid item py={1} display="flex" justifyContent="space-between" alignItems="center" gap={2} lg={12} xs={12} sm={12}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        navigate(RoutesEnum.pageType);
                                    }}
                                    sx={{ height: "38.5px" }}
                                >
                                    {en.backbtn}
                                </Button>
                                <Grid item gap={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <FilterListIcon
                                        color="primary"
                                        onClick={() => (filterFields ? setFilterFields(false) : setFilterFields(true))}
                                        style={{ padding: "20px 5px" }}
                                    />
                                    <RefreshIcon onClick={() => fetchApiData()} style={{ padding: "20px 20px" }} />
                                    {defaultCheckbox && projectJson ? (
                                        <CloningSourceCode jsonData={projectJson} />
                                    ) : (
                                        <Button
                                            style={{ ...buttonStyle, height: "38.5px" }}
                                            disabled={!(state?.length > 0)}
                                            onClick={handleDownloadJson}
                                        >
                                            {en.genjson}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} py={1} gap={2} display="flex" justifyContent="right">
                                <ReportCsv />
                            </Grid> */}
                        </Grid>
                    </Box>
                    <HoC.CustomDrawer
                        type="REPORT_PAGE"
                        handleDrawerOnClose={() => dispatch(handleDrawerClose())}
                        title={`${newPages?.initialState?.pageName || newPages?.pageName} Config`}
                    >
                        <Box component="form" onSubmit={formikProps.handleSubmit}>
                            {/* Field Grid */}

                            <Grid item xs={12} p={2}>
                                <Box>
                                    <GenerateForm FormData={ReportPage} FormikProps={formikProps} />
                                    <Grid display="flex" justifyContent="flex-end" py={1} gap={2} mt={1}>
                                        {selectionModel.length > 0 ? (
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                disabled={!(selectionModel.length > 0)}
                                                onClick={handleDeleteRows}
                                            >
                                                {en.deletebtn}
                                            </Button>
                                        ) : (
                                            <Button variant="contained" color="inherit" onClick={() => formikProps.resetForm()}>
                                                {en.clearbtn}
                                            </Button>
                                        )}
                                        <Button style={buttonStyle} type="submit">
                                            {en.savebtn}
                                        </Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </HoC.CustomDrawer>
                </Paper>
            </Box>
            <HoC.CustomDrawer type="APPEND_PROJECT" handleDrawerOnClose={() => dispatch(handleDrawerClose())} title="Project List">
                <ProjectList
                    projectData={projectData}
                    selectedProject={selectedProject}
                    handleProjectModal={handleProjectModal}
                    handleCancel={handleAppendProjectCancel}
                    handleOk={handleApppendProjectOk}
                />
            </HoC.CustomDrawer>
        </Box>
    );
};

export default ReportForm;
