export const AppService: any = [
    {
        Label: "Name",
        Required: true,
        Name: "appServicePlanName",
        InitialValue: "",
        Regex: [
            // '^(?!.*[/"\\[\\]:|<>+=;,?*@&\\s]|^_|_$|[.-]$)(?!administrator|admin|user|user1|test|user2|test1|user3|admin1|1|123|a|actuser|adm|admin2|aspnet|backup|console|david|guest|john|owner|root|server|sql|support|support_388945a0|sys|test2|test3|user4|user5)[^s]{1,64}$'
        ],

        RegExError: "Please Enter Name",
        ErrorMessage: "App Service  Name cannot contain special characters, spaces, or be too long.",
        Tooltip: " Enter  Name ",
        HelperText: "asp-nimbusjs",
        Type: "text",
        List: null,
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false,
        Prefix: "asp-"
    },
    {
        Label: " Region",
        Required: true,
        Name: "appServiceRegion",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Region",
        ErrorMessage: "Region is required*",
        Tooltip: "Region Selection",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Australia Central ", value: "Australia Central " },
            { label: "Australia East", value: "Australia East" },
            { label: "Australia Southeast", value: "Australia Southeast" },
            { label: "Brazil South", value: "Brazil South" },
            { label: "Canada Central", value: "Canada Central" },
            { label: "Canada East", value: "Canada East" },
            { label: "Central India", value: "Central India" },
            { label: "Central US", value: "Central US" },
            { label: "East Asia", value: "East Asia" },
            { label: "East US", value: "East US" },
            { label: "East US 2", value: "East US 2" },
            { label: "France  Central", value: "France  Central" },
            { label: "Germany West Central", value: "Germany West Central" },
            { label: "Israel Central", value: "Israel Central" },
            { label: "Italy North", value: "Italy North" },
            { label: "Japan East", value: "Japan East" },
            { label: "Japan West", value: "Japan West" },
            { label: "Korea Central", value: "Korea Central" },
            { label: "Korea South", value: "Korea South" },
            { label: "North Central US", value: "North Central US" },
            { label: "North Europe", value: "North Europe" },
            { label: "Norway East", value: "Norway East" },
            { label: "Poland Central", value: " Poland Central" },
            { label: "Qatar Central", value: " Qatar Central" },
            { label: "South Africa North", value: "South Africa North" },
            { label: "South Central US", value: "South Central US" },
            { label: "South India", value: "South India" },
            { label: "Southeast Asia", value: "Southeast Asia" },
            { label: "Sweden Central", value: "Sweden Central" },
            { label: "Switzerland North", value: "Switzerland North" },
            { label: "UAE   North", value: "UAE  North" },
            { label: "UK South", value: "UK South" },
            { label: "UK West", value: "UK West" },
            { label: "West Central US", value: "West Central US" },
            { label: "West Europe", value: "West Europe" },
            { label: "West India", value: "West India" },
            { label: "West US", value: "West US" },
            { label: "West US 2", value: "West US 2" },
            { label: "West US 3", value: "West US 3" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Pricing Tier",
        Required: true,
        Name: "pricingTier",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Pricing Tier",
        ErrorMessage: "Pricing Tier is required*",
        Tooltip: "Pricing Tier Selection",
        HelperText:
            "Note: Please be aware that the pricing provided above is merely an approximate figure. For a precise cost estimate, we recommend consulting the Azure pricing calculator.",
        Type: "select",
        List: [
            {
                label: "Standard S1(100 total ACU,1.75 GB memory,1vCPU)",
                value: "Standard S1(100 total ACU,1.75 GB memory,1vCPU)"
            },
            {
                label: "Basic B1 974.61 INR/Month (Estimated) ACU:100,Memory:1.75 GB,vCPU:1",
                value: "Basic B1 974.61 INR/Month (Estimated) ACU:100,Memory:1.75 GB,vCPU:1"
            },
            {
                label: "Premium V3 P1V3 8,886.18 INR/Month (Estimated) ACU:195,Memory:8 GB .vCPU:2",
                value: "Premium V3 P1V3 8,886.18 INR/Month (Estimated) ACU:195,Memory:8 GB .vCPU:2"
            },
            {
                label: "Premium V3 P2V3 17,772.36 INR/Month (Estimated) ACU:195,Memory:16 GB .vCPU:4",
                value: "Premium V3 P2V3 17,772.36 INR/Month (Estimated) ACU:195,Memory:16 GB .vCPU:4"
            },
            {
                label: "Premium V3 P3V3 35,544.71 INR/Month (Estimated) ACU:195,Memory:32 GB .vCPU:8",
                value: "Premium V3 P3V3 35,544.71 INR/Month (Estimated) ACU:195,Memory:32 GB .vCPU:8"
            },
            {
                label: "Free F1 0.00 INR/Month(Estimated) 60 CPU Minutes/day included",
                value: "Free F1 0.00 INR/Month(Estimated) 60 CPU Minutes/day included"
            },
            {
                label: "Shared D1 745.29 INR/Month(Estimated) 60 CPU Minutes/day included",
                value: "Shared D1 745.29 INR/Month(Estimated) 60 CPU Minutes/day included"
            },
            {
                label: "Basic B1 4,299.76 INR/Month (Estimated) ACU:100,Memory:1.75 GB,vCPU:1",
                value: "Basic B1 4,299.76 INR/Month (Estimated) ACU:100,Memory:1.75 GB,vCPU:1"
            },
            {
                label: "Standard  S1 5,733.02 INR/Month (Estimated) ACU:100,Memory:1.75 GB,vCPU:1",
                value: "Standard  S1 5,733.02 INR/Month (Estimated) ACU:100,Memory:1.75 GB,vCPU:1"
            },
            {
                label: "Premium V3 P1V3 18,059.01 INR/Month (Estimated) ACU:195,Memory:8 GB .vCPU:2",
                value: "Premium V3 P1V3 18,059.01 INR/Month (Estimated) ACU:195,Memory:8 GB .vCPU:2"
            },
            {
                label: "Premium V3 P2V3 36,118.02 INR/Month (Estimated) ACU:195,Memory:16 GB .vCPU:4",
                value: "Premium V3 P2V3 36,118.02 INR/Month (Estimated) ACU:195,Memory:16 GB .vCPU:4"
            },
            {
                label: "Premium V3 P3V3 72,236.03 INR/Month (Estimated) ACU:195,Memory:32 GB .vCPU:8",
                value: "Premium V3 P3V3 72,236.03 INR/Month (Estimated) ACU:195,Memory:32 GB .vCPU:8"
            }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
