import {
  UPDATE_LOGIN_TYPE,
  CREATE_PROJECT,
  CREATE_PAGE,
  CREATE_CUSTOM_PROJECT,
  UPDATE_PROJECT_DETAILS,
  UPDATE_TECHNOLOGY,
  UPDATE_COMPONENT,
  UPDATE_PROJECT_DETAILS_FROM_JSON,
  UPDATE_TECHNOLOGY_FROM_JSON,
  UPLOAD_PAGE_JSON,
  UPDATE_DEFAULT_CHECKBOX,
  LOGIN,
  CREATE_HOST,
  UILAYER_IAAS,
  APILAYER_IAAS,
  DBLAYER_IAAS,
  LAYER_HOST,
  CREATE_HOST_API,
} from "./actionTypes";
const userJson = localStorage.getItem("user");

const initialState = {
  user: userJson ? JSON.parse(userJson) : null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_TYPE:
      return {
        ...state,
        config: action.payload,
      };
    case CREATE_PROJECT:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_PAGE:
      return {
        ...state,
        pageDetails: action.payload,
      };
    case CREATE_CUSTOM_PROJECT:
      return {
        ...state,
        config: {
          ...state.config,
          createCustomProject: {
            ...state.config.createCustomProject,
            ...action.payload,
          },
        },
      };
    case UPDATE_DEFAULT_CHECKBOX:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          ...action.payload.projectDetails,
        },
      };
    case UPDATE_TECHNOLOGY:
      return {
        ...state,
        technology: {
          ...state.technology,
          ...action.payload.technology,
        },
      };
    case UPDATE_COMPONENT:
      return {
        ...state,
        component: action.payload.component,
      };
    case UPDATE_PROJECT_DETAILS_FROM_JSON:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_TECHNOLOGY_FROM_JSON:
      return {
        ...state,
        technology: {
          ...state.technology,
          ...action.payload.technology,
        },
      };
    case UPLOAD_PAGE_JSON:
      return {
        ...state,
        pageJson: {
          ...action.payload.pageJson,
        },
      };
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_HOST:
      return {
        ...state,
        host: {
          ...state.host,
          ...action.payload.host
        }
      };
      case CREATE_HOST_API:
        return {
          ...state,
          host: {
            ...state.host,
            ...action.payload.hostApi
          }
        };
      case LAYER_HOST:
        return {
          ...state,
          ...action.payload.layer
        };
    case UILAYER_IAAS:
      return {
        ...state,
        uiIaas: {
          ...state.uiIaas,
          ...action.payload.uiIaas,
        },
      };
    case APILAYER_IAAS:
      return {
        ...state,
        apiIaas: {
          ...state.apiIaas,
          ...action.payload.apiIaas,
        },
      };
    case DBLAYER_IAAS:
      return {
        ...state,
        dbIaas: {
          ...state.dbIaas,
          ...action.payload.dbIaas,
        },
      };

    default:
      return state;
  }
};

export default reducer;
