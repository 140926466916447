import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { Box, Button } from "@mui/material";

import { en } from "../../../dictionary/en";
import HoC from "../../../Hoc";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handlePages } from "../../../Redux/Reducers";
import { validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../../Theme/Theme";

import { getListPageConfig } from "./uitilities";

const ListPageForm = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { newPages } = useAppSelector((state) => state);
    const ListPageFormConfig = getListPageConfig();
    console.log("newPages: ", newPages);
    const formikProps = useFormik({
        initialValues: {
            ...ListPageFormConfig.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a })),
            api: newPages?.pageDetails[0]?.api ?? "",
            toolBarPosition: newPages?.pageDetails[0]?.toolBarPosition ?? "",
            enableMultiRowSelection: newPages?.pageDetails[0]?.enableMultiRowSelection ?? "",
            showFilterButton: newPages?.pageDetails[0]?.showFilterButton ?? "",
            showDownloadButton: newPages?.pageDetails[0]?.showDownloadButton ?? "",
            showRefreshButton: newPages?.pageDetails[0]?.showRefreshButton ?? ""
        },
        onSubmit: (values) => {
            dispatch(handlePages({ pageDetails: [...newPages.pageDetails, values] }));
            handleClose?.();
            toast.success("Field added successfully");
        },
        validate: (values) => {
            return validateFormOnSubmit(values, [ListPageFormConfig]);
        }
    });
    console.log(newPages);
    return (
        // <Box component="form" onSubmit={formikProps.handleSubmit}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <HoC.GenerateForm
                FormData={ListPageFormConfig.map((e: any) => {
                    if (newPages.pageDetails.some((a: any) => a.api !== "") && (e.Name === "api" || e.Categery === "tableSetting")) {
                        return { ...e, Disabled: true, Required: false, Tooltip: "Already  added in first column" };
                    }
                    if (newPages.pageDetails.length && e.Name === "columnOrder") {
                        return { ...e, List: e.List.slice(newPages.pageDetails.length, e.List.length) };
                    }
                    return e;
                })}
                FormikProps={formikProps}
            />
            <Box display="flex" justifyContent="flex-end" py={1} gap={2}>
                <Button variant="contained" color="inherit" onClick={handleClose}>
                    {en.clearbtn}
                </Button>

                <Button style={buttonStyle} onClick={formikProps.submitForm}>
                    {en.savebtn}
                </Button>
            </Box>
        </Box>
        // </Box>
    );
};
export default ListPageForm;
