import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Box, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import SaveButton from "../../../Hoc/Button/SaveButton";
import GenerateForm from "../../../Hoc/GenerateForms";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleHostApiLayer, handleHostDbLayer, handleHostUi } from "../../../Redux/Reducers";
import { PrefixAdder, validateFormOnSubmit } from "../../../ReusableCodes/Resuseablefunctions";

import { VnetPage } from "./Utilities";

const SelectVnetPage = ({ setIsAllSaved, isAllSaved }: any) => {
    const dispatch = useAppDispatch();
    const { hostLayerType, hostUi, hostApiLayer, hostDbLayer } = useAppSelector((state) => state);
    const [afterSave, setAfterSave] = useState(false);
    const layer = hostLayerType?.layer;
    const vmNameUi = hostUi?.virtualMachine?.VmName;
    const vmNameApi = hostApiLayer?.virtualMachine?.VmName;
    const vmNameDb = hostDbLayer?.virtualMachine?.VmName;

    const formikProps = useFormik({
        initialValues: {
            ...VnetPage.reduce((acc: any, field: any) => {
                acc[field.Name] = field.InitialValue;
                return acc;
            }, {})
        },
        onSubmit: async (values) => {
            if (layer === en.isUiLayer)
                dispatch(
                    handleHostUi({
                        virtualMachine: {
                            ...hostUi.virtualMachine,
                            virtualNetwork: values.virtualNetwork
                        }
                    })
                );
            else if (layer === en.isApiLayer)
                dispatch(
                    handleHostApiLayer({
                        virtualMachine: {
                            ...hostApiLayer?.virtualMachine,
                            virtualNetwork: values.virtualNetwork
                        }
                    })
                );
            else if (layer === en.isDbLayer)
                dispatch(
                    handleHostDbLayer({
                        virtualMachine: {
                            ...hostDbLayer?.virtualMachine,
                            virtualNetwork: values.virtualNetwork
                        }
                    })
                );

            setTimeout(() => {
                setAfterSave(true);
            }, 500);
        },
        validate: (values) => {
            setAfterSave(false);
            validateFormOnSubmit(values, [VnetPage]);
        }
    });
    useEffect(() => {
        isAllSaved.SelectVnetPage = afterSave;
        setIsAllSaved({ ...isAllSaved });
    }, [afterSave]);
    useEffect(() => {
        if (layer === en.isUiLayer && hostUi) {
            formikProps.setValues({
                ...formikProps.values,
                ...Object.keys(hostUi).reduce(
                    (acc: any, key: any) => {
                        if (VnetPage.some((field: any) => field.Name === key)) {
                            acc[key] = hostUi?.[key];
                        }
                        return acc;
                    },
                    { virtualNetwork: vmNameUi }
                )
            });
        } else if (layer === en.isApiLayer && hostApiLayer) {
            formikProps.setValues({
                ...formikProps.values,
                ...Object.keys(hostApiLayer).reduce(
                    (acc: any, key: any) => {
                        if (VnetPage.some((field: any) => field.Name === key)) {
                            acc[key] = hostApiLayer?.[key];
                        }
                        return acc;
                    },
                    { virtualNetwork: vmNameApi }
                )
            });
        } else if (layer === en.isDbLayer && hostDbLayer) {
            formikProps.setValues({
                ...formikProps.values,
                ...Object.keys(hostDbLayer).reduce(
                    (acc: any, key: any) => {
                        if (VnetPage.some((field: any) => field.Name === key)) {
                            acc[key] = hostDbLayer?.[key];
                        }
                        return acc;
                    },
                    { virtualNetwork: vmNameDb }
                )
            });
        }
    }, [hostApiLayer, hostDbLayer, hostUi, layer]);
    const VnetName = formikProps?.values?.virtualNetwork;
    formikProps.values.virtualNetwork = PrefixAdder(VnetName, en.vnetPrefix);

    return (
        <Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h6" marginTop="5px">
                    Select Virtual Network
                </Typography>
                <Box px={0.1} py={2}>
                    <GenerateForm FormData={VnetPage} FormikProps={formikProps} />
                </Box>
            </Box>
            <Box sx={{ display: afterSave ? "none" : "inline" }}>
                <SaveButton handleSubmit={formikProps.handleSubmit} />
            </Box>
        </Box>
    );
};

export default SelectVnetPage;
