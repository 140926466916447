import {
    Auth0Credentials,
    createProjectConfig,
    EmailCredentials,
    facebookCredentials,
    FacebookMediaCredentials,
    FtpCredentials,
    GoogleCredentials,
    PaypalCredentials,
    RazorPayCredentials,
    StorageCredentials,
    StripeCredentials,
    VimeoCredentials,
    YoutubeMediaCredentials
} from "../../../inputconfig";
import { componentsType } from "../../../Redux/types";

// auth
export const emailFormikProps = { ...EmailCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a })) };
export const googleFormikProps = {
    ...GoogleCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const auth0FormikProps = {
    ...Auth0Credentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const facebokFormikProps = {
    ...facebookCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
// media
export const youtubeMediaFormikProps = {
    ...YoutubeMediaCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const vimeoMediaFormikProps = {
    ...VimeoCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const facebookMediaFormikProps = {
    ...FacebookMediaCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
// payments
export const razorPayFormikProps = {
    ...RazorPayCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const paypalFormikProps = {
    ...PaypalCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const stripeFormikProps = {
    ...StripeCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
// file upload
export const ftpFormikProps = {
    ...FtpCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};
export const storageFormikProps = {
    ...StorageCredentials.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a }))
};

// validation

const validateEmailLogin = (selectedOptions: any) => {
    for (const options of selectedOptions.Authentication) {
        if (!options?.loginType) {
            return false;
        }
    }
    // if (selectedOptions.Authentication[0]?.email && selectedOptions.Authentication[0]?.password) {
    //     if (selectedOptions.Authentication[0]?.email === "" || selectedOptions.Authentication[0]?.password === "") {
    //         return false;
    //     }
    // } else if (selectedOptions?.Authentication[0]?.loginType === "emailAndOtp") {
    //     return false;
    // }
    return true;
};
const validateFacebookLogin = (selectedOptions: any) => {
    if (selectedOptions.Authentication[0]?.appId && selectedOptions.Authentication[0]?.appSecret && selectedOptions.Authentication[0]?.reDirectUris) {
        if (
            selectedOptions.Authentication[0]?.appId === "" ||
            selectedOptions.Authentication[0]?.appSecret === "" ||
            selectedOptions.Authentication[0]?.reDirectUris === ""
        ) {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validateGoogleLogin = (selectedOptions: any) => {
    console.log(selectedOptions.Authentication[0]?.clientId);
    if (selectedOptions.Authentication[0]?.authenticationType === "google" && selectedOptions.Authentication[0]?.clientId) {
        if (selectedOptions.Authentication[0].clientId === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validateAuth0Login = (selectedOptions: any) => {
    if (selectedOptions.Authentication[0]?.clientId && selectedOptions.Authentication[0]?.domain) {
        if (selectedOptions.Authentication[0]?.clientId === "" || selectedOptions.Authentication[0]?.domain === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};

export const validateAuthentication = (selectedOptions: componentsType) => {
    if (selectedOptions.Authentication[0].authenticationType === createProjectConfig.projectData.components.component1.value1) {
        if (!validateEmailLogin(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Authentication[0].authenticationType === createProjectConfig.projectData.components.component1.value2) {
        if (!validateFacebookLogin(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Authentication[0].authenticationType === createProjectConfig.projectData.components.component1.value3) {
        if (!validateGoogleLogin(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Authentication[0].authenticationType === createProjectConfig.projectData.components.component1.value4) {
        if (!validateAuth0Login(selectedOptions)) {
            return false;
        }
    }
    return true;
};
const validateYoutubeMedia = (selectedOptions: any) => {
    if (selectedOptions.Media[0]?.googleapiKey && selectedOptions.Media[0]?.oauthClientId) {
        if (selectedOptions.Media[0]?.googleapiKey === "" || selectedOptions.Media[0]?.oauthClientId === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validateFacebookMedia = (selectedOptions: any) => {
    if (selectedOptions.Media[0]?.appId && selectedOptions.Media[0]?.appSecret) {
        if (selectedOptions.Media[0]?.appId === "" || selectedOptions.Media[0]?.appSecret === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validateVimeoMedia = (selectedOptions: any) => {
    if (selectedOptions.Media[0]?.clientId && selectedOptions.Media[0]?.accessToken) {
        if (selectedOptions.Media[0]?.clientId === "" || selectedOptions.Media[0]?.accessToken === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};

export const validateMedia = (selectedOptions: any) => {
    if (selectedOptions.Media[0].mediaType === createProjectConfig.projectData.components.component3.value1) {
        if (!validateYoutubeMedia(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Media[0].mediaType === createProjectConfig.projectData.components.component3.value2) {
        if (!validateVimeoMedia(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Media[0].mediaType === createProjectConfig.projectData.components.component3.value3) {
        if (!validateFacebookMedia(selectedOptions)) {
            return false;
        }
    }

    return true;
};

const validateRazorpay = (selectedOptions: any) => {
    if (selectedOptions.Payments[0]?.apiKey && selectedOptions.Payments[0]?.keySecret) {
        if (selectedOptions.Payments[0]?.apiKey === "" || selectedOptions.Payments[0]?.keySecret === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validatePaypal = (selectedOptions: any) => {
    if (selectedOptions.Payments[0]?.clientID && selectedOptions.Payments[0]?.secretKey) {
        if (selectedOptions.Payments[0]?.clientID === "" || selectedOptions.Payments[0]?.secretKey === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validateStripe = (selectedOptions: any) => {
    if (selectedOptions.Payments[0]?.clientID && selectedOptions.Payments[0]?.secretKey) {
        if (selectedOptions.Payments[0]?.clientID === "" || selectedOptions.Payments[0]?.secretKey === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};

export const validatePayment = (selectedOptions: any) => {
    if (selectedOptions.Media[0].paymentType === createProjectConfig.projectData.components.component2.value1) {
        if (!validateRazorpay(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Media[0].paymentType === createProjectConfig.projectData.components.component2.value2) {
        if (!validatePaypal(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions.Media[0].paymentType === createProjectConfig.projectData.components.component2.value3) {
        if (!validateStripe(selectedOptions)) {
            return false;
        }
    }

    return true;
};

const validateFtp = (selectedOptions: any) => {
    if (selectedOptions["File Upload"][0]?.serverAddress && selectedOptions["File Upload"][0]?.clientSecret) {
        if (selectedOptions["File Upload"][0]?.serverAddress === "" || selectedOptions["File Upload"][0]?.clientSecret === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};
const validateStorage = (selectedOptions: any) => {
    if (selectedOptions["File Upload"][0]?.clientID && selectedOptions["File Upload"][0]?.clientSecret) {
        if (selectedOptions["File Upload"][0]?.clientID === "" || selectedOptions["File Upload"][0]?.clientSecret === "") {
            return false;
        }
    } else {
        return false;
    }
    return true;
};

export const validateFileUpload = (selectedOptions: any) => {
    if (selectedOptions["File Upload"][0].fileUploadType === createProjectConfig.projectData.components.component4.value1) {
        if (!validateFtp(selectedOptions)) {
            return false;
        }
    }
    if (selectedOptions["File Upload"][0].fileUploadType === createProjectConfig.projectData.components.component4.value2) {
        if (!validateStorage(selectedOptions)) {
            return false;
        }
    }
    return true;
};
