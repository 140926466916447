/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Delete, Download } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, Input } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import API from "../../API";
// import API from "../../API";
import { en, messages } from "../../dictionary/en";
import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { handleDrawerClose, handlePageData, handlePages, handleRemovePages } from "../../Redux/Reducers";
import { downloadJsonFile } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../Theme/Theme";

import EditPage from "./EditPage";
import { tableHead } from "./Uitilities";

const CreatePage = () => {
    const dispatch = useAppDispatch();
    const { pageData } = useAppSelector((state) => state);
    const [selectedPage, setSelectedPage] = useState(null);
    console.log("setSelectedPage: ", setSelectedPage);
    console.log(selectedPage);
    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState<any>(null);
    console.log("jsonData: ", jsonData);
    const [dataPage, setDataPage] = useState(false);
    console.log("dataPage: ", dataPage);
    const [listPage, setListPage] = useState(false);
    console.log("listPage: ", listPage);
    const [reportPage, setReportPage] = useState(false);
    console.log("reportPage: ", reportPage);
    const [crudPage, setCrudPage] = useState(false);
    console.log("crudPage: ", crudPage);
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const content = e?.target?.result;
                const parsedData = JSON.parse(content as string);
                setJsonData(parsedData);
                if (parsedData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowdataform) {
                    setDataPage(true);
                } else if (parsedData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowlistform) {
                    setListPage(true);
                } else if (parsedData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowreportform) {
                    setReportPage(true);
                } else if (parsedData?.pageType.replace(/\s+/g, "").toLowerCase() === en.lowcrudform) {
                    setCrudPage(true);
                }
            } catch (parseError) {
                console.error(messages.jsonparseerror, parseError);
            }
        };

        reader.readAsText(file);
    };
    const handleEdit = (data: any) => {
        const newPageData: any = pageData?.filter((e: any) => e._id === data._id);
        dispatch(handlePages(newPageData[0]));
        // dispatch(handleDrawerOpen({ open: true, type: en.editPageType }));
        navigate("/managepage/pagetype");
    };
    const handleDelete = async (data: any) => {
        const deletedData = pageData?.filter((project: any) => project._id !== data._id);
        const res: any = await API.Projects.deletePages(data._id);
        if (res?.data) {
            dispatch(handlePageData(deletedData));
        }
        toast.success(`Deleted ${data.pageName.toLowerCase()} page`);
    };
    const handleDownload = (data: any, filename: any) => {
        downloadJsonFile(data, filename);
    };
    const handleClose = () => {
        dispatch(handleDrawerClose());
    };
    React.useEffect(() => {
        dispatch(
            handleRemovePages({
                pageType: "",
                pageName: "",
                pageDetails: []
            })
        );
    }, []);
    return (
        <>
            <HoC.CardComponent>
                <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
                    <Box display="flex" paddingBottom={5} gap={2}>
                        <Button
                            style={buttonStyle}
                            className="NextButton"
                            onClick={() => navigate(RoutesEnum.pageType)}
                            startIcon={<NoteAddIcon />}
                            sx={{
                                fontSize: mediaQuery ? "1rem" : "1.25rem"
                            }}
                        >
                            {en.creatpagebtn}
                        </Button>
                        {/* <label htmlFor="image-upload-input"> */}
                        <Button
                            style={buttonStyle}
                            component="span"
                            sx={{
                                fontSize: mediaQuery ? "1rem" : "1.25rem"
                            }}
                            startIcon={<UploadFileIcon />}
                        >
                            {en.uploadjson}
                        </Button>
                        {/* </label> */}
                        <Input type="file" onChange={handleChange} style={{ display: "none" }} id="image-upload-input" />
                    </Box>
                    <Box component="div" className="scrollOver400px">
                        <HoC.TableComponent
                            tableHead={tableHead}
                            tableData={pageData}
                            editButton={
                                <Button sx={{ justifyContent: "flex-start" }}>
                                    <EditIcon sx={{ color: "#303f9f" }} />
                                </Button>
                            }
                            deleteButton={
                                <Button sx={{ justifyContent: "flex-start" }}>
                                    <Delete sx={{ color: "#303f9f" }} />
                                </Button>
                            }
                            downloadButton={
                                <Button sx={{ justifyContent: "flex-start" }}>
                                    <Download sx={{ color: "#303f9f" }} />
                                </Button>
                            }
                            handleEditData={(data: any) => handleEdit(data)}
                            handleDeleteData={handleDelete}
                            handleDownload={handleDownload}
                        />
                    </Box>
                </Box>
            </HoC.CardComponent>
            <HoC.CustomDrawer type={en.editPageType} title={en.EditProjectTitle} handleDrawerOnClose={handleClose}>
                <EditPage page={selectedPage} />
            </HoC.CustomDrawer>
        </>
    );
};

export default CreatePage;
