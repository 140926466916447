export const getListPageConfig = () => {
    const NumberList = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 100; i++) {
        NumberList.push({ label: `${i}`, value: i });
    }
    const ListPageFormConfig: any = [
        {
            Label: "Data Source",
            Required: false,
            Name: "dataSource",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Data Source",
            ErrorMessage: "Data Source is required*",
            Tooltip: "Data Source",
            HelperText: "",
            Type: "header",
            List: null,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "API",
            Required: false,
            Name: "api",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter valid API",
            ErrorMessage: "API is required*",
            Tooltip: "API",
            HelperText: "",
            Type: "text",
            List: null,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Columns",
            Required: false,
            Name: "columns",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter columns",
            ErrorMessage: "columns is required*",
            Tooltip: "Columns",
            HelperText: "",
            Type: "header",
            List: null,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Column Name",
            Required: true,
            Name: "columnName",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Column Name",
            ErrorMessage: "Column Name is required*",
            Tooltip: "Column Name",
            HelperText: "",
            Type: "text",
            List: null,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Column Type",
            Required: true,
            Name: "columnType",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Column Type",
            ErrorMessage: "Column Type is required*",
            Tooltip: "Column Type",
            HelperText: "",
            Type: "select",
            List: [
                { label: "String", value: "string" },
                { label: "Number", value: "number" },
                { label: "Date", value: "date" },
                { label: "Boolean", value: "boolean" }
            ],
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Column Value",
            Required: true,
            Name: "columnValue",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Column Value",
            ErrorMessage: "Column Value is required*",
            Tooltip: "Column Value",
            HelperText: "",
            Type: "text",
            List: null,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Column Order",
            Required: true,
            Name: "columnOrder",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Column Order",
            ErrorMessage: "ColumnOrder is Required",
            Tooltip: "",
            HelperText: "",
            Type: "select",
            List: NumberList,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Column Pinning",
            Required: false,
            Name: "pinColumn",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select column pinning",
            ErrorMessage: "Column Type is required*",
            Tooltip: "Column Pinning",
            HelperText: "",
            Type: "select",
            List: [
                { label: "Left", value: "left" },
                { label: "Right", value: "right" }
            ],
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Column Alignment",
            Required: true,
            Name: "alignment",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Column Alignment",
            ErrorMessage: "Column Alignment is required*",
            Tooltip: "Select Column Alignment",
            HelperText: "",
            Type: "select",
            List: [
                { label: "Left", value: "left" },
                { label: "Right", value: "right" },
                { label: "Center", value: "center" }
            ],
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Enable Filter",
            Required: true,
            Name: "enableFilter",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Enable Filters",
            ErrorMessage: "Enable Filters is required*",
            Tooltip: "Enable Filters",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Enable sorting",
            Required: true,
            Name: "enableSorting",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Enable sorting",
            ErrorMessage: "Enable sorting is required*",
            Tooltip: "Enable sorting",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Hide Column",
            Required: false,
            Name: "hideColumn",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Hide Column",
            ErrorMessage: "Hide Column is required*",
            Tooltip: "Hide Column",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Toolbar",
            Required: false,
            Name: "",
            InitialValue: "",
            Regex: [],
            RegExError: "Please enter Toolbar",
            ErrorMessage: "Toolbar is required*",
            Tooltip: "Toolbar",
            HelperText: "",
            Type: "header",
            List: null,
            Categery: "GeneratePage",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Position",
            Required: false,
            Name: "toolBarPosition",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Position",
            ErrorMessage: "Position is Required",
            Tooltip: "Toolbar Position",
            HelperText: "",
            Type: "select",
            List: [
                { label: "Top", value: "top" },
                { label: "Bottom", value: "bottom" }
            ],
            Categery: "tableSetting",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Enable Multi Row Selection",
            Required: false,
            Name: "enableMultiRowSelection",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Enable Multi Row Selection",
            ErrorMessage: "Enable Multi Row Selection is required*",
            Tooltip: "Enable Multi Row Selection",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Show Filter Button",
            Required: false,
            Name: "showFilterButton",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Show Filter Button",
            ErrorMessage: "Show Filter Button is required*",
            Tooltip: "Show Filter Button",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Show Download Button",
            Required: false,
            Name: "showDownloadButton",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Show Download Button",
            ErrorMessage: "Show Download Button is required*",
            Tooltip: "Show Download Button",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        },
        {
            Label: "Show Refresh Button",
            Required: false,
            Name: "showRefreshButton",
            InitialValue: "",
            Regex: [],
            RegExError: "Please select Show Refresh Button",
            ErrorMessage: "Show Refresh Button is required*",
            Tooltip: "Show Refresh Button",
            HelperText: "",
            Type: "checkbox",
            List: [],
            Categery: "tableSetting",
            Group: "List_Data",
            MinValue: null,
            MaxValue: null,
            Multiple: 0,
            PlaceHolder: "",
            Disabled: false
        }
    ];
    return ListPageFormConfig;
};
