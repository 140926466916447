export const ServicesLayer: any = [
    {
        Label: "Name",
        Required: true,
        Name: "azureCacheName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Cache Name",
        ErrorMessage: " Name is required*",
        Tooltip: "Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Region",
        Required: true,
        Name: "redisCacheRegion",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Region",
        ErrorMessage: "Region is required*",
        Tooltip: "Region Selection",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Australia East", value: "Australia East" },
            { label: "Australia Central", value: "Australia Central" },
            { label: "Australia Southeast", value: "Australia Southeast" },
            { label: "Brazil South", value: "Brazil South" },
            { label: "Canada East", value: "Canada East" },
            { label: "Canada Central", value: "Canada Central" },
            { label: "Central US", value: "Central US" },
            { label: "Central India", value: "Central India" },
            { label: "East US", value: "East US" },
            { label: "East Asia", value: "East Asia" },
            { label: "East US 2", value: "East US 2" },
            { label: "France Central", value: "France Central" },
            { label: "Germany West Central", value: "Germany West Central" },
            { label: "Italy North", value: "Italy North" },
            { label: "Norway East", value: "Norway East" },
            { label: "Japan East", value: "Japan East" },
            { label: "Japan West", value: "Japan West" },
            { label: "Korea Central", value: "Korea Central" },
            { label: "Korea South", value: "Korea South" },
            { label: "North Central US", value: "North Central US" },
            { label: "North Europe", value: "North Europe" },
            { label: "Norway West", value: "Norway West" },
            { label: "Poland Central", value: "Poland Central" },
            { label: "Qatar Central", value: "Qatar Central" },
            { label: "South Africa North", value: "South Africa North" },
            { label: "South Central US", value: "South Central US" },
            { label: "South India", value: "South India" },
            { label: "Southeast Asia", value: "Southeast Asia" },
            { label: "Sweden Central", value: "Sweden Central" },
            { label: "Switzerland North", value: "Switzerland North" },
            { label: "UAE North", value: "UAE North" },
            { label: "UK South", value: "UK South" },
            { label: "UK West", value: "UK West" },
            { label: "West Central US", value: "West Central US" },
            { label: "West Europe", value: "West Europe" },
            { label: "West India", value: "West India" },
            { label: "West US", value: "West US" },
            { label: "West US 2", value: "West US 2" },
            { label: "West US 3", value: "West US 3" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Cache Type",
        Required: true,
        Name: "cacheType",
        InitialValue: "",
        Regex: [
            // "^(?!.*[/\"\\[\\]:|<>+=;,?*@&\\s]|^_|_$|[.-]$)(?!administrator|admin|user|user1|test|user2|test1|user3|admin1|1|123|a|actuser|adm|admin2|aspnet|backup|console|david|guest|john|owner|root|server|sql|support|support_388945a0|sys|test2|test3|user4|user5)[^\s]{1,64}$"
        ],

        RegExError: "Please Enter Cache Type",
        ErrorMessage: "",
        Tooltip: " Enter Cache Type ",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Basic C0", value: "Basic C0" },
            { label: "Basic C1", value: "Basic C1" },
            { label: "Basic C2", value: "Basic C2" },
            { label: "Basic C3", value: "Basic C3" },
            { label: "Basic C4", value: "Basic C4" },
            { label: "Basic C5", value: "Basic C5" },
            { label: "Basic C6", value: "Basic C6" },
            { label: "Standard C0", value: "Standard C0" },
            { label: "Standard C1", value: "Standard C1" },
            { label: "Standard C2", value: "Standard C2" },
            { label: "Standard C3", value: "Standard C3" },
            { label: "Standard C4", value: "Standard C4" },
            { label: "Standard C5", value: "Standard C5" },
            { label: "Standard C6", value: "Standard C6" },
            { label: "Premium P1", value: "Premium P1" },
            { label: "Premium P2", value: "Premium P2" },
            { label: "Premium P3", value: "Premium P3" },
            { label: "Premium P4", value: "Premium P4" },
            { label: "Premium P5", value: "Premium P5" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
export const StorageSelection: any = [
    {
        Label: "Storage Name",
        Required: true,
        Name: "storageName",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Storage Name",
        ErrorMessage: " Storage Name is required*",
        Tooltip: "Storage Name",
        HelperText: "",
        Type: "text",
        List: [],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Tier",
        Required: true,
        Name: "storageTier",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Tier",
        ErrorMessage: " Storage Tier is required*",
        Tooltip: "Storage Tier",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Hot", value: "hot" },
            { label: "Cool", value: "cool" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: "Performance-Redundance",
        Required: true,
        Name: "performanceRedundance",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Enter Performance-Redundance",
        ErrorMessage: " Performance-Redundance is required*",
        Tooltip: "Performance-Redundance",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Premium_LRS", value: "Premium_LRS" },
            { label: "Premium_LRS", value: "Premium_LRS" },
            { label: "Standard_GRS", value: "Standard_GRS" },
            { label: "Standard_GZRS", value: "Standard_GZRS" },
            { label: "Standard_LRS", value: "Standard_LRS" },
            { label: "Standard_RAGRS", value: "Standard_RAGRS" },
            { label: "Standard_RAGZRS", value: "Standard_RAGZRS" },
            { label: "Standard_ZRS", value: "Standard_ZRS" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    },
    {
        Label: " Region",
        Required: true,
        Name: "storageAccountRegion",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Region",
        ErrorMessage: "Region is required*",
        Tooltip: "Region Selection",
        HelperText: "",
        Type: "select",
        List: [
            { label: "Australia East", value: "Australia East" },
            { label: "Australia Central", value: "Australia Central" },
            { label: "Australia Southeast", value: "Australia Southeast" },
            { label: "Brazil South", value: "Brazil South" },
            { label: "Canada East", value: "Canada East" },
            { label: "Canada Central", value: "Canada Central" },
            { label: "Central US", value: "Central US" },
            { label: "Central India", value: "Central India" },
            { label: "East US", value: "East US" },
            { label: "East Asia", value: "East Asia" },
            { label: "East US 2", value: "East US 2" },
            { label: "France Central", value: "France Central" },
            { label: "Germany West Central", value: "Germany West Central" },
            { label: "Italy North", value: "Italy North" },
            { label: "Norway East", value: "Norway East" },
            { label: "Japan East", value: "Japan East" },
            { label: "Japan West", value: "Japan West" },
            { label: "Korea Central", value: "Korea Central" },
            { label: "Korea South", value: "Korea South" },
            { label: "North Central US", value: "North Central US" },
            { label: "North Europe", value: "North Europe" },
            { label: "Norway West", value: "Norway West" },
            { label: "Poland Central", value: "Poland Central" },
            { label: "Qatar Central", value: "Qatar Central" },
            { label: "South Africa North", value: "South Africa North" },
            { label: "South Central US", value: "South Central US" },
            { label: "South India", value: "South India" },
            { label: "Southeast Asia", value: "Southeast Asia" },
            { label: "Sweden Central", value: "Sweden Central" },
            { label: "Switzerland North", value: "Switzerland North" },
            { label: "UAE North", value: "UAE North" },
            { label: "UK South", value: "UK South" },
            { label: "UK West", value: "UK West" },
            { label: "West Central US", value: "West Central US" },
            { label: "West Europe", value: "West Europe" },
            { label: "West India", value: "West India" },
            { label: "West US", value: "West US" },
            { label: "West US 2", value: "West US 2" },
            { label: "West US 3", value: "West US 3" }
        ],
        Categery: "Generate Page",
        Group: "VM_Data",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
