import React from "react";

import { Button } from "@mui/material";

import { en } from "../../../dictionary/en";
import { useAppSelector } from "../../../Redux/Hooks";
import { DownloadText } from "../../../ReusableCodes/Resuseablefunctions";

const ServicesBiscript = () => {
    const { hostServiceLayer } = useAppSelector((state) => state);
    const storageName = hostServiceLayer?.storageName;
    const storageTier = hostServiceLayer?.storageTier?.value;
    const performanceRedundance = hostServiceLayer?.performanceRedundance?.value;
    const regionStorage = hostServiceLayer?.storageAccountRegion?.value;
    const serviceName = hostServiceLayer?.serviceName;
    const redisName = hostServiceLayer?.azureCacheName;
    const regionRedis = hostServiceLayer?.redisCacheRegion?.value;
    const cacheType = hostServiceLayer?.cacheType?.value;
    const isStorage = serviceName === en.storageAccountService;
    let content = "";
    if (isStorage)
        content = `
    Provide me a azure bicep script for creating a storage account named ${storageName} with ${performanceRedundance} as SKU and tier as ${storageTier} in the region ${regionStorage}.
  `;
    else {
        content = `Provide me a bicep script for creating a azure cache for redis named ${redisName} in the region ${regionRedis}, with ${cacheType} pricing tier.`;
    }

    const handleDownloadClick = () => {
        DownloadText(content, storageName);
    };

    return (
        <div style={{ margin: "200px" }}>
            <p>{content}</p>
            <Button onClick={handleDownloadClick}>Integrate ChatGPT</Button>
        </div>
    );
};

export default ServicesBiscript;
