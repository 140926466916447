import * as React from "react";

import { Box, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import { switchProps } from "./types";

const SwitchComponent: React.FC<switchProps> = ({ checked, handleSwitch, label, labelPlacement }) => {
    return (
        <Box>
            <FormControlLabel
                control={<Switch sx={{ m: 1 }} onChange={handleSwitch} checked={checked} />}
                label={label || ""}
                labelPlacement={labelPlacement || "end"}
            />
        </Box>
    );
};

export default SwitchComponent;
