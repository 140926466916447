import React, { useState } from "react";
import { toast } from "react-toastify";

import InsertPageBreakOutlinedIcon from "@mui/icons-material/InsertPageBreakOutlined";
import { Box, Button, Grid, Input, Typography } from "@mui/material";

import upload from "../../assets/upload.png";
import { en } from "../../dictionary/en";
import ConfigEnum from "../../enums/Config.enum";
import HoC from "../../Hoc";
import { uploadJSON } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../Theme/Theme";
import CloningSourceCode from "../codeCloning/CodeCloning";

const GenerateProject = () => {
    const [jsonData, setJsonData] = useState<any>(null); // Adjust the type as needed
    const buttonStyles = {
        ...buttonStyle,
        width: "min-content",
        borderRadius: "15px"
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            await uploadJSON(event, ConfigEnum.uploadProjectJson)
                .then((validJson: any) => {
                    setJsonData(validJson);
                })
                .catch((error: string) => {
                    console.log("Upload Project Json Error :", error);
                    toast.error(error);
                });
        } catch (error: any) {
            console.log("Upload Project Json Error :", error);
        }
    };

    return (
        <Box component="div">
            <HoC.CardComponent>
                <Box component="div" p={5}>
                    <Grid container height="100%">
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h4">{en.genprojectbtn}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={5} className="cardComponentInsideWrapper" sx={{ width: "auto" }}>
                                <Grid
                                    container
                                    sx={{
                                        gap: "40px",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        alignContent: "center"
                                    }}
                                >
                                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                                        <Typography variant="h6">{en.uploadprojectbtn}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
                                        <Button style={buttonStyles} component="label">
                                            <img src={upload} width="40px" alt="Upload" />
                                            &nbsp;
                                            <span>Upload</span>
                                            <Input
                                                type="file"
                                                onChange={handleChange}
                                                sx={{ color: "white", visibility: "hidden" }}
                                                disableUnderline
                                            />
                                        </Button>
                                    </Grid>
                                    {jsonData ? (
                                        <Grid
                                            item
                                            xs={8}
                                            sx={{
                                                textAlign: "center",
                                                border: "2px dotted #303f9f",
                                                marginTop: "5px",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                                padding: "8px",
                                                backgroundColor: "#e8f4ff",
                                                borderRadius: "8px",
                                                paddingBottom: "0px",
                                                width: "100%",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <Typography variant="h6">
                                                <InsertPageBreakOutlinedIcon sx={{ width: "25px" }} />
                                            </Typography>
                                            <Grid sx={{ width: "100%", overflow: "hidden" }}>
                                                <Typography sx={{ fontSize: "medium" }}>{jsonData?.projectDetails?.projectName}</Typography>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                            <CloningSourceCode jsonData={jsonData} />
                        </Grid>
                    </Grid>
                </Box>
            </HoC.CardComponent>
        </Box>
    );
};

export default GenerateProject;
