import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CrudForm from "./Component/Form/CrudPage/CrudForm";
import EditData from "./Component/Form/Editpage/EditDataForm";
import EditList from "./Component/Form/Editpage/EditListForm";
import EditReport from "./Component/Form/Editpage/EditReportForm";
import ListForm from "./Component/Form/ListPage/ListForm";
import ReportForm from "./Component/Form/ReportForm";
import GeneratePage from "./Component/GeneratePage/GeneratePage";
import GenerateProject from "./Component/GenerateProject/GenerateProject";
import Home from "./Component/Home";
import BiScriptAppServices from "./Component/Host/AppServices/WebAppBiscript";
import WebAppPlanPage from "./Component/Host/AppServices/WebAppDetails";
import BicryptTextFunctions from "./Component/Host/Functions/FunctionBiscript";
import FunctionAppPlanPage from "./Component/Host/Functions/FunctionDetails";
import FunctionAppHost from "./Component/Host/Functions/FunctionOtherInfo";
import FunctionVmPlan from "./Component/Host/Functions/FunctionVNet";
import SelectAppType from "./Component/Host/SelectAppType";
import ServicesHost from "./Component/Host/ServicesLayer";
import SelectService from "./Component/Host/ServicesLayer/SelectServices";
import ServicesBiscript from "./Component/Host/ServicesLayer/ServicesBiscript";
import StorageAccountPage from "./Component/Host/ServicesLayer/StorageAccount";
import DbSqlFunction from "./Component/Host/VirtualMachine/DBSql";
import BicryptText from "./Component/Host/VirtualMachine/VmBiscript";
import AppServicePlan from "./Component/Host/VirtualMachine/VmPlan";
import Login from "./Component/Login";
import ManagePage from "./Component/ManagePage/ManagePage";
import PageForm from "./Component/ManagePage/PageForm";
import PageType from "./Component/ManagePage/PageType";
import BrandingAndLogo from "./Component/ManageProject/BrandingAndLogo";
import SelectComponent from "./Component/ManageProject/Components";
import ManageProject from "./Component/ManageProject/ManageProject";
import SelectProjectName from "./Component/ManageProject/ProjectDetails";
import Technology from "./Component/ManageProject/Technologies";
import Navbar from "./Component/Navbar/intex";
import RoutesEnum from "./enums/Routes.enum";
import { GetPages, GetProjects } from "./Redux/AsyncThunk";
import { useAppDispatch, useAppSelector } from "./Redux/Hooks";
import { handleUser } from "./Redux/Reducers";
import { Spinner } from "./Theme/Theme";
import TestingArea from "./Testing";

import "./App.css";

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const {
        userData: { user },
        loaderState: { loading }
    } = useAppSelector((state) => state);
    const getInitialApis = async () => {
        await dispatch(GetProjects());
        await dispatch(GetPages());
    };
    useEffect(() => {
        getInitialApis();
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const usedata = localStorage.getItem("user");
        const userDetails = {
            token: JSON.parse(token!),
            user: JSON.parse(usedata!)
        };
        if (token && usedata) {
            dispatch(handleUser(userDetails));
        }
    }, []);
    return (
        <Router>
            <Navbar />
            {loading ? (
                <Spinner loading={loading} />
            ) : (
                <Routes>
                    <Route path={RoutesEnum.home} element={<Home />} />
                    {!user ? (
                        <Route path={RoutesEnum.login} element={<Login />} />
                    ) : (
                        <>
                            {/* Manage Project flow */}
                            <Route path={RoutesEnum.manageProject} element={<ManageProject />} />
                            <Route path={RoutesEnum.generateProject} element={<GenerateProject />} />
                            <Route path={RoutesEnum.technologies} element={<Technology />} />
                            <Route path={RoutesEnum.appComponents} element={<SelectComponent />} />
                            <Route path={RoutesEnum.branding} element={<BrandingAndLogo />} />
                            {/* Manage Page flow */}
                            <Route path={RoutesEnum.managePage} element={<ManagePage />} />
                            <Route path={RoutesEnum.pageType} element={<PageType />} />
                            <Route path={RoutesEnum.pageForm} element={<PageForm />} />
                            <Route path={RoutesEnum.generatePage} element={<GeneratePage />} />
                            {/* <Route path={RoutesEnum.selectProject} element={<SelectProject />} /> */}
                            <Route path={RoutesEnum.listcapture} element={<ListForm />} />
                            <Route path={RoutesEnum.reportcapture} element={<ReportForm />} />
                            <Route path={RoutesEnum.crudcapture} element={<CrudForm />} />
                            <Route path={RoutesEnum.projectDetails} element={<SelectProjectName />} />
                            <Route path={RoutesEnum.editData} element={<EditData onPropsHandleChange={() => {}} />} />
                            <Route path={RoutesEnum.editList} element={<EditList onPropsHandleChange={() => {}} />} />
                            <Route path={RoutesEnum.editReport} element={<EditReport onPropsHandleChange={() => {}} />} />
                            <Route path={RoutesEnum.uiLayerVmPlan} element={<AppServicePlan />} />
                            <Route path={RoutesEnum.selectAppType} element={<SelectAppType />} />
                            <Route path={RoutesEnum.functionOtherInfo} element={<FunctionAppHost />} />
                            <Route path={RoutesEnum.webAppDetails} element={<WebAppPlanPage />} />
                            <Route path={RoutesEnum.functionAppDetails} element={<FunctionAppPlanPage />} />
                            <Route path={RoutesEnum.functionVNet} element={<FunctionVmPlan />} />
                            <Route path={RoutesEnum.vmBiscript} element={<BicryptText />} />
                            <Route path={RoutesEnum.appServicesBiScript} element={<BiScriptAppServices />} />
                            <Route path={RoutesEnum.functionsBiScript} element={<BicryptTextFunctions />} />
                            <Route path={RoutesEnum.dbSql} element={<DbSqlFunction />} />
                            <Route path={RoutesEnum.servicesLayer} element={<SelectService />} />
                            <Route path={RoutesEnum.servicesAzureCache} element={<ServicesHost />} />
                            <Route path={RoutesEnum.servicesStorageAccount} element={<StorageAccountPage />} />
                            <Route path={RoutesEnum.servicesBiscript} element={<ServicesBiscript />} />
                        </>
                    )}

                    {/* Testing Area */}
                    <Route path={RoutesEnum.testingArea} element={<TestingArea />} />
                </Routes>
            )}
        </Router>
    );
};

export default App;
