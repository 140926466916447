import axios from "axios";

import { UpdateLoggedIn } from "../Reduxt/action";
import store from "../Reduxt/store";

const useAxiosPrivate = () => {
    const baseURL = process.env.REACT_APP_API_BASEURL;
    const token = localStorage.getItem("token") || "";
    const axiosPrivate = axios.create({ baseURL, headers: { token: token ? JSON.parse(token!) : "" } });

    axiosPrivate.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        (error) => {
            console.log("from error");
            console.log(error.response.status);
            if (error.response.status === 403) {
                store.dispatch(UpdateLoggedIn({ user: null, token: null }));
                localStorage.removeItem("token");
                localStorage.removeItem("user");
            }
            return Promise.reject(error);
        }
    );
    return axiosPrivate;
};
export default useAxiosPrivate;
