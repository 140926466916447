import api from "../../assets/api.png";
import database from "../../assets/database.png";
import generate from "../../assets/generate.png";
import Logo from "../../assets/Logo.png";
import managePage from "../../assets/managePage.png";
import manageProject from "../../assets/manageProject.png";
import scheduled from "../../assets/scheduled.png";
import services from "../../assets/services.png";
import ui from "../../assets/ui.png";
import RoutesEnum from "../../enums/Routes.enum";

export const SideNavMenu = {
    logo: <img src={Logo} alt="Logo" style={{ width: "100px" }} />,
    menus: [
        {
            parentMenuText: "Projects",
            childMenuText: "Manage Projects",
            childMenuIcon: <img src={manageProject} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Projects" />,
            path: RoutesEnum.manageProject
        },
        {
            childMenuText: "Generate Projects",
            childMenuIcon: <img src={generate} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Manage Project" />,
            path: RoutesEnum.generateProject
        },
        {
            parentMenuText: "Pages",
            childMenuText: "Manage Pages",
            childMenuIcon: <img src={managePage} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Pages" />,
            path: RoutesEnum.managePage
        },
        {
            childMenuText: "Generate Pages",
            childMenuIcon: <img src={generate} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Manage Page" />,
            path: RoutesEnum.generatePage
        },
        {
            parentMenuText: "Host",
            childMenuText: "UI Layer",
            childMenuIcon: <img src={ui} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="UI Layer" />,
            path: RoutesEnum.selectAppType
        },
        {
            childMenuText: "API Layer",
            childMenuIcon: <img src={api} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="API Layer" />,
            path: RoutesEnum.selectAppType
        },
        {
            childMenuText: "Services Layer",
            childMenuIcon: <img src={services} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Services Layer" />,
            path: RoutesEnum.servicesLayer
        },
        {
            childMenuText: "Database Layer",
            childMenuIcon: <img src={database} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Database Layer" />,
            path: RoutesEnum.selectAppType
        },
        {
            childMenuText: "Scheduled Jobs",
            childMenuIcon: <img src={scheduled} style={{ width: "19px", marginRight: "5px", fontWeight: "bold" }} alt="Scheduled Jobs" />
        }
    ]
};
