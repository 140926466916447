import React from "react";

import { Button, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import { BackButtonStyle } from "../../../Theme/Theme";

import { BackButtonProps } from "./types";

const BackButton: React.FC<BackButtonProps> = ({ handleClick, buttonText }) => {
    return (
        <Button variant="outlined" onClick={handleClick} size="small" style={BackButtonStyle}>
            <Typography>{buttonText || en.backbtn}</Typography>
        </Button>
    );
};

export default BackButton;
