import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, Button, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import RoutesEnum from "../../enums/Routes.enum";
import HoC from "../../Hoc";
import { PageData } from "../../inputconfig";
import { useAppSelector } from "../../Redux/Hooks";
import { handlePages } from "../../Redux/Reducers";
import { validateFormOnSubmit } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle, mediumText, unSelectedButton } from "../../Theme/Theme";

import { pageTypeButtons } from "./Uitilities";

const PageType = () => {
    const { newPages } = useAppSelector((state: any) => state);
    console.log("newPages: ", newPages);
    const dispatch = useDispatch();
    // const [inputValue, setInputValue] = useState<any>(newPages);
    const [IsBackend, setIsBackend] = useState(newPages?.isBackend ?? false);
    const [pageData, setPageData] = useState<any>({ pageType: newPages?.pageType });
    const navigate = useNavigate();
    const mediaQuery: any = useMediaQuery("(max-width:1750px)");

    const formikProps = useFormik({
        initialValues: {
            ...PageData.map((e: any) => ({ [e.Name]: e.InitialValue })).reduce((a: any, b = {}) => ({ ...b, ...a })),
            ...newPages
        },
        onSubmit: async (values) => {
            if (!pageData?.pageType) {
                toast.error("Please select page type");
            }
            dispatch(handlePages({ ...values, ...pageData, isBackend: IsBackend }));
            if (pageData?.pageType === "DataForm") {
                navigate(RoutesEnum.pageForm);
            } else if (pageData?.pageType === "ListForm") {
                navigate(RoutesEnum.listcapture);
            } else if (pageData?.pageType === "ReportForm") {
                navigate(RoutesEnum.reportcapture);
            } else if (pageData?.pageType === "CrudForm") {
                navigate(RoutesEnum.crudcapture);
            }
        },
        validate: (values) => {
            return validateFormOnSubmit(values, [PageData]);
        }
    });

    return (
        <Box component="form" onSubmit={formikProps.handleSubmit}>
            <HoC.CardComponent cardTitle="Page Type" buttonType="submit" handleNext={() => null} handleBack={() => navigate(RoutesEnum.managePage)}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: mediaQuery ? "370px" : "100%" }}>
                    <Box px={4} py={2} height="500px" display="flex" flexDirection="column" sx={{ overflow: mediaQuery ? "auto" : "visible" }}>
                        {/* <Box width="100%" display="flex" justifyContent="space-between" paddingY={5}> */}
                        <Grid container spacing={4} height="40%" pt={2} sx={{ textAlign: "center" }}>
                            {pageTypeButtons?.map((button: any) => {
                                return (
                                    <Grid item xs={3}>
                                        <Button
                                            style={pageData?.pageType === button?.pageType ? buttonStyle : unSelectedButton}
                                            onClick={() => {
                                                setPageData({ pageType: button?.pageType });
                                                formikProps.setFieldValue("pageName", "");
                                                formikProps.setFieldValue("pagePath", "");
                                                formikProps.setFieldValue("childMenuText", "");
                                            }}
                                            className="page-type-button"
                                        >
                                            <Typography style={mediumText}>{button.buttonText}</Typography>
                                        </Button>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Box sx={{ margin: "auto 0" }}>
                            <HoC.GenerateForm FormData={PageData} FormikProps={formikProps} />
                            <Box>
                                <HoC.SwitchComponent
                                    checked={IsBackend}
                                    handleSwitch={() => (IsBackend ? setIsBackend(false) : setIsBackend(true))}
                                    label="Is Backend Required?"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </HoC.CardComponent>
        </Box>
    );
};
export default PageType;
