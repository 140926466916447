export const LoginProps: any = [
    {
        Label: "Email",
        Required: true,
        Name: "email",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter your Email",
        ErrorMessage: "Field Email is required*",
        Tooltip: "Enter Your Email",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Login",
        Group: "Login",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "Email",
        Disabled: false
    },
    {
        Label: "Password",
        Required: true,
        Name: "password",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter your Password",
        ErrorMessage: "Field Password is required*",
        Tooltip: "Enter Your Password",
        HelperText: "",
        Type: "password",
        List: null,
        Categery: "Login",
        Group: "Login",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        PlaceHolder: "",
        Disabled: false
    }
];
