import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Box, Button, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { en } from "../../../dictionary/en";
import RoutesEnum from "../../../enums/Routes.enum";
import HoC from "../../../Hoc";
import GenerateForm from "../../../Hoc/GenerateForms";
import { createProjectConfig, mongoDBCredentialsForm, MsSqlCredentialsForm, MySqlCredentialsForm } from "../../../inputconfig";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks";
import { handleDrawerClose, handleDrawerOpen, handleProjects } from "../../../Redux/Reducers";
import { buttonStyle } from "../../../Theme/Theme";
import { backendOption, databaseOptions, frontendOption } from "../Uitilities";

import { optionType } from "./types";

const Technology = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { newProject } = useAppSelector((state) => state);
    const [selectedOptions, setSelectedOptions] = useState({
        frontend: newProject.technology.frontend || "reactJs",
        api: newProject.technology.api || "nodeJs",
        database: newProject.technology.database
    });
    const mediaQuery: any = useMediaQuery("(max-width:1400px)");
    const [selectedComponent, setSelectedComponent] = useState(newProject?.technology?.database?.databaseName || "");
    const { projectData } = createProjectConfig;
    const initialValues =
        selectedComponent === projectData.databaseValue.technology1Value
            ? {
                  ...mongoDBCredentialsForm
                      .map((e: any) => ({ [e.Name]: selectedOptions.database[e.Name] || e.InitialValue }))
                      .reduce((a: any, b = {}) => ({ ...b, ...a })),
                  ...newProject?.technology?.database
              }
            : selectedComponent === projectData.databaseValue.technology3Value
            ? {
                  ...MsSqlCredentialsForm.map((e: any) => ({ [e.Name]: selectedOptions.database[e.Name] || e.InitialValue })).reduce(
                      (a: any, b = {}) => ({ ...b, ...a })
                  ),
                  ...newProject?.technology?.database
              }
            : selectedComponent === projectData.databaseValue.technology2Value
            ? {
                  ...MySqlCredentialsForm.map((e: any) => ({ [e.Name]: selectedOptions.database[e.Name] || e.InitialValue })).reduce(
                      (a: any, b = {}) => ({ ...b, ...a })
                  ),
                  ...newProject?.technology?.database
              }
            : {};
    const formikProps = useFormik({
        initialValues,
        onSubmit: async (values) => {
            setSelectedOptions((prev) => ({ ...prev, database: { ...prev.database, ...values } }));
            dispatch(handleDrawerClose());
        }
    });
    const handleDBChange = (value: any) => {
        if (selectedOptions.database.databaseName !== value) {
            formikProps.resetForm();
            setSelectedOptions((prev) => ({ ...prev, database: { databaseName: value } }));
            setSelectedComponent(value);
        }
    };
    const handleDrawer = () => {
        dispatch(handleDrawerOpen({ open: true, type: "DATABASE_CREDENTIALS" }));
    };
    // eslint-disable-next-line consistent-return
    const handleNext = () => {
        let validate = false;
        console.log(selectedOptions.database?.connection_String);
        console.log(selectedOptions);
        if (selectedOptions.frontend === "") {
            toast.error("Select an option");
            validate = true;
        }
        if (selectedOptions.api === "") {
            toast.error("Select an option");
            validate = true;
        }
        if (selectedOptions.database.databaseName === createProjectConfig?.projectData?.databaseValue?.technology1Value) {
            if (selectedOptions.database?.connection_String) {
                if (selectedOptions.database?.connection_String === "") {
                    toast.error("Please fill the credentials");
                    handleDrawer();
                    validate = true;
                }
            } else {
                toast.error("Please fill the credentials");
                handleDrawer();
                validate = true;
            }
        }
        if (selectedOptions.database.databaseName === createProjectConfig?.projectData?.databaseValue?.technology2Value) {
            if (selectedOptions.database?.hostName) {
                if (selectedOptions.database?.hostName === "") {
                    toast.error("Please fill the credentials");
                    handleDrawer();
                    validate = true;
                }
            } else {
                toast.error("Please fill the credentials");
                handleDrawer();
                validate = true;
            }
        }
        if (selectedOptions.database.databaseName === createProjectConfig?.projectData?.databaseValue?.technology3Value) {
            if (selectedOptions.database?.servername) {
                if (selectedOptions.database?.servername === "") {
                    toast.error("Please fill the credentials");
                    handleDrawer();
                    validate = true;
                }
            } else {
                toast.error("Please fill the credentials");
                handleDrawer();
                validate = true;
            }
        }
        if (selectedOptions.frontend !== createProjectConfig?.projectData?.frontendValue?.technology1Value) {
            toast.info(`${selectedOptions.frontend.toLocaleUpperCase()} Under Development`);
            validate = true;
        }
        if (selectedOptions.api !== createProjectConfig?.projectData?.backendValue?.technology1Value) {
            toast.info(`${selectedOptions.api.toLocaleUpperCase()} Under Development`);
            validate = true;
        }
        if (validate) {
            return;
        }
        dispatch(handleProjects({ technology: { ...newProject.technology, ...selectedOptions } }));
        navigate(RoutesEnum.appComponents);
    };
    const handleBack = () => {
        navigate(RoutesEnum.manageProject);
    };

    // intial valuses need to add conditions according to the dbform

    return (
        <HoC.CardComponent cardTitle="Technology" handleBack={handleBack} handleNext={handleNext}>
            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: mediaQuery ? "370px" : "100%" }}>
                <Box px={4} py={2} sx={{ overflow: mediaQuery && "auto", paddingTop: mediaQuery && "0px", paddingBottom: mediaQuery && "0px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Frontend</Typography>
                                </Grid>
                                {frontendOption.map((option: optionType) => (
                                    <Grid item sm={3} sx={{ paddingTop: mediaQuery && "8px!important" }}>
                                        <Box>
                                            <HoC.SelectBox
                                                option={option}
                                                category="frontend"
                                                setSelectedOptions={setSelectedOptions}
                                                selectedOptions={selectedOptions.frontend}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Backend</Typography>
                                </Grid>
                                {backendOption.map((option: optionType) => (
                                    <Grid item sm={3} sx={{ paddingTop: mediaQuery && "8px!important" }}>
                                        <Box>
                                            <HoC.SelectBox
                                                option={option}
                                                category="api"
                                                setSelectedOptions={setSelectedOptions}
                                                selectedOptions={selectedOptions.api}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Database</Typography>
                                </Grid>
                                {databaseOptions.map((option: optionType) => (
                                    <Grid item sm={3} sx={{ paddingTop: mediaQuery && "8px!important" }}>
                                        <Box>
                                            <HoC.SelectBox
                                                option={option}
                                                setSelectedOptions={handleDBChange}
                                                openDrawer={handleDrawer}
                                                selectedOptions={selectedOptions.database.databaseName}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <HoC.CustomDrawer
                            title={selectedComponent?.toUpperCase()}
                            type="DATABASE_CREDENTIALS"
                            handleDrawerOnClose={() => dispatch(handleDrawerOpen({ open: false, type: "DATABASE_CREDENTIALS" }))}
                        >
                            <Box
                                sx={{
                                    maxWidth: "660px",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}
                                p={1}
                                component="form"
                                onSubmit={formikProps.handleSubmit}
                            >
                                {selectedOptions.database.databaseName === createProjectConfig?.projectData?.databaseValue?.technology1Value && (
                                    <GenerateForm FormData={mongoDBCredentialsForm} FormikProps={formikProps} />
                                )}
                                {selectedOptions.database.databaseName === createProjectConfig?.projectData?.databaseValue?.technology2Value && (
                                    <GenerateForm FormData={MySqlCredentialsForm} FormikProps={formikProps} />
                                )}
                                {selectedOptions.database.databaseName === createProjectConfig?.projectData?.databaseValue?.technology3Value && (
                                    <GenerateForm FormData={MsSqlCredentialsForm} FormikProps={formikProps} />
                                )}
                                <Box display="flex" justifyContent="space-between" py={1} gap={2}>
                                    <Button variant="contained" color="inherit" onClick={() => formikProps.resetForm()}>
                                        {en.clearbtn}
                                    </Button>

                                    <Button style={buttonStyle} type="submit">
                                        {en.savebtn}
                                    </Button>
                                </Box>
                            </Box>
                        </HoC.CustomDrawer>
                    </Grid>
                </Box>
            </Box>
        </HoC.CardComponent>
    );
};

export default Technology;
