import React from "react";
import { MRT_ColumnDef } from "material-react-table";

import { Box, Button, Typography } from "@mui/material";

import HoC from "../Hoc";
import Input from "../Hoc/Input";
import Select from "../Hoc/Select";

import { defaultSettings } from "./uitilities";

const TestingArea = () => {
    const data: any = {};
    let showDownloadButton: boolean = false;
    let showFilterButton: boolean = false;
    let showRefreshButton: boolean = false;
    let enableSorting: any = false;
    let enableMultiRowSelection: any = false;
    let toolBarPosition: any = "bottom";
    let enableBottomToolbar: any = false;
    let enableTopToolbar: any = false;
    const hideColumn: any = [];
    const pinColumn: any = [];

    const [loading, setLoading] = React.useState<any>(false);
    const [dataSource, setDataSource] = React.useState([]);
    const [filter, setFilter] = React.useState<any>([]);
    const [filterFields, setFilterFields] = React.useState<any>(false);
    const [selectWhere, setSelectWhere] = React.useState<any>("");
    const [selectCondition, setSelectCondition] = React.useState<any>("");
    const [inputValue, setInputValue] = React.useState<any>("");
    const columns: MRT_ColumnDef[] = [];
    for (const column of data.pageDetails) {
        columns.push({ accessorKey: column.columnValue, header: column.columnName });
        hideColumn.push({ ...defaultSettings.columnVisibility, [column.columnValue]: !column.enableColumnResizing });
        hideColumn.push({ ...defaultSettings.columnPinning, [column.columnValue]: !column.pinColumn.value });
        showDownloadButton = column.showDownloadButton;
        showFilterButton = column.showFilterButton;
        showRefreshButton = column.showRefreshButton;
        enableSorting = column.enableSorting;
        enableMultiRowSelection = column.enableMultiRowSelection;
        toolBarPosition = column.toolBarPosition.value;
        enableBottomToolbar = column.toolBarPosition.value === "bottom";
        enableTopToolbar = column.toolBarPosition.value === "top";
    }
    const tableData = filter?.length ? filter : data.pageDetails.filter((col: any) => col.api)[0].api ? dataSource : data.pageDetails;
    const fetchApiData = () => {
        try {
            setLoading(true);
            const Url: any = data.pageDetails.filter((col: any) => col.api)[0].api;
            fetch(Url)
                .then((res) => res.json())
                .then((res) => setDataSource(res))
                .catch((err) => err);
            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchApiData();
    }, []);
    const whereList = columns.map((e: any) => {
        return { label: e.header, value: e.accessorKey };
    });
    const handleSearchFilter = () => {
        let filteredData: any;
        if (selectCondition.value === ">") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] > inputValue);
        } else if (selectCondition.value === ">=") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] >= inputValue);
        } else if (selectCondition.value === "<") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] < inputValue);
        } else if (selectCondition.value === "<=") {
            filteredData = tableData.filter((e: any) => e[selectWhere.value] <= inputValue);
        } else if (selectCondition.value === "=") {
            const dataValue = Number.isNaN(parseInt(inputValue, 10)) ? inputValue : parseInt(inputValue, 10);
            filteredData = tableData.filter((e: any) => e[selectWhere.value] === dataValue);
        } else if (selectCondition.value === "!=") {
            const conditionValue = Number.isNaN(parseInt(inputValue, 10)) ? inputValue : parseInt(inputValue, 10);
            filteredData = tableData.filter((e: any) => e[selectWhere.value] === conditionValue);
        }
        setFilter(filteredData);
    };
    return (
        <Box width="100%" mt={10}>
            <Typography>Testing Area</Typography>
            {filterFields && (
                <Box component="form" p={5} gap={2} noValidate display="flex">
                    <Select label="Where" value={selectWhere} list={whereList ?? []} onChange={(e: any) => setSelectWhere(e)} />
                    <Select
                        label="Condition"
                        value={selectCondition}
                        list={[
                            { label: "equals", value: "=" },
                            { label: "does not equal", value: "!=" },
                            { label: "greater than", value: ">" },
                            { label: "less than", value: "<" },
                            { label: "greater than or equals", value: ">=" },
                            { label: "less than or equals", value: "<=" },
                            { label: "before", value: "before" },
                            { label: "after", value: "after" },
                            { label: "is empty", value: "empty" },
                            { label: "is not empty", value: "empty" },
                            { label: "contains", value: "contains" },
                            { label: "does not constains", value: "constains" }
                        ]}
                        onChange={(e: any) => setSelectCondition(e)}
                    />
                    <Input label="Value" value={inputValue} onChange={(e: any) => setInputValue(e)} />
                    <Button onClick={handleSearchFilter}>Search</Button>
                </Box>
            )}
            <HoC.DataGridTable
                data={tableData}
                columns={columns}
                loading={loading}
                showDownloadButton={showDownloadButton}
                showFilterButton={showFilterButton}
                showRefreshButton={showRefreshButton}
                enableBottomToolbar={enableBottomToolbar || false}
                enableTopToolbar={enableTopToolbar || false}
                toolBarPosition={toolBarPosition}
                enableSorting={enableSorting}
                enableMultiRowSelection={enableMultiRowSelection}
                handleFilterButton={() => (filterFields ? setFilterFields(false) : setFilterFields(true))}
                handleRefreshButton={() => fetchApiData()}
                settings={{ ...defaultSettings, columnVisibility: hideColumn[0], columnPinning: pinColumn[0] }}
            />
        </Box>
    );
};

export default TestingArea;
