import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

import InsertPageBreakOutlinedIcon from "@mui/icons-material/InsertPageBreakOutlined";
import { Box, Button, Grid, Input, Typography } from "@mui/material";

import upload from "../../assets/upload.png";
import { en } from "../../dictionary/en";
import ConfigEnum from "../../enums/Config.enum";
import HoC from "../../Hoc";
import { uploadJSON } from "../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../Theme/Theme";
import DownloadCode from "../codeCloning/CodeDownload";

import { JsonData } from "./types";

const GeneratePage = () => {
    const [jsonData, setJsonData] = useState<JsonData>();
    const buttonStyles = { ...buttonStyle, width: "min-content", borderRadius: "15px" };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const validJson: any = await uploadJSON(event, ConfigEnum.uploadPageJson);
            setJsonData(validJson);
        } catch (error: any) {
            toast.error(error);
        }
    };

    const handleDownloadExcel = () => {
        const value = jsonData?.pageDetails.map((e) => {
            return { [e.columnName]: e.columnValue };
        });

        if (jsonData?.pageType?.replace(/\s+/g, "").toLowerCase() === en.lowreportform) {
            const columnTypes = (jsonData?.pageDetails || []).map((e: any) => e?.columnType?.label);

            const worksheet = XLSX.utils.json_to_sheet(value || [], {
                header: columnTypes // Use columnTypes as the header
            });

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
            XLSX.writeFile(workbook, `${jsonData?.pageName}.xlsx`);
        }
    };
    console.log("hanlde", handleDownloadExcel);

    // Function to create the CSV file name
    // const createCsvFileName = () => {
    //     const currentDate = new Date();

    //     const year = currentDate.getFullYear();
    //     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    //     const day = String(currentDate.getDate()).padStart(2, "0");

    //     const formattedDate = `${year}${month}${day}`;

    //     const formattedTime = currentDate
    //         .toLocaleString("en-US", {
    //             hour: "2-digit",
    //             minute: "2-digit",
    //             second: "2-digit",
    //             hour12: true
    //         })
    //         .replace(/:/g, "-");

    //     return `ReportForm_${formattedDate} ${formattedTime}.csv`;
    // };

    // const handleDownloadCSV = () => {
    //     if (jsonData && jsonData.pageType?.replace(/\s+/g, "").toLowerCase() === en.lowreportform && jsonData.pageDetails) {
    //         // Create the CSV data array with header, value
    //         const csvData = jsonData.pageDetails.map((e) => {
    //             const rowData: any = {};
    //             rowData[e.columnName] = e.columnValue;
    //             return rowData;
    //         });

    //         if (csvData.length > 0) {
    //             // Create the CSV content
    //             let csvContent = "data:text/csv;charset=utf-8,";

    //             // Create the CSV header row
    //             const headerRow = Object.keys(csvData[0]);
    //             csvContent += `"${headerRow.join('","')}"\r\n`;

    //             // Create CSV rows for each data item
    //             csvData.forEach((row) => {
    //                 const rowValues = Object.values(row).map((value) => `"${value}"`);
    //                 csvContent += `${rowValues.join(",")}\r\n`;
    //             });

    //             // Get the formatted file name
    //             const fileName = createCsvFileName();

    //             // Create a data URI for the CSV
    //             const encodedUri = encodeURI(csvContent);

    //             // Create a link element and trigger a click to download the CSV file
    //             const link = document.createElement("a");
    //             link.setAttribute("href", encodedUri);
    //             link.setAttribute("download", fileName); // Use the generated file name
    //             link.style.display = "none";
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         } else {
    //             console.error("No data available for CSV download.");
    //         }
    //     }
    // };

    return (
        <Box component="div">
            <HoC.CardComponent>
                <Box component="div" p={5}>
                    <Grid container height="100%">
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h4">{en.genpagebtn}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Box p={5} className="cardComponentInsideWrapper" sx={{ width: "auto" }}>
                                <Grid
                                    container
                                    sx={{
                                        gap: "40px",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        alignContent: "center"
                                    }}
                                >
                                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                                        <Typography variant="h6">{en.uploadpagebtn}</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <Button style={buttonStyles} component="label">
                                            <img src={upload} width="40px" alt="Upload" />
                                            &nbsp;
                                            <span>Upload</span>
                                            <Input type="file" onChange={handleChange} sx={{ color: "white", visibility: "hidden" }} />
                                        </Button>
                                    </Grid>

                                    {jsonData ? (
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{
                                                textAlign: "center",
                                                border: "2px dotted #303f9f",
                                                marginTop: "5px",
                                                display: "flex",

                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                                padding: "8px",
                                                backgroundColor: "#e8f4ff",

                                                borderRadius: "8px",
                                                paddingBottom: "0px",
                                                width: "100%",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <Typography variant="h6">
                                                <InsertPageBreakOutlinedIcon sx={{ width: "25px" }} />
                                            </Typography>

                                            <Grid sx={{ width: "100%", overflow: "hidden" }}>
                                                <Typography sx={{ fontSize: "medium" }}>{jsonData?.pageName}</Typography>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <DownloadCode jsonData={jsonData} />
                        </Grid>
                    </Grid>
                </Box>
            </HoC.CardComponent>
        </Box>
    );
};

export default GeneratePage;
