/* eslint-disable no-underscore-dangle */
import React from "react";
import { format } from "date-fns";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { buttonStyle, textWhite } from "../../Theme/Theme";

import { TableProps } from "./types";

const TableComponent: React.FC<TableProps> = ({
    tableHead,
    tableData,
    editButton,
    deleteButton,
    downloadButton,
    handleEditData,
    handleDeleteData,
    handleDownload
}) => {
    console.log("tableData: ", tableData);
    return (
        <TableContainer
            component={Paper}
            elevation={3}
            sx={{
                height: "100%",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                    width: "10px"
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 5px grey"
                },

                "&::-webkit-scrollbar-thumb": {
                    background: "#303f9f"
                }
            }}
        >
            <Table>
                <TableHead style={{ ...buttonStyle, position: "sticky", top: "0px", zIndex: 1 }}>
                    <TableRow>
                        {tableHead?.map((head: any) =>
                            head === "Created Date" || head === "Modified Date" ? (
                                <TableCell style={textWhite}>
                                    <Typography sx={{ fontSize: "inherit", width: "max-content" }}>{head}</Typography>
                                    <Typography sx={{ fontSize: "smaller" }}>(MM/DD/YYYY)</Typography>
                                </TableCell>
                            ) : (
                                <TableCell style={textWhite}>{head}</TableCell>
                            )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData?.map((data: any, index: any) => {
                        console.log("data: ", { data });
                        return (
                            <TableRow key={index}>
                                <TableCell>{data._id}</TableCell>
                                {data?.projectDetails?.projectName && <TableCell>{data?.projectDetails?.projectName}</TableCell>}
                                {data?.projectDetails?.tagName && <TableCell>{data?.projectDetails?.tagName}</TableCell>}
                                {data?.pageName && <TableCell>{data?.pageName}</TableCell>}
                                {data?.pageType && <TableCell>{data?.pageType}</TableCell>}
                                {data?.createdDate && <TableCell>{format(new Date(data?.createdDate), "LL/dd/yyyy")}</TableCell>}
                                {data?.createdDate && <TableCell>{format(new Date(data?.modifiedDate), "LL/dd/yyyy")}</TableCell>}
                                <TableCell onClick={() => handleEditData(data)}>{editButton}</TableCell>
                                <TableCell onClick={() => handleDeleteData(data)}>{deleteButton}</TableCell>
                                {data?.pageName && <TableCell onClick={() => handleDownload(data, data?.pageName)}>{downloadButton}</TableCell>}
                                {data?.projectDetails?.projectName && (
                                    <TableCell onClick={() => handleDownload(data, data?.projectDetails?.projectName)}>{downloadButton}</TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default TableComponent;
