/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-prototype-builtins */
import { isArray } from "lodash";

// import { JsonData } from "../Component/GeneratePage/types";
import { en } from "../dictionary/en";
import ConfigEnum from "../enums/Config.enum";
import { FormData } from "../types/InputProps";

export interface DataItem {
    name: string;
    calories: number;
    fat: number;
    carbs: number;
    protein: number;
}

export function createData(name: string, calories: number, fat: number, carbs: number, protein: number): DataItem {
    return { name, calories, fat, carbs, protein };
}
export function validateObject(obj: any, type: string) {
    const requiredKeys = ["component", "technology", "projectDetails"];
    const projectDetailsKeys = ["projectName", "tagName"];
    const technologyKeys = ["api", "frontend", "database"];
    console.log(type);
    console.log({ obj });
    for (const key of requiredKeys) {
        if (!obj.hasOwnProperty(key)) {
            return false; // Object is missing a required key
        }
    }

    if (!obj.projectDetails || typeof obj.projectDetails !== "object") {
        return false; // "projectDetails" should exist and be an object
    }

    for (const key of projectDetailsKeys) {
        if (!obj.projectDetails.hasOwnProperty(key)) {
            return false; // "projectDetails" is missing a required key
        }
    }

    if (!obj.technology || typeof obj.technology !== "object") {
        return false; // "technology" should exist and be an object
    }

    for (const key of technologyKeys) {
        if (!obj.technology.hasOwnProperty(key)) {
            return false; // "technology" is missing a required key
        }
    }

    return true; // Object is valid
}
export const downloadJsonFile = (object: any, filename: string): void => {
    const json = JSON.stringify(object, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
};

export function base64File(selectedImage: File, setBase64Image: (base64: string) => void): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
        setBase64Image(event.target.result as string);
    };
    reader.readAsDataURL(selectedImage);
}

export const formatPageJson = (newPageDetails: any[], oldJson: any): any => {
    if (oldJson.pageType.replace(/\s+/g, "").toLowerCase() === "dataform") {
        const formatPageDetails = newPageDetails.map((item) => {
            return {
                ...item,
                mandatory: { label: String(item.mandatory), value: item.mandatory },
                fieldOrder: { label: String(item.fieldOrder), value: item.fieldOrder },
                fieldType: { label: String(item.fieldType), value: item.fieldType }
            };
        });

        return {
            ...oldJson,
            pageDetails: formatPageDetails
        };
    }
    if (oldJson.pageType.replace(/\s+/g, "").toLowerCase() === "listform") {
        const formatPageDetails = newPageDetails.map((item) => {
            return {
                ...item,
                columnType: { label: String(item.columnType), value: item.columnType },
                columnOrder: {
                    label: String(item.columnOrder),
                    value: item.columnOrder
                },
                columnProperties: {
                    label: String(item.columnProperties),
                    value: item.columnProperties
                }
            };
        });

        return {
            ...oldJson,
            pageDetails: formatPageDetails
        };
    }
    if (oldJson.pageType.replace(/\s+/g, "").toLowerCase() === "reportform") {
        const formatPageDetails = newPageDetails.map((item) => {
            return {
                ...item,
                columnType: { label: String(item.columnType), value: item.columnType },
                columnOrder: {
                    label: String(item.columnOrder),
                    value: item.columnOrder
                }
            };
        });

        return {
            ...oldJson,
            pageDetails: formatPageDetails
        };
    }
    return {};
};

export const uploadJSON = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    return new Promise((resolve, reject) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type !== "application/json") {
                reject("Please upload a JSON file.");
                return; // Make sure to return to prevent further execution
            }
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const content = e.target?.result as string;
                    const parsedData = JSON.parse(content);
                    if (type === ConfigEnum.uploadProjectJson) {
                        if (validateObject(parsedData, type)) {
                            resolve(parsedData);
                        } else {
                            reject("Please upload a valid JSON for a project.");
                        }
                    } else if (type === ConfigEnum.uploadPageJson) {
                        resolve(parsedData);
                    }
                } catch (parseError) {
                    console.error("Error parsing JSON:", parseError);
                    reject("Error parsing JSON. Please make sure it's valid JSON.");
                }
            };
            reader.readAsText(file);
        }
    });
};

// generateRegex.ts

export function generateRegex(columnType: string): RegExp | null {
    let regexPattern = "";
    if (columnType === "string") {
        regexPattern = "^[a-zA-Z0-9]*$";
    } else if (columnType === "number") {
        regexPattern = "^[0-9]+$";
    } else if (columnType === "date") {
        regexPattern = "^\\d{4}-\\d{2}-\\d{2}$";
    }

    try {
        return new RegExp(regexPattern);
    } catch (error) {
        console.error("Invalid regex pattern:", error);
        return null;
    }
}
export const RegExValidation = (item: FormData, value: any) => {
    let error: string = "";
    const regex: RegExp = new RegExp(item?.Regex?.join("\\") || "");
    if (item?.Regex?.length && typeof value === "string") {
        if (!regex.test(value)) error = item.RegExError || `Please enter correct ${item.Label}`;
    } else if (isArray(value) && item?.Regex?.length) {
        const someOne = value.some((e: any) => !regex.test(e));
        if (value && value?.length === 0) error = item.ErrorMessage;
        else if (someOne) error = `Incorrect ${item.Label}`;
        else if (value.length < item.Multiple) error = `Minimum ${item.Multiple} ${item.Label} record is Required*`;
    }
    return error;
};
export const validateFormOnSubmit = (values: any, ArrayRecord: Array<any>) => {
    const errors: any = {};

    ArrayRecord.forEach((FieldData) => {
        FieldData.forEach((item: FormData) => {
            if (item?.Type === "date") {
                if (item.Required && !isArray(values[item.Name])) {
                    if (
                        !values[item.Name] ||
                        (parseInt(values?.[item.Name] !== null ? values?.[item.Name]?.split("/")?.[2] : "0", 10) || 0) <= 1000
                    ) {
                        errors[item.Name] = item.ErrorMessage;
                    } else if ((parseInt(values?.[item.Name] !== null ? values?.[item.Name]?.split("/")?.[2] : "0", 10) || 0) > 1000) {
                        // const errorData = DateValidation(item, values[item.Name]);
                        // if (errorData) errors[item.Name] = errorData;
                    }
                } else if (item.Required && isArray(values[item.Name])) {
                    if (!values[item.Name]?.length) {
                        errors[item.Name] = item.ErrorMessage;
                    } else if (values[item.Name]?.some((e: string) => parseInt(e?.split("/")?.[2], 10) <= 1000)) {
                        errors[item.Name] = item.RegExError || `Incorrect ${item.Label}`;
                    } else {
                        // const errorData = DateValidation(item, values[item.Name]);
                        // if (errorData) errors[item.Name] = errorData;
                    }
                } else if (typeof values[item.Name] === "string" ? values[item.Name] : values[item.Name]?.length) {
                    // const errorData = DateValidation(item, values[item.Name]);
                    // if (errorData) errors[item.Name] = errorData;
                }
            }
            if (item.Type === "text") {
                if (item.Required && typeof values[item.Name] === "string") {
                    if (values[item.Name]) {
                        const error: string = RegExValidation(item, values[item.Name]);
                        if (error) errors[item.Name] = error;
                    } else {
                        errors[item.Name] = item.ErrorMessage;
                    }
                } else if (item.Required && typeof values[item.Name] === "object") {
                    if (values[item.Name]?.length) {
                        const error: string = RegExValidation(item, values[item.Name]);
                        if (
                            !error &&
                            values[item.Name].length !== [...(new Set(values[item.Name].map((e: string) => e?.trim()?.toLowerCase())) as any)].length
                        ) {
                            errors[item.Name] = "Duplicate Entry";
                        } else if (error) errors[item.Name] = error;
                    } else {
                        errors[item.Name] = item.ErrorMessage;
                    }
                } else if (typeof values[item.Name] === "string" ? values[item.Name] : values[item.Name]?.filter((e: string) => e)?.length) {
                    const error: string = RegExValidation(item, values[item.Name]);
                    if (error) errors[item.Name] = error;
                }
            }
            if (item.Type === "select") {
                if (item.Required) {
                    if (isArray(values[item.Name])) {
                        if (!values?.[item.Name]?.length) {
                            errors[item.Name] = item.ErrorMessage;
                        } else if (values?.[item.Name]?.length < item.Multiple)
                            errors[item.Name] = `Minimum ${item.Multiple} ${item.Label} record is Required*`;
                    } else if (!(typeof values?.[item.Name] === "string" ? values?.[item.Name] : values?.[item.Name]?.label))
                        errors[item.Name] = item.ErrorMessage;
                } else if (values?.[item.Name]) {
                    if (item.Multiple && values?.[item.Name]?.length && values?.[item.Name].length < item.Multiple)
                        errors[item.Name] = `Minimum ${item.Multiple} ${item.Label} record is Required*`;
                }
            }
            if (item.Type === "checkbox") {
                if (item.Required && !values[item.Name]) errors[item.Name] = item.ErrorMessage;
            }
            if (item.Type === "radio") {
                if (item.Required && !values[item.Name]) errors[item.Name] = item.ErrorMessage;
            }
            if (item.Type === "checkboxContainer") {
                if (!item.Multiple) {
                    if (item.Required && !values[item.Name]?.TestNumber) errors[item.Name] = item.ErrorMessage;
                } else if (item.Required && !values[item.Name]?.length) errors[item.Name] = item.ErrorMessage;
                else if (values[item.Name]?.length ? values[item.Name]?.length < item.Multiple : false)
                    errors[item.Name] = "Please choose atlease 2 items";
            }
            if (item.Type === "file" && item.Required) {
                if (item?.additionalData?.mainObject && !values?.[item?.additionalData?.mainObject]?.[item?.Name]) {
                    errors[item.Name] = item.ErrorMessage;
                } else if (!item?.additionalData?.mainObject && !values?.[item?.Name]) {
                    errors[item.Name] = item.ErrorMessage;
                }
            }
            // Logic Speciallt for Insurance Modal
            // additionalFieldValidation(item, errors, values);
        });
    });
    // additionalValidationRules(errors, values);
    console.log({ errors, values });

    return errors;
};
export const NameFormatter = (e: any) => {
    try {
        const newValue = e;
        // eslint-disable-next-line const-case/uppercase
        const separator = "-";
        let formattedValue = "";

        const cleanValue = newValue.replace(/[^a-zA-Z0-9]/g, "");
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cleanValue.length; i++) {
            formattedValue += cleanValue[i];
            if (i === 2 && i < cleanValue.length - 1) {
                formattedValue += separator;
            }
        }

        return formattedValue;
    } catch (error) {
        console.error("An error occurred while formatting the name:", error);
        return "";
    }
};
export const PrefixAdder = (e: any, prefix: string) => {
    try {
        const newValue = e;
        // eslint-disable-next-line const-case/uppercase

        if (!newValue.startsWith(prefix)) {
            return prefix + newValue;
        }

        return newValue;
    } catch (error) {
        console.error("An error occurred while formatting the name:", error);
        return "";
    }
};
export const DownloadText = (content: any, fileName: any) => {
    const blob = new Blob([content], { type: "text/plain" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${fileName}.txt`;

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};
export const VmScript = (
    AuthType: any,
    VnetName: any,
    VnetNameIp: any,
    Port: any,
    Image: any,
    typeOfHost: any,
    VmSize: any,
    VmImage: any,
    OsDiskSize: any,
    VmName: any,
    UserName?: any,
    Password?: any,
    SSH_UserName?: any,
    SSH_KeypairName?: any
) => {
    let content = "";
    if (AuthType === en.password) {
        content = `
        1.provide me a azure bicep script for creating a vnet named ${VnetName} and a public subnet,publicip named ${VnetNameIp}, nsg that allows${Port} and nic attaching the above
        2. establish an ${Image} ${typeOfHost} with the VM size as ${VmSize}, image as ${VmImage},
        osdisk size as ${OsDiskSize} with the vmname as ${VmName}, username as ${UserName} and password as ${Password} which should use the above nic
        for network profile
        `;
    } else {
        content = `1.provide me a azure bicep script for creating a vnet named ${VnetName} and a public subnet,publicip named ${VnetNameIp}, nsg that allows ${Port} and nic attaching the above
        2.establish a ${Image} ${typeOfHost}  with the vmsize as ${VmSize},
        image as ${VmImage}, osdisk size as ${OsDiskSize} with the username as ${SSH_UserName},
        which should use the above nic for network profile and create a new ssh-keypair  named ${SSH_KeypairName} and used as default sigin method for the vm`;
    }
    return content;
};
