import React, { useEffect, useState } from "react";

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import { en } from "../../../dictionary/en";
import Input from "../../../Hoc/Input";
import { regionOptions } from "../../../inputconfig";
import { useAppDispatch } from "../../../Redux/Hooks";
import { handleHostApiLayer } from "../../../Redux/Reducers";
import { PrefixAdder } from "../../../ReusableCodes/Resuseablefunctions";

import { appServicePlanList, functionList } from "./Utilities";

const FunctionAppHost = ({ nextButton }: any) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    console.log(open);

    const [selectedOptions, setSelectedOptions] = useState<any>({
        hostingOption: "",
        operatingSystem: "",
        Region: "",
        pricingPlan: "",
        appServicePlanName: ""
    });
    const hostingOption = [
        { label: "Consumption", value: "consumption" },
        { label: "Functions Premium", value: "functionsPremium" },
        { label: "App Service Plan", value: "appServicePlan" }
    ];
    const osOptions = [
        {
            label: "Linux",
            value: "linux",
            icon: <img width="40" height="40" src="https://img.icons8.com/ios-filled/50/linux.png" alt="linux" />
        },
        {
            label: "Windows",
            value: "windows",
            icon: <img width="40" height="40" src="https://img.icons8.com/ios-filled/50/windows-10.png" alt="windows-10" />
        }
    ];

    const handleChange = (event: any) => {
        if (event.target.value !== en.functionHostPlanAsConsumption && selectedOptions.pricingPlan === "" && event.target.name === "hostingOption") {
            handleOpen();
        } else if (event.target.name === "hostingOption") {
            setOpen(false);
        }
        if (event.target.name === "hostingOption") {
            return setSelectedOptions((prev: any) => ({
                ...prev,
                pricingPlan: "",
                appServicePlanName: "",
                [event.target.name]: event.target.value
            }));
        }
        setSelectedOptions((prev: any) => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
        return null;
    };
    // eslint-disable-next-line consistent-return
    const handleSubmit = () => {
        dispatch(handleHostApiLayer(selectedOptions));
    };

    useEffect(() => {
        if (nextButton) {
            handleSubmit();
        }
    }, [nextButton]);
    return (
        <Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box py={2}>
                    <Typography>Functions App ServicesApplication Components</Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} py={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography>Operating System</Typography>
                                    </Box>
                                </Grid>
                                {osOptions.map((option: any) => (
                                    <Grid item xs={6}>
                                        <Box
                                            className="transition"
                                            px={2}
                                            sx={{
                                                minHeight: "100px",
                                                backgroundColor: option.value === selectedOptions?.operatingSystem?.value ? "#ffff" : "#edededdd",
                                                border: "1px solid #edededdd",
                                                borderRadius: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center"
                                            }}
                                            onClick={() => {
                                                setSelectedOptions((prev: any) => ({
                                                    ...prev,
                                                    operatingSystem: { label: option.label, value: option.value }
                                                }));
                                            }}
                                        >
                                            <Box display="flex" gap={2} alignItems="center">
                                                {option.icon}
                                                <Typography>{option.label}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} py={2} display="flex" flexDirection="column" gap={2}>
                            <Box>
                                <Typography>Choose Region</Typography>
                            </Box>
                            <Box>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Region"
                                        name="Region"
                                        onChange={handleChange}
                                    >
                                        {regionOptions.map((option: any) => (
                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} py={2} display="flex" flexDirection="column" gap={2}>
                            <Box>
                                <Typography>Choose Hosting Plan</Typography>
                            </Box>
                            <Box>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-label">Host</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Host"
                                        name="hostingOption"
                                        onChange={handleChange}
                                    >
                                        {hostingOption.map((option: any) => (
                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} py={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography>App Service Plan</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        value={selectedOptions.appServicePlanName}
                                        onChange={(e: any) => {
                                            setSelectedOptions({ appServicePlanName: PrefixAdder(e, en.appServicePlanPrefix) });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {open ? (
                            <Grid item xs={12} sm={6} py={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>Choose Pricing Plan</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            {selectedOptions.hostingOption === "functionsPremium" ? (
                                                <FormControl sx={{ m: 1, width: "100%" }}>
                                                    <InputLabel id="demo-simple-select-label">Functions Pricing</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="App Service Pricing"
                                                        name="pricingPlan"
                                                        onChange={handleChange}
                                                    >
                                                        {functionList.map((option: any) => (
                                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <FormControl sx={{ m: 1, width: "100%" }}>
                                                    <InputLabel id="demo-simple-select-label">App Service Pricing</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="App Service Pricing"
                                                        name="pricingPlan"
                                                        onChange={handleChange}
                                                        value={selectedOptions.pricingPlan}
                                                    >
                                                        {appServicePlanList.map((option: any) => (
                                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};
export default FunctionAppHost;
