import React from "react";

import { Box } from "@mui/material";

import BackButton from "../../Button/BackButton/index";
import NextButton from "../../Button/NextButton/index";

import { NavigatorsProps } from "./types";

const Navigators: React.FC<NavigatorsProps> = ({ handleBack, handleNext, submit, disableNext = false, buttonText1, buttonText2, buttonType }) => {
    return (
        <Box component="div" display="flex" justifyContent={handleBack ? "space-between" : "flex-end"}>
            {handleBack && <BackButton handleClick={handleBack} buttonText={buttonText2} />}
            {(handleNext || submit) && (
                <NextButton buttonType={buttonType} handleClick={handleNext} submit={submit} disableNext={disableNext} buttonText={buttonText1} />
            )}
        </Box>
    );
};

export default Navigators;
