import React, { useRef, useState } from "react";
import { ChromePicker } from "react-color";

import StorageIcon from "@mui/icons-material/CloudUpload";
import StripeIcon from "@mui/icons-material/CreditCard";
import EmailLoginIcon from "@mui/icons-material/Email";
import FacebookMediaIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Auth0Icon from "@mui/icons-material/Lock";
import RazPayIcon from "@mui/icons-material/MonetizationOn";
import PayPalIcon from "@mui/icons-material/Payment";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import API from "../../../API";
import { createProjectConfig, optionLabels } from "../../../inputconfig";
import { useAppDispatch } from "../../../Redux/Hooks";
import { handleDrawerClose } from "../../../Redux/Reducers";
import { downloadJsonFile } from "../../../ReusableCodes/Resuseablefunctions";
import { buttonStyle } from "../../../Theme/Theme";

const EditProject = ({ project }: any) => {
    const dispatch = useAppDispatch();
    const [projectDetails, setProjectDetails] = useState<any>(project[0]);
    console.log({ projectDetails });
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const favRef = useRef<HTMLInputElement | null>(null);
    const logoRef = useRef<HTMLInputElement | null>(null);

    const mergeOptionValueAndLable = (values: any, labels: any) => {
        const combinedArray = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < values.length; i++) {
            const label = labels[i];
            const value = values[i];

            combinedArray.push({ label, value });
        }

        return combinedArray;
    };
    const componentoptions: any = {
        Authentication: [
            { option: "emailLogin", icon: <EmailLoginIcon style={{ color: "red" }} /> },
            { option: "facebook", icon: <FacebookMediaIcon style={{ color: "#1877F2" }} /> },
            { option: "google", icon: <GoogleIcon /> },
            { option: "auth0", icon: <Auth0Icon /> }
        ],
        Payments: [
            { option: "razPay", icon: <RazPayIcon /> },
            { option: "payPal", icon: <PayPalIcon style={{ color: "#00457C" }} /> },
            { option: "stripe", icon: <StripeIcon style={{ color: "blue" }} /> }
        ],
        Media: [
            { option: "youtube", icon: <YouTubeIcon style={{ color: "#CD201F" }} /> },
            { option: "vimeo", icon: <StorageIcon /> },
            { option: "facebook", icon: <FacebookMediaIcon style={{ color: "#1877F2" }} /> }
        ],
        "File Upload": [
            { option: "ftp", icon: <StorageIcon /> },
            { option: "storage", icon: <StorageIcon /> }
        ]
    };
    const navbarOptions = [
        {
            label: "Left",
            value: "left"
        },
        {
            label: "Right",
            value: "right"
        },
        {
            label: "Top",
            value: "top"
        }
    ];
    const appFontOptions = [
        {
            label: "Arial",
            value: "Arial"
        },
        {
            label: "Helvetica",
            value: "Helvetica"
        },
        {
            label: "Times New Roman",
            value: "Times New Roman"
        },
        {
            label: "Courier New",
            value: "Courier New"
        }
        // Add more font options here
    ];

    const hostOptions = mergeOptionValueAndLable(
        Object.values(createProjectConfig.projectData.hostValue),
        Object.values(createProjectConfig.projectData.hostOption)
    );
    const frontendOptions = mergeOptionValueAndLable(
        Object.values(createProjectConfig.projectData.frontendValue),
        Object.values(createProjectConfig.projectData.frontendOption)
    );
    const backendOptions = mergeOptionValueAndLable(
        Object.values(createProjectConfig.projectData.backendValue),
        Object.values(createProjectConfig.projectData.backendOption)
    );
    const databaseOptions = mergeOptionValueAndLable(
        Object.values(createProjectConfig.projectData.databaseValue),
        Object.values(createProjectConfig.projectData.databaseOption)
    );
    const handleClose = () => {
        dispatch(handleDrawerClose());
    };

    // eslint-disable-next-line consistent-return
    const handleUpdate = async () => {
        try {
            const component = Object.keys(selectedOptions).map((e) => ({ key: e, value: selectedOptions[e] }));
            console.log({ component });
            const res: any = await API.Projects.updateProjects({ ...projectDetails, component });
            console.log({ res });
            if (res?.success) return downloadJsonFile(res.data, projectDetails.projectDetails.projectName);
        } catch (error) {
            console.log(error);
        }
    };

    const handleImageChange = (e: any) => {
        console.log(e);
        const selectedImage = e.target.files?.[0];
        const reader = new FileReader();
        reader.onload = (event: any) => {
            setProjectDetails((data: any) => ({
                ...data,
                projectDetails: { ...data.projectDetails, [e.target.name]: event.target.result as string }
            }));
        };
        reader.readAsDataURL(selectedImage);
    };
    const handleChangeProjectDetails = (event: any) => {
        setProjectDetails((data: any) => ({
            ...data,
            projectDetails: {
                ...data.projectDetails,
                [event.target.name]: event.target.value
            }
        }));
    };
    const handleComponent = (component: string, value: string) => {
        setSelectedOptions((prevSelectedOptions: any) => ({
            ...prevSelectedOptions,
            [component]: value
        }));
    };

    const handleChangeTechonolgy = (event: any) => {
        console.log(event);
        setProjectDetails((data: any) => ({ ...data, technology: { ...data.technology, [event.target.name]: event.target.value } }));
    };
    console.log(projectDetails);
    return (
        <Grid container p={2} rowGap={2}>
            <Grid item xs={6}>
                <Box>
                    <TextField
                        id="filled-basic"
                        label={createProjectConfig.projectData.projectName}
                        variant="standard"
                        name="projectName"
                        defaultValue={projectDetails?.projectDetails?.projectName}
                        onChange={handleChangeProjectDetails}
                    />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <TextField
                        id="filled-basic"
                        label={createProjectConfig.projectData.tagName}
                        variant="standard"
                        name="tagName"
                        defaultValue={projectDetails?.projectDetails?.tagName}
                        onChange={handleChangeProjectDetails}
                    />
                </Box>
            </Grid>
            {/* App Font */}
            <Grid item xs={6}>
                <Box>
                    <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                            <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                                App Font
                                <Typography color="red" component="span">
                                    *
                                </Typography>
                            </Typography>
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={projectDetails?.projectDetails?.appFont}
                            name="appFont"
                            onChange={handleChangeProjectDetails}
                            label="App Font"
                        >
                            {appFontOptions.map((e, key) => (
                                <MenuItem value={e.value} key={key}>
                                    {e.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            {/* Navbar */}
            <Grid item xs={6}>
                <Box>
                    <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                            <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                                Navbar
                                <Typography color="red" component="span">
                                    *
                                </Typography>
                            </Typography>
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedOptions?.NavBar}
                            name="NavBar"
                            onChange={(event: any) => {
                                handleComponent("NavBar", event.target.value);
                            }}
                            label="Navbar"
                        >
                            {navbarOptions.map((e, key) => (
                                <MenuItem value={e.value} key={key}>
                                    {e.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box>
                    <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                            <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                                Front-End Techonolgy
                                <Typography color="red" component="span">
                                    *
                                </Typography>
                            </Typography>
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={projectDetails?.technology?.frontend}
                            name="frontend"
                            onChange={handleChangeTechonolgy}
                            label="Frontend"
                        >
                            {frontendOptions.map((e, key) => (
                                <MenuItem value={e.value} key={key}>
                                    {e.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                            <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                                Back-End Techonolgy
                                <Typography color="red" component="span">
                                    *
                                </Typography>
                            </Typography>
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={projectDetails?.technology?.api}
                            name="api"
                            onChange={handleChangeTechonolgy}
                            label="Api"
                        >
                            {backendOptions.map((e, key) => (
                                <MenuItem value={e.value} key={key}>
                                    {e.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                            <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                                DataBase
                                <Typography color="red" component="span">
                                    *
                                </Typography>
                            </Typography>
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={projectDetails?.technology?.database}
                            name="database"
                            onChange={handleChangeTechonolgy}
                            label="DataBase"
                        >
                            {databaseOptions.map((e, key) => (
                                <MenuItem value={e.value} key={key}>
                                    {e.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                            <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                                Host
                                <Typography color="red" component="span">
                                    *
                                </Typography>
                            </Typography>
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={projectDetails?.technology?.host}
                            name="host"
                            onChange={handleChangeTechonolgy}
                            label="Host"
                        >
                            {hostOptions.map((e, key) => (
                                <MenuItem value={e.value} key={key}>
                                    {e.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} py={2}>
                <Divider variant="middle" />
            </Grid>
            {/* Logo */}
            <Grid item xs={6}>
                <Box>
                    <InputLabel id="demo-simple-select-standard-label">
                        <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                            Logo
                            <Typography color="red" component="span">
                                *
                            </Typography>
                        </Typography>
                    </InputLabel>
                    <Box
                        component="img"
                        src={projectDetails?.projectDetails?.base64Logo}
                        alt="Uploaded"
                        style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            marginTop: "10px" // Add some spacing
                        }}
                        onClick={() => {
                            logoRef.current?.click();
                        }}
                    />
                    <Box
                        component="input"
                        type="file"
                        ref={logoRef}
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        accept="image/*"
                        name="base64Logo"
                    />
                </Box>
            </Grid>
            {/* FavIcon */}
            <Grid item xs={6}>
                <Box>
                    <InputLabel id="demo-simple-select-standard-label">
                        <Typography component="span" sx={{ fontWeight: "900", color: "black" }}>
                            FavIcon
                            <Typography color="red" component="span">
                                *
                            </Typography>
                        </Typography>
                    </InputLabel>
                    <Box
                        component="img"
                        src={projectDetails?.projectDetails?.faviIconBase64Image}
                        alt="Uploaded"
                        style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            marginTop: "10px" // Add some spacing
                        }}
                        onClick={() => favRef.current?.click()}
                    />
                    <Box
                        component="input"
                        type="file"
                        ref={favRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        name="faviIconBase64Image"
                        onChange={handleImageChange}
                    />
                </Box>
            </Grid>
            {/* Theme color */}
            <Grid item xs={12} py={3}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <ChromePicker color="#000" onChange={() => {}} />
                </Box>
            </Grid>
            {/* Components */}
            <Grid item xs={12} py={3}>
                <Grid container spacing={2}>
                    {Object.keys(componentoptions).map((category) => (
                        <Grid item xs={12} sm={6} key={category}>
                            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                {category}
                            </Typography>
                            <Grid container spacing={2}>
                                {componentoptions[category].map((item: any) => (
                                    <Grid item key={item.option}>
                                        <IconButton
                                            onClick={() => {
                                                handleComponent(category, item.option);
                                            }}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                color: selectedOptions[category] === item.option ? "#default" : "#303f9f",
                                                backgroundColor: selectedOptions[category] === item.option ? "#FFA500" : "default",
                                                borderRadius: "15px",
                                                ":hover": {
                                                    backgroundColor: selectedOptions[category] === item.option ? "#FFA500" : "transparent" // Disable hover effect
                                                }
                                            }}
                                        >
                                            {item.icon}
                                            <Typography variant="body2">{optionLabels[item.option]}</Typography>
                                        </IconButton>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} py={2}>
                <Box p={2} display="flex" justifyContent="space-between">
                    <Button onClick={handleClose}>
                        <Typography>Cancel</Typography>
                    </Button>
                    <Button style={buttonStyle} onClick={handleUpdate}>
                        <Typography>Update</Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default EditProject;
