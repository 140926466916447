import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetPages, GetProjects, UserLogin } from "./AsyncThunk";
import { initialState } from "./ReducerInitialState";
import { drawerType, InitialState } from "./types";

export const NumbusJs: any = createSlice({
    name: "NumbusJs",
    initialState,
    reducers: {
        // this is for handling users
        handleUser: (state: InitialState, action: PayloadAction<any>) => {
            state.userData = action.payload;
        },
        // this is for hosts Layer Type
        handleHost: (state: InitialState, action: PayloadAction<any>) => {
            state.hostLayerType = { ...state.hostLayerType, ...action.payload };
        },
        // this is for hosts ui layer
        handleHostUi: (state: InitialState, action: PayloadAction<any>) => {
            state.hostUi = { ...state.hostUi, ...action.payload };
        },
        // this is for hosts api layer
        handleHostApiLayer: (state: InitialState, action: PayloadAction<any>) => {
            state.hostApiLayer = { ...state.hostApiLayer, ...action.payload };
        },
        // this is for hosts db layer
        handleHostDbLayer: (state: InitialState, action: PayloadAction<any>) => {
            state.hostDbLayer = { ...state.hostDbLayer, ...action.payload };
        },
        // this is for hosts service layer
        handleHostServiceLayer: (state: InitialState, action: PayloadAction<any>) => {
            state.hostServiceLayer = { ...state.hostServiceLayer, ...action.payload };
        },
        // this is for new projects
        handleProjects: (state: InitialState, action: PayloadAction<any>) => {
            state.newProject = { ...state.newProject, ...action.payload };
        },
        // this for add or update or delete
        handleProjectsData: (state: InitialState, action: PayloadAction<any>) => {
            state.projectData = action.payload;
        },
        // this for new pages
        handlePages: (state: InitialState, action: PayloadAction<any>) => {
            state.newPages = { ...state.newPages, ...action.payload };
        },
        handleRemovePages: (state: InitialState, action: PayloadAction<any>) => {
            state.newPages = action.payload;
        },
        // for pages Add or update or delete
        handlePageData: (state: InitialState, action: PayloadAction<any>) => {
            state.pageData = action.payload;
        },
        handleDrawerOpen: (state: InitialState, action: PayloadAction<drawerType>) => {
            state.drawerState = action.payload;
        },
        handleDrawerClose: (state: InitialState) => {
            state.drawerState = {
                open: false,
                type: "",
                title: ""
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetProjects.pending, (state: any) => {
                state.loaderState = { loading: false };
            })
            .addCase(GetProjects.rejected, (state: any) => {
                state.loaderState = { loading: false };
            })
            .addCase(GetProjects.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.projectData = action.payload.data;
                state.loaderState = { loading: false };
            })
            .addCase(GetPages.pending, (state: any) => {
                state.loaderState = { loading: true };
            })
            .addCase(GetPages.rejected, (state: any) => {
                state.loaderState = { loading: false };
            })
            .addCase(GetPages.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.pageData = action.payload.data;
                state.loaderState = { loading: false };
            })
            .addCase(UserLogin.pending, (state: any) => {
                state.loaderState = { loading: true };
            })
            .addCase(UserLogin.rejected, (state: any) => {
                state.loaderState = { loading: false };
            })
            .addCase(UserLogin.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.userData = action.payload.data;
                state.loaderState = { loading: false };
            });
    }
});
export const {
    handleUser,
    handleHost,
    handleHostUi,
    handleProjects,
    handleProjectsData,
    handlePages,
    handlePageData,
    handleHostApiLayer,
    handleHostDbLayer,
    handleHostServiceLayer,
    handleDrawerClose,
    handleDrawerOpen,
    handleRemovePages
} = NumbusJs.actions;

export default NumbusJs.reducer;
